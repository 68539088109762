export const _ENV_MODE = process.env.REACT_APP_ENV_MODE;

export default class Env {
    static getApiUrl(path, query = null) {
        let env = "";
        switch (_ENV_MODE) {
            default:
            case 'local':
                env = 'http://activtrack-cloud.local/';
                env = 'https://cloud.activ8track.com/';
                break;
            case 'stage':
                env = 'https://cloud-dev.activ8track.com/';
                break;
            case 'prod':
                env = 'https://cloud.activ8track.com/';
                break;
        }
        return encodeURI(env + path + Env.addQuery(query));
    }

    static addQuery(path) {
        if (!!path) {
            let result = ('?' +
                (
                    Object.keys(path).reduce(
                        (memo, key) => {
                            memo += (key + "=" + path[key] + "&");
                            return memo;
                        }
                    , "")
                )
            );
            return result.substring(0, result.length - 1);
        }
        return "";
    }

    static getPublicToken() {
        return 'QWN0IXZUciRjazpUcmFjayRvZnR3YXJlMjAhOA==';
    }
}
