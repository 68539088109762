import axios from "axios";
import Env from "../../util/env";
import {processError, processResponse} from "./api-util";
import Resources from "../services/resources";
import FileSaver from "file-saver";

export default class Api {

    static getResource(user, query, resource) {
        return axios.get(
            Env.getApiUrl('api/cloud/' + resource, query),
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
        .then((response) => {
            return processResponse(response);
        })
        .catch((error) => {
            return processError(error);
        });
    }

    static createResource(user, params, resource) {
        return axios.post(
            Env.getApiUrl('api/cloud/' + resource),
            params,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
        .then((response) => {
            return processResponse(response);
        })
        .catch((error) => {
            return processError(error);
        })
    }

    static updateResource(user, params, resource) {
        return axios.patch(
            Env.getApiUrl('api/cloud/' + resource),
            params,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static deleteResource(user, query, resource) {
        return axios.delete(
            Env.getApiUrl('api/cloud/' + resource, query),
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static login(username, password) {
        return axios.post(
            Env.getApiUrl('token'),
            ('grant_type=password&username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password)),
            {
                headers: {
                    'Authorization': 'Basic ' + Env.getPublicToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
            .then((response) => {
                return {
                    'status': 0,
                    'data': response.data
                };
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static refresh(token) {
        return axios.post(
            Env.getApiUrl('refresh'),
            'grant_type=refresh_token&refresh_token=' + token,
            {
                headers: {
                    'Authorization': 'Basic ' + Env.getPublicToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
            .then((response) => {
                return {
                    'status': 0,
                    'data': response.data
                };
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static userInfo(user) {
        return axios.get(
            Env.getApiUrl('api/cloud/user'),
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response)
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static resetPassword(username) {
        return axios.post(
            Env.getApiUrl('api_pub/reset-password'),
            {
                username: username
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static resetPasswordConfirm(username, token, password) {
        return axios.post(
            Env.getApiUrl('api_pub/reset-password-confirm'),
            {
                username: username,
                token: token,
                password: password
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static downloadExcelReport(user, resource, query) {
        return axios({
            url: Env.getApiUrl('api/cloud/' + resource, query),
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + user.access_token
            }
        }).then((response) => {
            FileSaver.saveAs(new Blob([response.data]), query.name);

            return {
                'status': 0,
                'data': true
            };
        }).catch((error) => {
            return {
                'status': 1,
                'data': false
            };
        });
    }
}