import React, {Component} from "react";
import Dialog from "./index";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldText from "../field-text";
import FieldSelect from "../field-select";
import FieldTextarea from "../field-textarea";
import Button from "../button";
import LoaderSmall from "../../components/loader-small";
import {resetResourceMessage, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import SuccessMessage from "../success-message";
import ErrorMessage from "../error-message";

export default class EditCompanyUserDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                user_description: new Field('user_description', '', ['']),
                user_email: new Field('user_email', '', ['empty']),
                user_fname: new Field('user_fname', '', ['empty']),
                user_lname: new Field('user_lname', '', ['empty']),
                user_phone: new Field('user_phone', '', ['empty']),
                role_id: new Field('role_id', '', ['empty'])
            }
        };
    }

    componentDidMount() {
        this.setState({
                fields: {
                    user_description: new Field('user_description', this.props.value.user_description, ['']),
                    user_email: new Field('user_email', this.props.value.user_email, ['empty']),
                    user_fname: new Field('user_fname', this.props.value.user_fname, ['empty']),
                    user_lname: new Field('user_lname', this.props.value.user_lname, ['empty']),
                    user_phone: new Field('user_phone', this.props.value.user_phone, ['empty']),
                    role_id: new Field('role_id', this.props.value.role_id, ['empty'])
                }
            }
        )
    }

    componentDidUpdate = (prevProps) => {
        if (((!prevProps.resource.update) && (this.props.resource.update)) ||
            ((!prevProps.resource.error) && (this.props.resource.error))) {
            setTimeout(() => this.props.dispatch(resetResourceMessage()), 5000);
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {

                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({id: this.props.value.user_id}, FieldsManager.getFieldKeyValues(this.state.fields)),
                    piggyResource: Resources.CompanyUsers,
                    resource: Resources.CompanyUsers,
                    query: this.props.query
                }));
            }
        })
    };

    render() {

        const {translate, resource} = this.props;

        return (
            <Dialog active={this.props.visible}>

                <a className="close-dialog" title="Close"
                   onClick={this.props.cancel}
                ><i className="ti-close"></i> </a>

                <div className="dialog-title-wrapper">
                    <div className="dialog-main-title">Company User</div>
                    <div className="dialog-second-title">edit entry</div>
                </div>

                {resource.update && (
                    <SuccessMessage className={"action-success-big"} text={translate("label.entry_updated")}
                                    onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {resource.error && (
                    <ErrorMessage className={"action-success-big"} text={resource.errorMessage}
                                  onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {!resource.isLoading && (
                    <form onKeyPress={this.submitForm}>

                        <React.Fragment>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label>First Name*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_fname} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label>Last Name*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_lname} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label>Email*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_email} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label>Phone*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_phone} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label>Type*</label>
                                    <FieldSelect addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.role_id}>
                                        <option value={1}>Company Admin</option>
                                        <option value={2}>Office user</option>
                                        <option value={3}>External user</option>
                                    </FieldSelect>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label>Notes</label>
                                    <FieldTextarea addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_description} />
                                </div>
                            </div>

                            <Button className="btn btn-brand mg-r-15" onClick={this.submit} text={"Update"}/>
                            <Button className="btn btn-brand" onClick={this.props.cancel} text={"Cancel"}/>
                        </React.Fragment>

                    </form>
                )}

                {resource.isLoading && (
                    <LoaderSmall addClass={"text-center"}/>
                )}

            </Dialog>
        )
    }

}