export function hideModals() {
    return {
        type: 'HIDE_MODALS',
        data: {}
    };
}

export function showAddAddressBookModal(data = null) {
    return {
        type: 'SHOW_ADD_ADDRESS_BOOK_MODAL',
        data: data
    };
}

export function hideAddAddressBookModal() {
    return {
        type: 'HIDE_ADD_ADDRESS_BOOK_MODAL',
        data: {}
    };
}

export function showAddLoadModal(data = null) {
    return {
        type: 'SHOW_ADD_LOAD_MODAL',
        data: data
    };
}

export function hideAddLoadModal() {
    return {
        type: 'HIDE_ADD_LOAD_MODAL',
        data: {}
    };
}

export function minimizeAddLoadModal() {
    return {
        type: 'MINIMIZE_ADD_LOAD_MODAL',
        data: {}
    };
}

export function unminimizeAddLoadModal() {
    return {
        type: 'UNMINIMIZE_ADD_LOAD_MODAL',
        data: {}
    };
}


export function minimizeAddAddressBookModal() {
    return {
        type: 'MINIMIZE_ADD_ADDRESS_BOOK_MODAL',
        data: {}
    };
}

export function unminimizeAddAddressBookModal() {
    return {
        type: 'UNMINIMIZE_ADD_ADDRESS_BOOK_MODAL',
        data: {}
    };
}

export function showAddSupportModal() {
    return {
        type: 'SHOW_ADD_SUPPORT_MODAL',
        data: {
        }
    };
}

export function hideAddSupportModal() {
    return {
        type: 'HIDE_ADD_SUPPORT_MODAL',
        data: {}
    };
}


export function setMenuState(data) {
    return {
        type: 'SET_MENU_STATE',
        data: data
    };
}