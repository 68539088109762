import React, {Component} from "react";
import PublicLayout from "../../components/public-layout";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import {Field, FieldsManager} from "../../data/services/fields";
import {resetPassword, resetUserMessage} from "../../data/actions/user";
import LoaderSmall from "../../components/loader-small";
import ErrorMessage from "../../components/error-message";
import FieldText from "../../components/field-text";
import Button from "../../components/button";

class ResetPasswordView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                username: new Field('username', '', ['email', 'empty'])
            }
        };
    }

    componentDidMount() {
        this.props.dispatch(resetUserMessage());
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (!this.state.fields.username.errorMessage) {
                this.props.dispatch(resetPassword({
                    username: this.state.fields.username.value
                }))
            }
        })
    };

    render() {
        const {translate} = this.props;

        return (
            <PublicLayout {...this.props}>
                <div className="col-lg-6 bg-light">
                    <div className="ht-100v d-flex align-items-center justify-content-center">

                        {!this.props.user.isLoading && this.props.user.reset && (
                            <div className="">
                                <h3 className="tx-dark mg-b-5 tx-left">Please check your email and follow instructions in order to set password.</h3>
                                <div className="mg-t-10 tx-gray-500">
                                    <Link className="form-btn-submit" to={`/login`}>{"Back to login"}</Link>
                                </div>
                            </div>
                        )}

                        {this.props.user.isLoading && (
                            <LoaderSmall/>
                        )}

                        {this.props.user.error && (
                            <ErrorMessage className={"action-error-big"} text={translate(this.props.user.errorMessage)}
                                          onDismiss={() => this.props.dispatch(resetUserMessage())}/>
                        )}

                        {!this.props.user.isLoading && !this.props.user.reset && (
                            <div className="">
                                <h3 className="tx-dark mg-b-5 tx-left">Forgot password</h3>
                                <p className="tx-gray-500 tx-15 mg-b-40 tx-left">
                                    Enter your email to continue.
                                    <br/>
                                    Email needs to be valid and used before.
                                </p>

                                <div className="form-group tx-left">
                                    <label className="tx-gray-500 mg-b-5">Email address</label>
                                    <FieldText onChange={this.handleInputChange} {...this.state.fields.username}
                                               placeholder={"email@domain.com"} type={"email"} addClass={"form-control"}/>
                                </div>

                                <Button className="btn btn-brand btn-block" onClick={this.submit}
                                        text={"Reset password"}/>

                                <div className="mg-t-10 tx-gray-500">
                                    Sign in with another account? <Link to="/login" className="tx-semibold">Login</Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(ResetPasswordView);