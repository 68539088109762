import React, {Component} from "react";
import Select, {createFilter} from "react-select";

export default class FieldSelectSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const pairs = this.props.values ? this.props.values : {};

        let values = Object.keys(pairs).sort((a, b) => {
            return (pairs[a] == pairs[b]) ? 0 : ((pairs[a] > pairs[b]) ? 1 : -1);
        }).map((key, i) => ({value: key, label: pairs[key]}));

        const filterConfig = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'any',
        };


        return (
            <React.Fragment>
                <Select
                    onChange={(selectedOption) => {
                        this.props.onChange(this.props.name, selectedOption ? selectedOption.value : "");
                    }}
                    className="select-css-search"
                    value={{value: this.props.value, label: pairs[this.props.value]}}
                    isClearable={false}
                    isSearchable={true}
                    isDisabled={this.props.disabled}
                    name={this.props.name}
                    options={values}
                    filterOption={createFilter(filterConfig)}
                />
                {this.props.errorMessage && (
                    <div className="field-error-text">
                        <img src="/images/forms/Error.png" alt="error icon" title={`${this.props.errorMessage}`}/>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
