import React, {Component} from "react";
import {connect} from "react-redux";
import BasicLayout from "../../components/basic-layout";
import {
    checkFeature, ETA_FEATURE,
    generateIcon, getEventColor, getKeyStatus, getProp, getRouteStatus, getStopType, getStopTypeString,
    LOCATION_ADDRESS_FEATURE,
    toFrontDateTimeNoSec,
    toFrontDateTimeNoSecFromUTC, toFrontDateTimeNoSecFromUTCAndZone
} from "../../util/util";
import {doneGetResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import LoaderSmall from "../../components/loader-small";
import Env from "../../util/env";
import {processResponse} from "../../data/services/api-util";
import axios from "axios";
import Pagination from "../../components/pagination";
import {Field, FieldsManager} from "../../data/services/fields";
import Dialog from "../../components/dialog/index";
import Button from "../../components/button/index";
import FieldTextarea from "../../components/field-textarea/index";
import NoResults from "../../components/no-results";
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import {getSecondResource, updateSecondResource} from "../../data/actions/secondResource";
import FieldDropdownSelect from "../../components/field-dropdown-select/index";

class LoadDetailsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            routes: [],
            offset: 0,
            limit: 5,
            paginationPage: 1,
            cardStatus: true,
            cardDetails: false,
            cardAnalytics: false,
            finishRouteDialog: false,
            fields: {
                review_note: new Field('review_note', '', ['empty'])
            },
            watchersUpdate: false,
            bottomTabs: 1
        };
    }

    componentDidMount() {
        this.markers = [];
        this.fetchData();

        const id = this.props.match.params && this.props.match.params.id;
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {
                id: id
            },
            resource: Resources.LoadWatcher
        }));

        this.handle = setInterval(this.loadData, 5000);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.resource.data === null) && (!!this.props.resource.data)) {
            this.updateMarkers(getProp(this.props.resource, "data.route.track", []), true);
        } else if ((!!prevProps.resource.isLoading) && (!this.props.resource.isLoading)) {
            this.updateMarkers(getProp(this.props.resource, "data.route.track", []), true)
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    loadData = () => {
        if (LocalStorage.has('user') && LocalStorage.get('user').Contact) {
            axios.get(
                Env.getApiUrl('api/cloud/route/check', {
                    id: this.props.match.params && this.props.match.params.id
                }),
                {
                    headers: {
                        'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                    }
                }
            )
            .then((response) => {
                const resp = processResponse(response);
                if (resp && resp.status === 0) {
                    const id = this.props.match.params && this.props.match.params.id;
                    const routes = getProp(resp, "data.route.track", []);
                    if (routes.length !== getProp(this.props.resource, "data.route.track", []).length) {
                        this.updateMarkers(routes, false, () => {});
                    }
                    this.props.dispatch(doneGetResource(resp.data));
                }
            })
            .catch((e) => {
                console.log(e);
            });
        }
    };

    componentWillUnmount() {
        if (this.handle) {
            clearInterval(this.handle);
            this.handle = null;
        }
    }

    updateState = (state) => {
        const id = this.props.match.params && this.props.match.params.id;
        let paramsAdd = {};
        switch (state) {
            case 'finish':
                this.setState({
                    finishRouteDialog: true
                });
                break;
            case 'finish-confirm':
                state = 'finish';
                paramsAdd = FieldsManager.getFieldKeyValues(this.state.fields);
            default:
                this.props.dispatch(updateResource({
                    user: LocalStorage.get("user"),
                    params: Object.assign({
                        id: id
                    }, paramsAdd),
                    query: {
                        id: id
                    },
                    resource: Resources.Loads + "/" + state,
                    piggyResource: Resources.Load
                }));
        }
    };

    fetchData = () => {
        const id = this.props.match.params && this.props.match.params.id;
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: id
            },
            resource: Resources.Load
        }));
    };

    startEndBound = () => {
        const start = getProp(this.props.resource, "data.route.pickup_customer", {});
        const end = getProp(this.props.resource, "data.route.destination_customer", {});

        if (start.customer_address_lat && end.customer_address_lat) {
            const bounds = new window.google.maps.LatLngBounds();
            const pos1 = new window.google.maps.LatLng(parseFloat(start.customer_address_lat), parseFloat(start.customer_address_lon));
            bounds.extend(pos1);

            const pos2 = new window.google.maps.LatLng(parseFloat(end.customer_address_lat), parseFloat(end.customer_address_lon));
            bounds.extend(pos2);

            this.map.fitBounds(bounds);
        }
    };

    lastEndBound = () => {
        const end = getProp(this.props.resource, "data.route.destination_customer", {});

        if (end.customer_address_lat && end.customer_address_lat) {
            const bounds = new window.google.maps.LatLngBounds();
            const pos1 = new window.google.maps.LatLng(parseFloat(this.state.routes[this.state.routes.length - 1].latitude), parseFloat(this.state.routes[this.state.routes.length - 1].longitude));
            bounds.extend(pos1);

            const pos2 = new window.google.maps.LatLng(parseFloat(end.customer_address_lat), parseFloat(end.customer_address_lon));
            bounds.extend(pos2);

            this.map.fitBounds(bounds);
        }
    };

    updateMarkers = (routes, first = true, callback = () => {}) => {
        const infowindow = new window.google.maps.InfoWindow({
            content: ''
        });

        if (first) {
            let firstPos = null;

            if (routes.length > 0) {
                firstPos = {
                    lat: parseFloat(routes[routes.length - 1].latitude),
                    lng: parseFloat(routes[routes.length - 1].longitude)
                }
            } else {
                const start = getProp(this.props.resource, "data.route.pickup_customer", {});
                firstPos = {
                    lat: parseFloat(start.customer_address_lat),
                    lng: parseFloat(start.customer_address_lon)
                }
            }

            this.map = new window.google.maps.Map(document.getElementById('map'), {
                center: firstPos,
                zoom: 10,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                gestureHandling: 'greedy',
                styles: [
                    {
                        "featureType": "administrative.country",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "hue": "#ff0000"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "stylers": [
                            {
                                "hue": "#FFBB00"
                            },
                            {
                                "saturation": 43.400000000000006
                            },
                            {
                                "lightness": 37.599999999999994
                            },
                            {
                                "gamma": 1
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "stylers": [
                            {
                                "hue": "#FFC200"
                            },
                            {
                                "saturation": -61.8
                            },
                            {
                                "lightness": 45.599999999999994
                            },
                            {
                                "gamma": 1
                            }
                        ]
                    }
                ]
            });

            this.bounds = new window.google.maps.LatLngBounds();

            // Locations
            routes.forEach((item, i) => {
                generateIcon(i + 1, (src) => {
                    const pos = new window.google.maps.LatLng(parseFloat(item.latitude), parseFloat(item.longitude));
                    this.bounds.extend(pos);

                    const addr = toFrontDateTimeNoSecFromUTC(item.route_time_utc) + ": " + (item.detected_address?item.detected_address:(parseFloat(item.latitude) + "," + parseFloat(item.longitude)));
                    let mark = new window.google.maps.Marker({
                        map: this.map,
                        position: pos,
                        title: addr,
                        icon: src
                    });

                    window.google.maps.event.addListener(mark, 'click', () => {
                        infowindow.setContent(addr);
                        infowindow.open(this.map, mark);
                    });

                    this.markers.push(mark);
                }, (i + 1) === routes.length?('#1F8838'):('#0069D9'));
            });

            // Start
            const start = getProp(this.props.resource, "data.route.pickup_customer", {});
            if (start.customer_address_lat && start.customer_address_lon) {
                const startPos = new window.google.maps.LatLng(parseFloat(start.customer_address_lat), parseFloat(start.customer_address_lon));
                this.bounds.extend(startPos);
                new window.google.maps.Marker({
                    map: this.map,
                    position: startPos,
                    title: "Pickup: " + start.customer_address+","+start.customer_city+","+start.customer_state,
                    icon: {
                        url: "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
                    },
                    zIndex: 10000
                });
            }

            // Stops
            const stops = getProp(this.props.resource, "data.route.stops", []);
            stops.forEach((stop) => {
                if (stop.customer_address_lat && stop.customer_address_lon) {
                    new window.google.maps.Marker({
                        map: this.map,
                        position: new window.google.maps.LatLng(parseFloat(stop.customer_address_lat), parseFloat(stop.customer_address_lon)),
                        title: "Stops: " + stop.customer_address + "," + stop.customer_city + "," + stop.customer_state + "(" + getStopTypeString(stop.stop_type) + ")",
                        icon: {
                            url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                        },
                        zIndex: 1000
                    });
                }
            });

            // End
            const end = getProp(this.props.resource, "data.route.destination_customer", {});
            if (end.customer_address_lat && end.customer_address_lon) {
                const endPos = new window.google.maps.LatLng(parseFloat(end.customer_address_lat), parseFloat(end.customer_address_lon));
                this.bounds.extend(endPos);
                new window.google.maps.Marker({
                    map: this.map,
                    position: endPos,
                    title: "Destination: " + end.customer_address+","+end.customer_city+","+end.customer_state,
                    icon: {
                        url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                    },
                    zIndex: 10000
                });
            }

            this.map.fitBounds(this.bounds);

            callback();
        } else {
            this.markers.forEach((marker) => {
                marker.setMap(null);
            });
            this.markers = [];


            routes.forEach((item, i) => {
                generateIcon(i + 1, (src) => {
                    const pos = new window.google.maps.LatLng(parseFloat(item.latitude), parseFloat(item.longitude));

                    const addr = toFrontDateTimeNoSecFromUTC(item.route_time_utc) + ": " + (item.detected_address?item.detected_address:(parseFloat(item.latitude) + "," + parseFloat(item.longitude)));
                    let mark = new window.google.maps.Marker({
                        map: this.map,
                        position: pos,
                        title: addr,
                        icon: src
                    });

                    window.google.maps.event.addListener(mark, 'click', () => {
                        infowindow.setContent(addr);
                        infowindow.open(this.map, mark);
                    });

                    this.markers.push(mark);
                }, (i + 1) === routes.length?('#1F8838'):('#0069D9'));
            });

            callback();
        }
    };

    updateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        });
    };

    showCard = (name) => {
        let cardStatus = false;
        let cardDetails = false;
        let cardAnalytics = false;

        if (name == "status") {
            cardStatus = true;
        } else if (name == "details") {
            cardDetails = true;
        } else {
            cardAnalytics = true;
        }

        this.setState({
            cardStatus: cardStatus,
            cardDetails: cardDetails,
            cardAnalytics: cardAnalytics
        });
    };

    changeViewPos = (lat, lon) => {
        const latLon = new window.google.maps.LatLng(lat, lon);
        this.map.panTo(latLon);
        this.map.setZoom(18);
    };

    updateWatchers = () => {
        this.setState({
            watchersUpdate: false
        }, () => {
            this.props.dispatch(updateSecondResource({
                user: LocalStorage.get('user'),
                params: {
                    id: this.props.match.params.id,
                    watchers: this.state.watchers.map((item) => item.value)
                },
                query: {
                    id: this.props.match.params.id
                },
                resource: Resources.LoadWatcher,
                piggyResource: Resources.LoadWatcher
            }));
        });
    };

    /*
         archived_date: null
         company_id: "1"
         destination_customer_id: "1"
         destination_date: "Dec 12 2019, 12:00 AM"
         destination_end_date: null
         driver_name: "Test"
         driver_phone: "Test"
         last_detected_address: "Užička 12, Beograd, Serbia"
         last_detected_eta: null
         latest: {route_track_id: "1", route_id: "1", latitude: "40.714224", longitude: "73.961452", route_time_utc: "2019-11-06 17:44:48", …}
         load_id: "#123"
         pickup_customer_id: "1"
         pickup_date: "Dec 12 2019, 12:00 AM"
         pickup_end_date: null
         route_activated_utc: null
         route_desc: "Test"
         route_finished_utc: null
         route_id: "1"
         route_status: "1"

         statistics:
         avgS: 0
         avgT: 0
         maxS: 0
         maxT: 0
         minS: 0
         minT: 0

         key:
         route_id: "1"
         route_key_id: "1"
         route_key_status: "0"
         route_key_value: "788506"
     */
    render() {
        const {translate, resource, secondResource} = this.props;

        const loadData = getProp(resource, "data", {});

        /*
         route_synced_utc: "2019-11-11 17:44:48"
         route_time_local: "Nov 6 2019, 11:44 PM"
         route_time_user: "Nov 6 2019, 5:44 PM"
         route_time_utc: "2019-11-06 17:44:48"
         */
        const routeTracks = Array.from(getProp(this.props.resource, "data.route.track", []));
        const tracks = routeTracks.reverse().slice(this.state.offset, this.state.offset + this.state.limit).map((item, i) => {
            return (
                <div
                    className="link-like-element route-track-link"
                    onClick={() => this.changeViewPos(parseFloat(item.latitude), parseFloat(item.longitude))}
                >
                    <div className="inline-block fpx">
                        {routeTracks.length - (i + ((this.state.paginationPage - 1) * 5))}
                    </div>
                    <div className="inline-block fpx">
                        {toFrontDateTimeNoSecFromUTC(item.route_time_utc)}
                    </div>
                    <div className="inline-block fpx">
                        {toFrontDateTimeNoSec(item.route_time_local)}
                    </div>
                    <div className="inline-block">
                        {item.detected_address}
                    </div>
                </div>
            )
        });

        const routeStops = getProp(resource, "data.route.stops", []).map((item) => {
            return (
                <div>
                    <div>Customer: {item.customer_name}</div>
                    <div>Date: {toFrontDateTimeNoSecFromUTC(item.stop_date)}</div>
                    <div>Type: {getStopType(item.stop_type)}</div>
                </div>
            );
        });

        const routeAttributes = getProp(resource, "data.route.attributes", []).map((item) => {
            return (
                <div>
                    <div className="attr-val-medium">{item.attribute_key}</div>
                    <div className="attr-val-large">{item.attribute_value}</div>

                    {(item.is_public == 1) && (
                        <div className="attr-val-small">{("Public")}</div>
                    )}
                </div>
            );
        });

        const timelines = getProp(resource, "data.route.events", []).map((item, i) => {
            return (
                <TimelineItem
                    key={i}
                    dateText={toFrontDateTimeNoSecFromUTC(item.event_date)}
                    dateInnerStyle={{ background: getEventColor(item.event_type), color: "#ffffff" }}
                >
                    <h5>{translate(item.event_key)} </h5>
                    {item.user_fname && (
                        <span className="bold">{item.user_fname} {item.user_lname}</span>
                    )}
                    <p>{item.event_value}</p>
                </TimelineItem>
            );
        });

        const watchers = this.state.watchersUpdate ? this.state.watchers : getProp(secondResource, "data.route.watchers", []).map((item) => {
            return {
                value: item.user_id,
                label: item.user_fname + " " + item.user_lname
            };
        });

        return (
            <BasicLayout {...this.props}>

                <div className="page-inner mg-t-20">
                    <div className="wrapper">

                        <div className="pageheader pd-t-15 pd-b-25">
                            <div className="d-flex justify-content-between">
                                <div className="clearfix">
                                    <h1 className="pd-0 mg-0 tx-26 tx-dark">Load Details - {getProp(loadData, "route.info.load_id", "")}</h1>
                                    <div className="breadcrumb pd-0 mg-0 bg-white">
                                        <span className="breadcrumb-item active">statuses, details and map information</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!resource.isLoading && !!loadData.route && (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-sm-6">
                                    {(loadData.route.info.route_status === "0" ) && (
                                        <div className="btn btn-success btn-sm mg-r-10" onClick={() => {this.updateState("start");}}>
                                            Start
                                        </div>
                                    )}

                                    {(loadData.route.info.route_status === "1" || loadData.route.info.route_status === "2" || loadData.route.info.route_status === "3") && (
                                        <React.Fragment>
                                            <div className="btn btn-warning btn-sm mg-r-10" onClick={() => {
                                                this.updateState("abort");
                                            }}>
                                                Cancel
                                            </div>
                                            <div className="btn btn-primary btn-sm mg-r-10" onClick={() => {
                                                this.updateState("resend");
                                            }}>
                                                Resend Code
                                            </div>
                                            <div className="btn btn-success btn-sm mg-r-10" onClick={() => {
                                                this.updateState("finish");
                                            }}>
                                                Finish
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {(loadData.route.info.route_status === "4"
                                        || loadData.route.info.route_status === "5"
                                        || loadData.route.info.route_status === "9"
                                    ) && (
                                        <React.Fragment>
                                            <div className="btn btn-warning btn-sm mg-r-10" onClick={() => {
                                                this.updateState("reset");
                                            }}>
                                                Reset
                                            </div>
                                        </React.Fragment>
                                    )}
                                    </div>

                                    <div className="col-sm-6 text-right">
                                        <div className="btn btn-secondary btn-sm mg-l-10" onClick={() => this.map.fitBounds(this.bounds)}>
                                            Show load
                                        </div>
                                        <div className="btn btn-secondary btn-sm mg-l-10" onClick={this.startEndBound}>
                                            Show start/end
                                        </div>
                                        {(this.state.routes.length > 0) && (
                                            <div className="btn btn-secondary btn-sm mg-l-10" onClick={this.lastEndBound}>
                                                Show last/end
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-sm-5 mg-t-15">
                                        <div className="card">

                                            <div className="card-body">
                                                <h5 className="card-title">Load information cards</h5>

                                                <div className={"btn btn-primary btn-sm mg-r-10 " + (this.state.cardStatus?"disabled":"")} onClick={() =>
                                                    (this.state.cardStatus)?"":this.showCard("status")
                                                }>
                                                    Show load status
                                                </div>
                                                <div className={"btn btn-primary btn-sm mg-r-10 " + (this.state.cardDetails?"disabled":"")} onClick={() =>
                                                    (this.state.cardDetails)?"":this.showCard("details")
                                                }>
                                                    Show load details
                                                </div>
                                                <div className={"btn btn-primary btn-sm mg-r-10 " + (this.state.cardAnalytics?"disabled":"")} onClick={() =>
                                                    (this.state.cardAnalytics)?"":this.showCard("analytics")
                                                }>
                                                    Show analytics
                                                </div>
                                            </div>

                                            {this.state.cardStatus && (
                                                <React.Fragment>
                                                    <div className="card-body">
                                                        <h5 className="card-title">{translate("text.status")}</h5>
                                                        <p className="card-text">Information about load and load codes</p>
                                                    </div>

                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="bold">{translate("text.route_status")}</span>
                                                            {getRouteStatus(loadData.route.info.route_status)}
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            {translate("text.route_key_value")}
                                                            <span className="badge badge-primary badge-pill">{loadData.route.key.route_key_value}</span>
                                                        </li>
                                                        {(parseInt(loadData.route.info.route_status) >= 1) && (
                                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                {translate("text.route_key_value_sent")}
                                                                <span className="badge badge-light">{toFrontDateTimeNoSecFromUTC(loadData.route.info.route_sent_utc)}</span>
                                                            </li>
                                                        )}

                                                        {(parseInt(loadData.route.info.route_status) >= 2) && (
                                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                {translate("text.route_status_activate_time")}
                                                                <span className="badge badge-light">{toFrontDateTimeNoSecFromUTC(loadData.route.info.route_activated_utc)}</span>
                                                            </li>
                                                        )}

                                                        {(parseInt(loadData.route.info.route_status) >= 4) && (
                                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                {translate("text.route_status_finish_time")}
                                                                <span className="badge badge-light">{toFrontDateTimeNoSecFromUTC(loadData.route.info.route_finished_utc)}</span>
                                                            </li>
                                                        )}


                                                        {(checkFeature(LocalStorage.get('user'), LOCATION_ADDRESS_FEATURE) || checkFeature(LocalStorage.get('user'), ETA_FEATURE)) && (
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="bold">{translate("text.last_detected_address_eta_time")}</span>
                                                            <span className="badge badge-warning">{toFrontDateTimeNoSecFromUTC(loadData.route.info.last_detected_address_eta_time)}</span>
                                                        </li>
                                                        )}

                                                        {checkFeature(LocalStorage.get('user'), LOCATION_ADDRESS_FEATURE) && (
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            {translate("text.last_detected_address")}
                                                            <span className="badge badge-secondary">{loadData.route.info.last_detected_address}</span>
                                                        </li>
                                                        )}

                                                        {checkFeature(LocalStorage.get('user'), ETA_FEATURE) && (
                                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                {translate("text.last_detected_eta")}
                                                                <span className="badge badge-warning">{toFrontDateTimeNoSecFromUTC(loadData.route.info.last_detected_eta)}</span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </React.Fragment>
                                            )}

                                            {this.state.cardDetails && (
                                                <React.Fragment>
                                                    <div className="card-body">
                                                        <h5 className="card-title">{translate("text.route_details")}</h5>
                                                        <p className="card-text">General information about load</p>
                                                    </div>
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            {translate("text.route_desc")}
                                                            <span className="badge badge-light">{loadData.route.info.route_desc}</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="bold">{translate("text.driver_name")}</span>
                                                            <span className="badge badge-light">{loadData.route.info.driver_fname} {loadData.route.info.driver_lname}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            {translate("text.driver_phone")}
                                                            <span className="badge badge-light">{loadData.route.info.driver_phone}</span>
                                                        </li>


                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="bold">{translate("text.shipper")}</span>
                                                            <span className="badge badge-secondary">{loadData.route.pickup_customer.customer_name}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            {translate("text.pickup_address")}
                                                            <span className="badge badge-secondary">{loadData.route.pickup_customer.customer_address}, {loadData.route.pickup_customer.customer_city}, {loadData.route.pickup_customer.customer_state}, {loadData.route.pickup_customer.customer_zip}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            {translate("text.pickup_date_user")}
                                                            <span className="badge badge-secondary">{toFrontDateTimeNoSecFromUTC(loadData.route.info.pickup_date)}{loadData.route.info.pickup_end_date?" - ":""}{toFrontDateTimeNoSecFromUTC(loadData.route.info.pickup_end_date)}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            {translate("text.pickup_date")}
                                                            <span className="badge badge-secondary">{toFrontDateTimeNoSecFromUTCAndZone(loadData.route.info.pickup_date, loadData.route.info.pickup_timezone)}{loadData.route.info.pickup_end_date?" - ":""}{toFrontDateTimeNoSecFromUTCAndZone(loadData.route.info.pickup_end_date, loadData.route.info.pickup_timezone)}</span>
                                                        </li>


                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="bold">{translate("text.consignee")}</span>
                                                            <span className="badge badge-secondary">{loadData.route.destination_customer.customer_name}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            {translate("text.destination_address")}
                                                            <span className="badge badge-secondary">{loadData.route.destination_customer.customer_address}, {loadData.route.destination_customer.customer_city}, {loadData.route.destination_customer.customer_state}, {loadData.route.destination_customer.customer_zip}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            {translate("text.destination_date_user")}
                                                            <span className="badge badge-secondary">{toFrontDateTimeNoSecFromUTC(loadData.route.info.destination_date)}{loadData.route.info.destination_end_date?" - ":""}{toFrontDateTimeNoSecFromUTC(loadData.route.info.destination_end_date)}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            {translate("text.destination_date")}
                                                            <span className="badge badge-secondary">{toFrontDateTimeNoSecFromUTCAndZone(loadData.route.info.destination_date, loadData.route.info.destination_timezone)}{loadData.route.info.destination_end_date?" - ":""}{toFrontDateTimeNoSecFromUTCAndZone(loadData.route.info.destination_end_date, loadData.route.info.destination_timezone)}</span>
                                                        </li>

                                                        {!!loadData.route.info.carrier_name && (
                                                            <React.Fragment>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="bold">{translate("text.carrier")}</span>
                                                                    <span className="badge badge-secondary">{loadData.route.info.carrier_name}</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    {translate("text.carrier_mc")}
                                                                    <span className="badge badge-secondary">{loadData.route.info.carrier_mc}</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    {translate("text.carrier_phone")}
                                                                    <span className="badge badge-secondary">{loadData.route.info.carrier_phone}</span>
                                                                </li>
                                                            </React.Fragment>
                                                        )}
                                                    </ul>

                                                    {(routeStops.length > 0) && (
                                                        <div className="card-body">
                                                            <h5 className="card-title">Load stops</h5>
                                                            {routeStops}
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}

                                            {this.state.cardAnalytics && (
                                                <React.Fragment>
                                                    <div className="card-body">
                                                        <h5 className="card-title">Analytics</h5>
                                                        <p className="card-text">Time between synchronization (Device-Server / GPS-Device)</p>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    Shortest time
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    Average time
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    Longest time
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="badge badge-secondary mg-b-3 mg-t-3">{loadData.route.info.statistics.minS}</span>
                                                                    <span className="badge badge-secondary mg-b-3 mg-t-3">{loadData.route.info.statistics.minT}</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="badge badge-secondary mg-b-3 mg-t-3">{loadData.route.info.statistics.avgS}</span>
                                                                    <span className="badge badge-secondary mg-b-3 mg-t-3">{loadData.route.info.statistics.avgT}</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="badge badge-secondary mg-b-3 mg-t-3">{loadData.route.info.statistics.maxS}</span>
                                                                    <span className="badge badge-secondary mg-b-3 mg-t-3">{loadData.route.info.statistics.maxT}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}

                                        </div>
                                    </div>

                                    <div className="col-sm-7 mg-t-15">
                                        <div id="map" className={"google-map"}>
                                        </div>
                                    </div>
                            </div>

                            <div className="card mg-t-20 min-height-220">

                                <div className="card-body">
                                    <h5 className="card-title">Additional information</h5>

                                    <div className={"btn btn-primary btn-sm mg-r-10 " + ((this.state.bottomTabs === 1)?"disabled":"")} onClick={() =>
                                        (this.state.bottomTabs === 1)?"":this.setState({bottomTabs: 1})
                                    }>
                                        Tracks
                                    </div>
                                    <div className={"btn btn-primary btn-sm mg-r-10 " + ((this.state.bottomTabs === 2)?"disabled":"")} onClick={() =>
                                        (this.state.bottomTabs === 2)?"":this.setState({bottomTabs: 2})
                                    }>
                                        Cargo details
                                    </div>

                                    {!LocalStorage.get('user').Contact.resource_restrict && (
                                        <div className={"btn btn-primary btn-sm mg-r-10 " + ((this.state.bottomTabs === 3)?"disabled":"")} onClick={() =>
                                            (this.state.bottomTabs === 3)?"":this.setState({bottomTabs: 3})
                                        }>
                                            Watchers
                                        </div>
                                    )}


                                {(this.state.bottomTabs === 1) && (tracks.length === 0) && (
                                    <React.Fragment>
                                        <NoResults text={this.props.translate("text.no_data")}/>
                                    </React.Fragment>
                                )}

                                {(this.state.bottomTabs === 1) && tracks.length > 0 && (
                                    <React.Fragment>
                                        <h5 className="card-title">{translate("text.detected_positions")}</h5>
                                        <p className="card-text">Table containing data from all detected driver positions</p>

                                        <div className="route-track-link">
                                            <div className="inline-block fpx">
                                                {"#"}
                                            </div>
                                            <div className="inline-block fpx">
                                                {translate("text.route_time_local")}
                                            </div>
                                            <div className="inline-block fpx">
                                                {translate("text.last_time_user")}
                                            </div>
                                                <div className="inline-block">
                                            {translate("text.last_address")}
                                            </div>
                                        </div>

                                        {tracks}
                                        <Pagination
                                            addClass="route-details-pagination"
                                            updateOffset={this.updateOffset}
                                            paginationButtonLimit={5}
                                            limit={this.state.limit}
                                            offset={this.state.offset}
                                            count={routeTracks.length}
                                            paginationPage={this.state.paginationPage}
                                        />
                                    </React.Fragment>
                                )}

                                {(this.state.bottomTabs === 2) && routeAttributes.length > 0 && (
                                    <React.Fragment>
                                        {routeAttributes}
                                    </React.Fragment>
                                )}

                                {(this.state.bottomTabs === 2) && (routeAttributes.length === 0) && (
                                    <React.Fragment>
                                        <NoResults text={this.props.translate("text.no_data")}/>
                                    </React.Fragment>
                                )}

                                {secondResource.isLoading && (this.state.bottomTabs === 3) && (
                                    <LoaderSmall addClass={"loader-page"}/>
                                )}

                                {!secondResource.isLoading && (this.state.bottomTabs === 3) && (
                                    <div className="margin-top-10">
                                        <FieldDropdownSelect
                                             onChange={(name, value) => {
                                                this.setState({
                                                    watchers: value,
                                                    watchersUpdate: true
                                                })
                                             }}
                                             value={watchers}
                                             name={"watchers"}
                                             isMulti={true}
                                             loadOptions={
                                                 (inputValue, callback) => {
                                                     axios.get(
                                                         Env.getApiUrl('api/cloud/company-users', {query: inputValue, teams: 1}),
                                                         {
                                                             headers: {
                                                                 'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                             }
                                                         }
                                                     )
                                                     .then((response) => {
                                                         const result = processResponse(response);
                                                         if (result && result.status === 0) {
                                                             const list = result.data.list.map((item) => {
                                                                 if (item.type) {
                                                                     return {
                                                                         value: item.user_group_id,
                                                                         label: item.user_group_name
                                                                     }
                                                                 } else {
                                                                     return {
                                                                         value: item.user_id,
                                                                         label: item.user_fname + " " + item.user_lname
                                                                     }
                                                                 }
                                                             });
                                                             callback(list);
                                                         }
                                                     })
                                                     .catch((error) => {
                                                     });
                                                 }
                                             }
                                        />

                                        <div className="margin-top-10">
                                            <Button className={"btn btn-brand margin-right-10" + (this.state.watchersUpdate?"":" disabled")}
                                                    onClick={() => {
                                                        if (this.state.watchersUpdate) {
                                                            this.updateWatchers();
                                                        }
                                                    }} text={"Save"}/>
                                            <Button className={"btn btn-brand" + (this.state.watchersUpdate?"":" disabled")}
                                                    onClick={() => this.setState({watchersUpdate: false})} text={"Cancel"}/>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>

                            {(timelines.length > 0) && (
                                <div className="card mg-t-20">
                                    <div className="card-body">
                                        <h5 className="card-title">Events time line</h5>

                                        <Timeline lineColor={'#ddd'}>
                                            {timelines}
                                        </Timeline>
                                    </div>
                                </div>
                            )}
                            </React.Fragment>
                        )}
                    </div>
                </div>

                {resource.isLoading && (
                    <LoaderSmall addClass={"loader-page"}/>
                )}

                <Dialog
                    active={!!this.state.finishRouteDialog}
                    addClass={"small"}
                >
                    <a className="close-dialog" title="Close"
                       onClick={() => {
                           this.setState({
                               finishRouteDialog: false
                           });
                       }}
                    ><i className="ti-close"></i> </a>

                    <div className="dialog-title-wrapper">
                        <div className="dialog-main-title">Confirm route finish</div>
                    </div>
                    <div className="dialog-text-wrapper">
                        You are about to finish route, please confirm your action?
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                                <label>Leave comment about driver?</label>
                                <FieldTextarea addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.review_note} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button className="btn btn-brand" onClick={() => { this.setState({
                            finishRouteDialog: false
                        }, () => {
                            this.updateState("finish-confirm");
                        })}} text={"Confirm"}/>
                    </div>
                </Dialog>
            </BasicLayout>
        );
    }
}

export default connect(state => state)(LoadDetailsView);