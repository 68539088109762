export default class Resources {
    static Loads = "routes";
    static Load = "route";

    static LoadWatcher = "routes/watchers";

    static AddressBook = "address-book";
    static Carriers = "carriers";
    static CarriersExport = "carriers/export";
    static AddressBookExport = "address-book/export";
    static CompanyDetails = "company-details";
    static CompanyUsers = "company-users";
    static CompanyDrivers = "company-drivers";
    static CompanyDriversExport = "company-drivers/export";
    static User = "user";

    static RouteNote = "route/note";

    static CompanyDriversReview = "company-drivers/review";
    static AddressBookReview = "address-book/review";

    static Support = "support";
}