import React, {Component} from "react";
import {connect} from "react-redux";
import BasicLayout from "../../components/basic-layout";
import LoaderSmall from "../../components/loader-small";
import FieldText from "../../components/field-text";
import FieldSelect from "../../components/field-select";
import Button from "../../components/button";
import {getResource, resetResourceMessage, updateResource} from "../../data/actions/resource";
import Resources from "../../data/services/resources";
import LocalStorage from "../../util/localStorage";
import {Field, FieldsManager} from "../../data/services/fields";
import {getProp} from "../../util/util";
import NotificationSuccessMessage from "../../components/notification-success-message";
import NotificationErrorMessage from "../../components/notification-error-message";
import {updateUserInfo} from "../../data/actions/user";

class ProfileView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {

            },
            loaded: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const info = getProp(nextProps, "user.data.Contact", {});

        if (!prevState.loaded && !!info.user_fname) {
            return {
                fields: {
                    user_fname: new Field('user_fname', info.user_fname, ['empty']),
                    user_lname: new Field('user_lname', info.user_lname, ['empty']),
                    user_email: new Field('user_email', info.user_email, ['email']),
                    user_phone: new Field('user_phone', info.user_phone, ['empty']),
                    user_zone: new Field('user_zone', info.user_zone, ['empty']),
                    user_datetimeformat: new Field('user_datetimeformat', info.user_datetimeformat, ['empty']),
                },
                loaded: true
            }
        }
        else return null;
    }

    componentDidUpdate = (prevProps) => {
        if (((!prevProps.resource.update) && (this.props.resource.update)) ||
            ((!prevProps.resource.error) && (this.props.resource.error))) {
            setTimeout(() => this.props.dispatch(resetResourceMessage()), 5000);
        }
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: Resources.User
        }))
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({
                            id: this.props.id,
                        }, FieldsManager.getFieldKeyValues(this.state.fields)
                    ),
                    resource: Resources.User
                }));

                const user = Object.assign({}, LocalStorage.get('user'), {
                    Contact: Object.assign(LocalStorage.get('user')['Contact'], FieldsManager.getFieldKeyValues(this.state.fields))
                });
                this.props.dispatch(updateUserInfo(user));
                LocalStorage.set('user', user);
            }
        })
    };

    render() {
        const {translate, resource} = this.props;

        return (
            <BasicLayout {...this.props}>

                <div className="page-inner mg-t-20">

                    <div className="wrapper">

                        <div className="pageheader pd-t-15 pd-b-25">
                            <div className="d-flex justify-content-between">
                                <div className="clearfix">
                                    <h1 className="pd-0 mg-0 tx-26 tx-dark">User Profile</h1>
                                    <div className="breadcrumb pd-0 mg-0 bg-white">
                                        <span className="breadcrumb-item active">Your profile and settings</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {resource.isLoading  && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}

                        {!resource.isLoading && (
                        <div className="row row-xs clearfix">
                            <div className="col-md-12 col-lg-12">
                                <div className="card">

                                    <div className="card-body">
                                        <form onKeyPress={this.submitForm}>

                                            <React.Fragment>
                                                <div className="form-row">

                                                    <div className="col-md-6 mb-3">
                                                        <label>First name*</label>
                                                        <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_fname} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label>Last name*</label>
                                                        <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_lname} />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <label>Email*</label>
                                                        <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_email} />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <label>Phone*</label>
                                                        <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_phone} />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <label>Time zone*</label>
                                                        <FieldSelect addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_zone}>
                                                                <option>Africa/Abidjan</option>
                                                                <option>Africa/Accra</option>
                                                                <option>Africa/Addis_Ababa</option>
                                                                <option>Africa/Algiers</option>
                                                                <option>Africa/Asmara</option>
                                                                <option>Africa/Bamako</option>
                                                                <option>Africa/Bangui</option>
                                                                <option>Africa/Banjul</option>
                                                                <option>Africa/Bissau</option>
                                                                <option>Africa/Blantyre</option>
                                                                <option>Africa/Brazzaville</option>
                                                                <option>Africa/Bujumbura</option>
                                                                <option>Africa/Cairo</option>
                                                                <option>Africa/Casablanca</option>
                                                                <option>Africa/Ceuta</option>
                                                                <option>Africa/Conakry</option>
                                                                <option>Africa/Dakar</option>
                                                                <option>Africa/Dar_es_Salaam</option>
                                                                <option>Africa/Djibouti</option>
                                                                <option>Africa/Douala</option>
                                                                <option>Africa/El_Aaiun</option>
                                                                <option>Africa/Freetown</option>
                                                                <option>Africa/Gaborone</option>
                                                                <option>Africa/Harare</option>
                                                                <option>Africa/Johannesburg</option>
                                                                <option>Africa/Juba</option>
                                                                <option>Africa/Kampala</option>
                                                                <option>Africa/Khartoum</option>
                                                                <option>Africa/Kigali</option>
                                                                <option>Africa/Kinshasa</option>
                                                                <option>Africa/Lagos</option>
                                                                <option>Africa/Libreville</option>
                                                                <option>Africa/Lome</option>
                                                                <option>Africa/Luanda</option>
                                                                <option>Africa/Lubumbashi</option>
                                                                <option>Africa/Lusaka</option>
                                                                <option>Africa/Malabo</option>
                                                                <option>Africa/Maputo</option>
                                                                <option>Africa/Maseru</option>
                                                                <option>Africa/Mbabane</option>
                                                                <option>Africa/Mogadishu</option>
                                                                <option>Africa/Monrovia</option>
                                                                <option>Africa/Nairobi</option>
                                                                <option>Africa/Ndjamena</option>
                                                                <option>Africa/Niamey</option>
                                                                <option>Africa/Nouakchott</option>
                                                                <option>Africa/Ouagadougou</option>
                                                                <option>Africa/Porto-Novo</option>
                                                                <option>Africa/Sao_Tome</option>
                                                                <option>Africa/Tripoli</option>
                                                                <option>Africa/Tunis</option>
                                                                <option>Africa/Windhoek</option>
                                                                <option>America/Adak</option>
                                                                <option>America/Anchorage</option>
                                                                <option>America/Anguilla</option>
                                                                <option>America/Antigua</option>
                                                                <option>America/Araguaina</option>
                                                                <option>America/Argentina/Buenos_Aires</option>
                                                                <option>America/Argentina/Catamarca</option>
                                                                <option>America/Argentina/Cordoba</option>
                                                                <option>America/Argentina/Jujuy</option>
                                                                <option>America/Argentina/La_Rioja</option>
                                                                <option>America/Argentina/Mendoza</option>
                                                                <option>America/Argentina/Rio_Gallegos</option>
                                                                <option>America/Argentina/Salta</option>
                                                                <option>America/Argentina/San_Juan</option>
                                                                <option>America/Argentina/San_Luis</option>
                                                                <option>America/Argentina/Tucuman</option>
                                                                <option>America/Argentina/Ushuaia</option>
                                                                <option>America/Aruba</option>
                                                                <option>America/Asuncion</option>
                                                                <option>America/Atikokan</option>
                                                                <option>America/Bahia</option>
                                                                <option>America/Bahia_Banderas</option>
                                                                <option>America/Barbados</option>
                                                                <option>America/Belem</option>
                                                                <option>America/Belize</option>
                                                                <option>America/Blanc-Sablon</option>
                                                                <option>America/Boa_Vista</option>
                                                                <option>America/Bogota</option>
                                                                <option>America/Boise</option>
                                                                <option>America/Cambridge_Bay</option>
                                                                <option>America/Campo_Grande</option>
                                                                <option>America/Cancun</option>
                                                                <option>America/Caracas</option>
                                                                <option>America/Cayenne</option>
                                                                <option>America/Cayman</option>
                                                                <option>America/Chicago</option>
                                                                <option>America/Chihuahua</option>
                                                                <option>America/Costa_Rica</option>
                                                                <option>America/Creston</option>
                                                                <option>America/Cuiaba</option>
                                                                <option>America/Curacao</option>
                                                                <option>America/Danmarkshavn</option>
                                                                <option>America/Dawson</option>
                                                                <option>America/Dawson_Creek</option>
                                                                <option>America/Denver</option>
                                                                <option>America/Detroit</option>
                                                                <option>America/Dominica</option>
                                                                <option>America/Edmonton</option>
                                                                <option>America/Eirunepe</option>
                                                                <option>America/El_Salvador</option>
                                                                <option>America/Fort_Nelson</option>
                                                                <option>America/Fortaleza</option>
                                                                <option>America/Glace_Bay</option>
                                                                <option>America/Godthab</option>
                                                                <option>America/Goose_Bay</option>
                                                                <option>America/Grand_Turk</option>
                                                                <option>America/Grenada</option>
                                                                <option>America/Guadeloupe</option>
                                                                <option>America/Guatemala</option>
                                                                <option>America/Guayaquil</option>
                                                                <option>America/Guyana</option>
                                                                <option>America/Halifax</option>
                                                                <option>America/Havana</option>
                                                                <option>America/Hermosillo</option>
                                                                <option>America/Indiana/Indianapolis</option>
                                                                <option>America/Indiana/Knox</option>
                                                                <option>America/Indiana/Marengo</option>
                                                                <option>America/Indiana/Petersburg</option>
                                                                <option>America/Indiana/Tell_City</option>
                                                                <option>America/Indiana/Vevay</option>
                                                                <option>America/Indiana/Vincennes</option>
                                                                <option>America/Indiana/Winamac</option>
                                                                <option>America/Inuvik</option>
                                                                <option>America/Iqaluit</option>
                                                                <option>America/Jamaica</option>
                                                                <option>America/Juneau</option>
                                                                <option>America/Kentucky/Louisville</option>
                                                                <option>America/Kentucky/Monticello</option>
                                                                <option>America/Kralendijk</option>
                                                                <option>America/La_Paz</option>
                                                                <option>America/Lima</option>
                                                                <option>America/Los_Angeles</option>
                                                                <option>America/Lower_Princes</option>
                                                                <option>America/Maceio</option>
                                                                <option>America/Managua</option>
                                                                <option>America/Manaus</option>
                                                                <option>America/Marigot</option>
                                                                <option>America/Martinique</option>
                                                                <option>America/Matamoros</option>
                                                                <option>America/Mazatlan</option>
                                                                <option>America/Menominee</option>
                                                                <option>America/Merida</option>
                                                                <option>America/Metlakatla</option>
                                                                <option>America/Mexico_City</option>
                                                                <option>America/Miquelon</option>
                                                                <option>America/Moncton</option>
                                                                <option>America/Monterrey</option>
                                                                <option>America/Montevideo</option>
                                                                <option>America/Montserrat</option>
                                                                <option>America/Nassau</option>
                                                                <option>America/New_York</option>
                                                                <option>America/Nipigon</option>
                                                                <option>America/Nome</option>
                                                                <option>America/Noronha</option>
                                                                <option>America/North_Dakota/Beulah</option>
                                                                <option>America/North_Dakota/Center</option>
                                                                <option>America/North_Dakota/New_Salem</option>
                                                                <option>America/Ojinaga</option>
                                                                <option>America/Panama</option>
                                                                <option>America/Pangnirtung</option>
                                                                <option>America/Paramaribo</option>
                                                                <option>America/Phoenix</option>
                                                                <option>America/Port-au-Prince</option>
                                                                <option>America/Port_of_Spain</option>
                                                                <option>America/Porto_Velho</option>
                                                                <option>America/Puerto_Rico</option>
                                                                <option>America/Punta_Arenas</option>
                                                                <option>America/Rainy_River</option>
                                                                <option>America/Rankin_Inlet</option>
                                                                <option>America/Recife</option>
                                                                <option>America/Regina</option>
                                                                <option>America/Resolute</option>
                                                                <option>America/Rio_Branco</option>
                                                                <option>America/Santarem</option>
                                                                <option>America/Santiago</option>
                                                                <option>America/Santo_Domingo</option>
                                                                <option>America/Sao_Paulo</option>
                                                                <option>America/Scoresbysund</option>
                                                                <option>America/Sitka</option>
                                                                <option>America/St_Barthelemy</option>
                                                                <option>America/St_Johns</option>
                                                                <option>America/St_Kitts</option>
                                                                <option>America/St_Lucia</option>
                                                                <option>America/St_Thomas</option>
                                                                <option>America/St_Vincent</option>
                                                                <option>America/Swift_Current</option>
                                                                <option>America/Tegucigalpa</option>
                                                                <option>America/Thule</option>
                                                                <option>America/Thunder_Bay</option>
                                                                <option>America/Tijuana</option>
                                                                <option>America/Toronto</option>
                                                                <option>America/Tortola</option>
                                                                <option>America/Vancouver</option>
                                                                <option>America/Whitehorse</option>
                                                                <option>America/Winnipeg</option>
                                                                <option>America/Yakutat</option>
                                                                <option>America/Yellowknife</option>
                                                                <option>Antarctica/Casey</option>
                                                                <option>Antarctica/Davis</option>
                                                                <option>Antarctica/DumontDUrville</option>
                                                                <option>Antarctica/Macquarie</option>
                                                                <option>Antarctica/Mawson</option>
                                                                <option>Antarctica/McMurdo</option>
                                                                <option>Antarctica/Palmer</option>
                                                                <option>Antarctica/Rothera</option>
                                                                <option>Antarctica/Syowa</option>
                                                                <option>Antarctica/Troll</option>
                                                                <option>Antarctica/Vostok</option>
                                                                <option>Arctic/Longyearbyen</option>
                                                                <option>Asia/Aden</option>
                                                                <option>Asia/Almaty</option>
                                                                <option>Asia/Amman</option>
                                                                <option>Asia/Anadyr</option>
                                                                <option>Asia/Aqtau</option>
                                                                <option>Asia/Aqtobe</option>
                                                                <option>Asia/Ashgabat</option>
                                                                <option>Asia/Atyrau</option>
                                                                <option>Asia/Baghdad</option>
                                                                <option>Asia/Bahrain</option>
                                                                <option>Asia/Baku</option>
                                                                <option>Asia/Bangkok</option>
                                                                <option>Asia/Barnaul</option>
                                                                <option>Asia/Beirut</option>
                                                                <option>Asia/Bishkek</option>
                                                                <option>Asia/Brunei</option>
                                                                <option>Asia/Chita</option>
                                                                <option>Asia/Choibalsan</option>
                                                                <option>Asia/Colombo</option>
                                                                <option>Asia/Damascus</option>
                                                                <option>Asia/Dhaka</option>
                                                                <option>Asia/Dili</option>
                                                                <option>Asia/Dubai</option>
                                                                <option>Asia/Dushanbe</option>
                                                                <option>Asia/Famagusta</option>
                                                                <option>Asia/Gaza</option>
                                                                <option>Asia/Hebron</option>
                                                                <option>Asia/Ho_Chi_Minh</option>
                                                                <option>Asia/Hong_Kong</option>
                                                                <option>Asia/Hovd</option>
                                                                <option>Asia/Irkutsk</option>
                                                                <option>Asia/Jakarta</option>
                                                                <option>Asia/Jayapura</option>
                                                                <option>Asia/Jerusalem</option>
                                                                <option>Asia/Kabul</option>
                                                                <option>Asia/Kamchatka</option>
                                                                <option>Asia/Karachi</option>
                                                                <option>Asia/Kathmandu</option>
                                                                <option>Asia/Khandyga</option>
                                                                <option>Asia/Kolkata</option>
                                                                <option>Asia/Krasnoyarsk</option>
                                                                <option>Asia/Kuala_Lumpur</option>
                                                                <option>Asia/Kuching</option>
                                                                <option>Asia/Kuwait</option>
                                                                <option>Asia/Macau</option>
                                                                <option>Asia/Magadan</option>
                                                                <option>Asia/Makassar</option>
                                                                <option>Asia/Manila</option>
                                                                <option>Asia/Muscat</option>
                                                                <option>Asia/Nicosia</option>
                                                                <option>Asia/Novokuznetsk</option>
                                                                <option>Asia/Novosibirsk</option>
                                                                <option>Asia/Omsk</option>
                                                                <option>Asia/Oral</option>
                                                                <option>Asia/Phnom_Penh</option>
                                                                <option>Asia/Pontianak</option>
                                                                <option>Asia/Pyongyang</option>
                                                                <option>Asia/Qatar</option>
                                                                <option>Asia/Qostanay</option>
                                                                <option>Asia/Qyzylorda</option>
                                                                <option>Asia/Riyadh</option>
                                                                <option>Asia/Sakhalin</option>
                                                                <option>Asia/Samarkand</option>
                                                                <option>Asia/Seoul</option>
                                                                <option>Asia/Shanghai</option>
                                                                <option>Asia/Singapore</option>
                                                                <option>Asia/Srednekolymsk</option>
                                                                <option>Asia/Taipei</option>
                                                                <option>Asia/Tashkent</option>
                                                                <option>Asia/Tbilisi</option>
                                                                <option>Asia/Tehran</option>
                                                                <option>Asia/Thimphu</option>
                                                                <option>Asia/Tokyo</option>
                                                                <option>Asia/Tomsk</option>
                                                                <option>Asia/Ulaanbaatar</option>
                                                                <option>Asia/Urumqi</option>
                                                                <option>Asia/Ust-Nera</option>
                                                                <option>Asia/Vientiane</option>
                                                                <option>Asia/Vladivostok</option>
                                                                <option>Asia/Yakutsk</option>
                                                                <option>Asia/Yangon</option>
                                                                <option>Asia/Yekaterinburg</option>
                                                                <option>Asia/Yerevan</option>
                                                                <option>Atlantic/Azores</option>
                                                                <option>Atlantic/Bermuda</option>
                                                                <option>Atlantic/Canary</option>
                                                                <option>Atlantic/Cape_Verde</option>
                                                                <option>Atlantic/Faroe</option>
                                                                <option>Atlantic/Madeira</option>
                                                                <option>Atlantic/Reykjavik</option>
                                                                <option>Atlantic/South_Georgia</option>
                                                                <option>Atlantic/St_Helena</option>
                                                                <option>Atlantic/Stanley</option>
                                                                <option>Australia/Adelaide</option>
                                                                <option>Australia/Brisbane</option>
                                                                <option>Australia/Broken_Hill</option>
                                                                <option>Australia/Currie</option>
                                                                <option>Australia/Darwin</option>
                                                                <option>Australia/Eucla</option>
                                                                <option>Australia/Hobart</option>
                                                                <option>Australia/Lindeman</option>
                                                                <option>Australia/Lord_Howe</option>
                                                                <option>Australia/Melbourne</option>
                                                                <option>Australia/Perth</option>
                                                                <option>Australia/Sydney</option>
                                                                <option>Europe/Amsterdam</option>
                                                                <option>Europe/Andorra</option>
                                                                <option>Europe/Astrakhan</option>
                                                                <option>Europe/Athens</option>
                                                                <option>Europe/Belgrade</option>
                                                                <option>Europe/Berlin</option>
                                                                <option>Europe/Bratislava</option>
                                                                <option>Europe/Brussels</option>
                                                                <option>Europe/Bucharest</option>
                                                                <option>Europe/Budapest</option>
                                                                <option>Europe/Busingen</option>
                                                                <option>Europe/Chisinau</option>
                                                                <option>Europe/Copenhagen</option>
                                                                <option>Europe/Dublin</option>
                                                                <option>Europe/Gibraltar</option>
                                                                <option>Europe/Guernsey</option>
                                                                <option>Europe/Helsinki</option>
                                                                <option>Europe/Isle_of_Man</option>
                                                                <option>Europe/Istanbul</option>
                                                                <option>Europe/Jersey</option>
                                                                <option>Europe/Kaliningrad</option>
                                                                <option>Europe/Kiev</option>
                                                                <option>Europe/Kirov</option>
                                                                <option>Europe/Lisbon</option>
                                                                <option>Europe/Ljubljana</option>
                                                                <option>Europe/London</option>
                                                                <option>Europe/Luxembourg</option>
                                                                <option>Europe/Madrid</option>
                                                                <option>Europe/Malta</option>
                                                                <option>Europe/Mariehamn</option>
                                                                <option>Europe/Minsk</option>
                                                                <option>Europe/Monaco</option>
                                                                <option>Europe/Moscow</option>
                                                                <option>Europe/Oslo</option>
                                                                <option>Europe/Paris</option>
                                                                <option>Europe/Podgorica</option>
                                                                <option>Europe/Prague</option>
                                                                <option>Europe/Riga</option>
                                                                <option>Europe/Rome</option>
                                                                <option>Europe/Samara</option>
                                                                <option>Europe/San_Marino</option>
                                                                <option>Europe/Sarajevo</option>
                                                                <option>Europe/Saratov</option>
                                                                <option>Europe/Simferopol</option>
                                                                <option>Europe/Skopje</option>
                                                                <option>Europe/Sofia</option>
                                                                <option>Europe/Stockholm</option>
                                                                <option>Europe/Tallinn</option>
                                                                <option>Europe/Tirane</option>
                                                                <option>Europe/Ulyanovsk</option>
                                                                <option>Europe/Uzhgorod</option>
                                                                <option>Europe/Vaduz</option>
                                                                <option>Europe/Vatican</option>
                                                                <option>Europe/Vienna</option>
                                                                <option>Europe/Vilnius</option>
                                                                <option>Europe/Volgograd</option>
                                                                <option>Europe/Warsaw</option>
                                                                <option>Europe/Zagreb</option>
                                                                <option>Europe/Zaporozhye</option>
                                                                <option>Europe/Zurich</option>
                                                                <option>Indian/Antananarivo</option>
                                                                <option>Indian/Chagos</option>
                                                                <option>Indian/Christmas</option>
                                                                <option>Indian/Cocos</option>
                                                                <option>Indian/Comoro</option>
                                                                <option>Indian/Kerguelen</option>
                                                                <option>Indian/Mahe</option>
                                                                <option>Indian/Maldives</option>
                                                                <option>Indian/Mauritius</option>
                                                                <option>Indian/Mayotte</option>
                                                                <option>Indian/Reunion</option>
                                                                <option>Pacific/Apia</option>
                                                                <option>Pacific/Auckland</option>
                                                                <option>Pacific/Bougainville</option>
                                                                <option>Pacific/Chatham</option>
                                                                <option>Pacific/Chuuk</option>
                                                                <option>Pacific/Easter</option>
                                                                <option>Pacific/Efate</option>
                                                                <option>Pacific/Enderbury</option>
                                                                <option>Pacific/Fakaofo</option>
                                                                <option>Pacific/Fiji</option>
                                                                <option>Pacific/Funafuti</option>
                                                                <option>Pacific/Galapagos</option>
                                                                <option>Pacific/Gambier</option>
                                                                <option>Pacific/Guadalcanal</option>
                                                                <option>Pacific/Guam</option>
                                                                <option>Pacific/Honolulu</option>
                                                                <option>Pacific/Kiritimati</option>
                                                                <option>Pacific/Kosrae</option>
                                                                <option>Pacific/Kwajalein</option>
                                                                <option>Pacific/Majuro</option>
                                                                <option>Pacific/Marquesas</option>
                                                                <option>Pacific/Midway</option>
                                                                <option>Pacific/Nauru</option>
                                                                <option>Pacific/Niue</option>
                                                                <option>Pacific/Norfolk</option>
                                                                <option>Pacific/Noumea</option>
                                                                <option>Pacific/Pago_Pago</option>
                                                                <option>Pacific/Palau</option>
                                                                <option>Pacific/Pitcairn</option>
                                                                <option>Pacific/Pohnpei</option>
                                                                <option>Pacific/Port_Moresby</option>
                                                                <option>Pacific/Rarotonga</option>
                                                                <option>Pacific/Saipan</option>
                                                                <option>Pacific/Tahiti</option>
                                                                <option>Pacific/Tarawa</option>
                                                                <option>Pacific/Tongatapu</option>
                                                                <option>Pacific/Wake</option>
                                                                <option>Pacific/Wallis</option>
                                                                <option >UTC</option>
                                                        </FieldSelect>
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <label>Date time format*</label>
                                                        <FieldSelect addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_datetimeformat}>
                                                            <option value={"MM/DD/YYYY HH:mm"}>MM/DD/YYYY HH:mm</option>
                                                            <option value={"MM/DD/YYYY h:mm A"}>MM/DD/YYYY h:mm A</option>
                                                            <option value={"DD/MM/YYYY HH:mm"}>DD/MM/YYYY HH:mm</option>
                                                            <option value={"DD/MM/YYYY h:mm A"}>DD/MM/YYYY h:mm A</option>
                                                        </FieldSelect>
                                                    </div>


                                                </div>

                                                <Button className="btn btn-brand" onClick={this.submit} text={"Edit details"}/>
                                            </React.Fragment>

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                        )}

                        <div className="notification-message-wrapper">
                            {resource.update && (
                                <NotificationSuccessMessage text={translate("label.profile_updated")}
                                                            onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                            )}
                            {resource.error && (
                                <NotificationErrorMessage addClass={"action-notification"} text={translate(resource.errorMessage)}
                                                          onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                            )}
                        </div>

                    </div>

                </div>

            </BasicLayout>
        );
    }
}

export default connect(state => state)(ProfileView);