import React, {Component} from "react";
import {connect} from "react-redux";
import BasicLayout from "../../components/basic-layout";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {deleteResource, getResource, resetResourceMessage} from "../../data/actions/resource";
import {getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import LoaderSmall from "../../components/loader-small";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import NotificationSuccessMessage from "../../components/notification-success-message";
import NotificationErrorMessage from "../../components/notification-error-message";
import EditAddressBookDialog from "../../components/dialog/edit-addressBook";
import Pagination from "../../components/pagination";
import FieldText from "../../components/field-text";
import NoResults from "../../components/no-results";
import Dialog from "../../components/dialog/index";
import FieldSelect from "../../components/field-select";
import {getSecondResource} from "../../data/actions/secondResource";
import ReviewsCustomersDialog from "../../components/dialog/reviews-customer";
import AddAddressBookReview from "../../components/dialog/add-addressBookReview";
import {downloadExcelReport} from "../../data/actions/data";

class AddressBookView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                query: new Field("query", "", []),
                archived: new Field("archived", 'no', [])
            },
            sort: "ASC",
            sortBy: "customer_name",
            offset: 0,
            limit: 15,
            paginationPage: 1,
            deleteModal: false,
            editAddressBookModal: false,
            showMap: null,
            viewReviewsModal: false,
            addReviewModal: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevState.offset !== this.state.offset)
            || (prevState.sort !== this.state.sort)
            || (prevState.sortBy !== this.state.sortBy)
        ) {
            this.fetchData();
        }
    };

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                offset: this.state.offset,
                limit: this.state.limit,
                archived: this.state.fields.archived.value
            },
            resource: Resources.AddressBook
        }))
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, () => {
            this.fetchData();
        });
    };

    updateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        });
    };

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy)?(this.state.sort==="ASC"?"DESC":"ASC"):"ASC"
        })
    };

    toggleDeleteModal = (submit) => {
        this.setState({
            deleteModal: submit
        })
    };

    toggleEditAddressBookModal = (item) => {
        this.setState({
            addressBookItem: item,
            editAddressBookModal: !this.state.editAddressBookModal
        })
    };

    toggleAddReviewModal = () => {
        this.setState({
            addReviewModal: !this.state.addReviewModal
        })
    };

    toggleReviewsModal = (item) => {
        this.setState({
            viewReviewsModal: !this.state.viewReviewsModal,
            reviewSubject: item
        }, () => {
            !!this.state.viewReviewsModal && this.props.dispatch(getSecondResource({
                user: LocalStorage.get('user'),
                query: {
                    id: item.customer_id
                },
                resource: Resources.AddressBookReview,
                piggyResource: Resources.AddressBookReview
            }));
        })
    };

    downloadExcel = () => {
        this.props.dispatch(downloadExcelReport({
            user: LocalStorage.get("user"),
            query: {
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                archived: this.state.fields.archived.value,
                name: "activtrack_address_book.xlsx"
            },
            resource: Resources.AddressBookExport
        }));
    };

    render() {
        const {resource, translate, download} = this.props;

        const data = getProp(resource, "data.list", []).map((item,i) => (
            <tr key={i}>
                <td>{item.customer_name}</td>
                <td>{item.customer_address}</td>
                <td>{item.customer_city}</td>
                <td>{item.customer_state}</td>
                <td>{item.customer_zip}</td>
                <td>{item.customer_phone}</td>
                <td>{item.customer_contact}</td>
                <td>{item.customer_desc}</td>
                <td className="table-actions text-center">

                    <span className="icon-btn" title={"Edit"}
                          onClick={() => this.toggleEditAddressBookModal(item)}
                    >
                        <i className="ti-pencil-alt tx-24 mg-r-10"></i>
                    </span>

                    {(!!item.customer_address_lat && !!item.customer_address_lon) && (
                        <span className="icon-btn" title={"View"}
                              onClick={() => {
                                  this.setState({
                                      showMap: item
                                  }, () => {
                                      const firstPos = {
                                          lat: parseFloat(this.state.showMap.customer_address_lat),
                                          lng: parseFloat(this.state.showMap.customer_address_lon)
                                      };
                                      this.map = new window.google.maps.Map(document.getElementById('map'), {
                                          center: firstPos,
                                          zoom: 12,
                                          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                                          gestureHandling: 'greedy',
                                          styles: [
                                              {
                                                  "featureType": "administrative.country",
                                                  "elementType": "geometry",
                                                  "stylers": [
                                                      {
                                                          "visibility": "simplified"
                                                      },
                                                      {
                                                          "hue": "#ff0000"
                                                      }
                                                  ]
                                              },
                                              {
                                                  "featureType": "landscape",
                                                  "stylers": [
                                                      {
                                                          "hue": "#FFBB00"
                                                      },
                                                      {
                                                          "saturation": 43.400000000000006
                                                      },
                                                      {
                                                          "lightness": 37.599999999999994
                                                      },
                                                      {
                                                          "gamma": 1
                                                      }
                                                  ]
                                              },
                                              {
                                                  "featureType": "road.highway",
                                                  "stylers": [
                                                      {
                                                          "hue": "#FFC200"
                                                      },
                                                      {
                                                          "saturation": -61.8
                                                      },
                                                      {
                                                          "lightness": 45.599999999999994
                                                      },
                                                      {
                                                          "gamma": 1
                                                      }
                                                  ]
                                              }
                                          ]
                                      });
                                      new window.google.maps.Marker({
                                          map: this.map,
                                          position: firstPos
                                      });
                                  });
                              }}
                        >
                            <i title="View Location" className="ti-location-pin tx-24 mg-r-10"></i>
                        </span>
                    )}

                    <span className="icon-btn" title={"Reviews"}
                          onClick={() => this.toggleReviewsModal(item)}
                    >
                        <i className="ti-comment tx-24 mg-r-15"></i>
                    </span>

                    {!item.archived_date && (
                    <span className="icon-btn" title={"Archive"}
                          onClick={() => this.toggleDeleteModal(() => {
                              this.props.dispatch(deleteResource({
                                  user: LocalStorage.get('user'),
                                  query: {
                                      id: item.customer_id
                                  },
                                  resource: Resources.AddressBook,
                                  piggyResource: Resources.AddressBook
                              }));
                          })}
                    >
                        <i className="ti-archive tx-24 mg-r-15"></i>
                    </span>
                    )}

                </td>
            </tr>
        ));

        return (
            <BasicLayout {...this.props} query={{
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                offset: this.state.offset,
                limit: this.state.limit,
                archived: this.state.fields.archived.value
            }}>

                <div className="page-inner mg-t-20">

                    <div className="wrapper">

                        <div className="pageheader pd-t-15 pd-b-25">
                            <div className="d-flex justify-content-between">
                                <div className="clearfix">
                                    <h1 className="pd-0 mg-0 tx-26 tx-dark">Address Book</h1>
                                    <div className="breadcrumb pd-0 mg-0 bg-white">
                                        <span className="breadcrumb-item active">company shared contacts - shippers/consignee</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-group col-sm-3 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Search
                                    </span>
                                </div>
                                <FieldText addClass={"form-control"} onChange={this.handleInputChange} {...this.state.fields.query} />
                            </div>
                            <div className="input-group col-sm-2 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Show Archived
                                    </span>
                                </div>
                                <FieldSelect addClass={"form-control"} onChange={this.handleInputChange}
                                             {...this.state.fields.archived}>
                                    <option value={"no"}>No</option>
                                    <option value={"yes"}>Yes</option>
                                    <option value={"all"}>All</option>
                                </FieldSelect>
                            </div>

                            <div className="input-group col-sm-2 mb-3">
                                {!download.isLoading && (
                                    <div className={"general-btn-icon"}
                                         onClick={this.downloadExcel}
                                    >
                                        <img alt="" className={"not-active"} title={translate("btn.export_as_excel")} src="/images/buttons/excel.png"/>
                                        <img alt="" className={"active"} title={translate("btn.export_as_excel")} src="/images/buttons/excel-active.png"/>
                                    </div>
                                )}

                                {download.isLoading && (
                                    <LoaderSmall/>
                                )}
                            </div>
                        </div>

                        <div className="row row-xs clearfix">
                            <div className="col-md-12 col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-dark table-striped table-hover load-table">
                                        <thead>
                                        <tr>
                                            <th title={this.state.sortBy === "customer_name"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("customer_name")}>
                                                Name
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "customer_name"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "customer_address"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("customer_address")}>
                                                Address
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "customer_address"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "customer_city"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("customer_city")}>
                                                City
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "customer_city"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "customer_state"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("customer_state")}>
                                                State
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "customer_state"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "customer_zip"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("customer_zip")}>
                                                Zip
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "customer_zip"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "customer_phone"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("customer_phone")}>
                                                Phone
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "customer_phone"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "customer_contact"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("customer_contact")}>
                                                Contact
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "customer_contact"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "customer_desc"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("customer_desc")}>
                                                Description
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "customer_desc"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {!resource.isLoading && data}
                                        </tbody>
                                    </table>
                                </div>
                                {(data.length > 0) && (
                                    <Pagination
                                        updateOffset={this.updateOffset}
                                        paginationButtonLimit={5}
                                        limit={this.state.limit}
                                        offset={this.state.offset}
                                        count={ getProp(resource, "data.count", 0)}
                                        paginationPage={this.state.paginationPage}
                                    />
                                )}
                                {resource.isLoading  && (
                                    <LoaderSmall addClass={"text-center"}/>
                                )}
                                {data.length < 1 && !resource.isLoading && (
                                    <NoResults text={this.props.translate("text.no_result")}/>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="notification-message-wrapper">
                        {resource.update && (
                            <NotificationSuccessMessage text={"Address Book entry updated."}
                                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        )}
                        {resource.deleteResource && (
                            <NotificationSuccessMessage icon={"ti-archive"} text={"Address Book entry archived."}
                                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        )}
                        {resource.error && (
                            <NotificationErrorMessage addClass={"action-notification"} text={translate(resource.errorMessage)}
                                                      onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        )}
                    </div>
                </div>

                <ConfirmDialog
                    second_title={"Archive entry"}
                    text={this.props.translate("message.are_you_sure_archive_contact")}
                    submit={this.state.deleteModal}
                    cancel={this.toggleDeleteModal}
                    visible={this.state.deleteModal} {...this.props}
                />

                {this.state.editAddressBookModal && (
                    <EditAddressBookDialog
                        cancel={this.toggleEditAddressBookModal}
                        value={this.state.addressBookItem}
                        visible={this.state.editAddressBookModal}
                        {...this.props}
                    />
                )}

                {this.state.viewReviewsModal && (
                    <ReviewsCustomersDialog
                        cancel={this.toggleReviewsModal}
                        list={getProp(this.props, "secondResource.data.list", [])}
                        visible={this.state.viewReviewsModal}
                        reviewSubject={this.state.reviewSubject}
                        {...this.props}
                        toggleAddReviewModal={this.toggleAddReviewModal}
                    />
                )}

                <AddAddressBookReview
                    cancel={this.toggleAddReviewModal}
                    visible={this.state.addReviewModal}
                    {...this.props}
                    customer_id={getProp(this.state, "reviewSubject.customer_id", 0)}
                />

                <Dialog active={this.state.showMap} addClass={"large-dialog"}>
                    <a className="close-dialog" title="Close"
                       onClick={() => {
                           this.setState({
                               showMap: null
                           });
                       }}
                    ><i className="ti-close">
                    </i></a>
                    <div id="map" className="addressMap">
                    </div>
                </Dialog>

            </BasicLayout>
        );
    }
}

export default connect(state => state)(AddressBookView);