import {
    watchLoginCall,
    watchResetPasswordCall,
    watchResetPasswordConfirmCall,
    watchSendSupportCall
} from "./userSaga";
import {all} from "redux-saga/effects";
import {
    watchCreateResource,
    watchDeleteResource,
    watchGetResource,
    watchGetResourceDetails,
    watchUpdateResource,
    watchGetSecondResource, watchUpdateSecondResource
} from "./resourceSaga";
import {watchCreateLoad, watchDeleteLoad, watchGetLoads} from "./loadsSaga";
import {watchDownloadExcelReport} from "./download";

/*
 * Single entry point to start all Sagas at once
 =========================================================================================*/
export default function *rootSaga() {
    yield all([
        watchLoginCall(),
        watchSendSupportCall(),
        watchResetPasswordCall(),
        watchResetPasswordConfirmCall(),
        watchGetResource(),
        watchGetResourceDetails(),
        watchCreateResource(),
        watchUpdateResource(),
        watchUpdateSecondResource(),
        watchDeleteResource(),
        watchGetSecondResource(),
        watchGetLoads(),
        watchCreateLoad(),
        watchDeleteLoad(),
        watchDownloadExcelReport()
    ]);
}