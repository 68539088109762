export function downloadExcelReport(data) {
    return {
        type: 'DOWNLOAD_EXCEL_REPORT',
        data: data
    }
}
export function doneDownloadExcelReport(data) {
    return {
        type: 'DONE_DOWNLOAD_EXCEL_REPORT',
        data: data
    }
}
export function errorDownloadExcelReport(data) {
    return {
        type: 'ERROR_DOWNLOAD_EXCEL_REPORT',
        data: data
    }
}