import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {
    doneCreateResource, doneDeleteResource, doneGetResource, doneGetResourceDetails, doneUpdateResource,
    errorCreateResource,
    errorDeleteResource,
    errorGetResource, errorGetResourceDetails, errorUpdateResource
} from "../actions/resource";
import {
    doneGetSecondResource, doneUpdateSecondResource, errorGetSecondResource,
    errorUpdateSecondResource
} from "../actions/secondResource";

export function *getResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetResource(result.data));
    } else {
        yield put(errorGetResource(result.data));
    }
}

export function* watchGetResource() {
    yield takeLatest('GET_RESOURCE', getResourceCall);
}

export function *getSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetSecondResource(result.data));
    } else {
        yield put(errorGetSecondResource(result.data));
    }
}

export function* watchGetSecondResource() {
    yield takeLatest('GET_SECOND_RESOURCE', getSecondResourceCall);
}

export function *getResourceDetailsCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetResourceDetails(result.data));
    } else {
        yield put(errorGetResourceDetails(result.data));
    }
}

export function* watchGetResourceDetails() {
    yield takeLatest('GET_RESOURCE_DETAILS', getResourceDetailsCall);
}

export function* createResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        } else if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        } else {
            yield put(doneCreateResource(result.data));
        }
    } else {
        yield put(errorCreateResource(result.data));
    }
}

export function* watchCreateResource() {
    yield takeLatest('CREATE_RESOURCE', createResourceCall);
}

export function *updateResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUpdateResource(result.data));
            }
        } else {
            yield put(doneUpdateResource(result.data));
        }
    } else {
        yield put(errorUpdateResource(result.data));
    }
}

export function* watchUpdateResource() {
    yield takeLatest('UPDATE_RESOURCE', updateResourceCall);
}

export function *updateSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneUpdateSecondResource(result.data));
            }
        } else {
            yield put(doneUpdateSecondResource(result.data));
        }
    } else {
        yield put(errorUpdateSecondResource(result.data));
    }
}

export function* watchUpdateSecondResource() {
    yield takeLatest('UPDATE_SECOND_RESOURCE', updateSecondResourceCall);
}

export function* deleteResourceContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneDeleteResource(result.data));
            }
        } else {
            yield put(doneDeleteResource(result.data));
        }
    } else {
        yield put(errorDeleteResource(result.data));
    }
}

export function* watchDeleteResource() {
    yield takeLatest('DELETE_RESOURCE', deleteResourceContactCall);
}