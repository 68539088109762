import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/public-layout";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldText from "../../components/field-text";
import FieldPassword from "../../components/field-password";
import Button from "../../components/button";
import {login, resetUserMessage} from "../../data/actions/user";
import {Link} from "react-router-dom";
import ErrorMessage from "../../components/error-message";
import LoaderSmall from "../../components/loader-small";

class LoginView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                username: new Field('username', '', ['empty']),
                password: new Field('password', '', ['empty'])
            }
        };
    }

    componentDidMount() {
        this.props.dispatch(resetUserMessage());
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.user.data === null) && (!!this.props.user.data)) {
            this.props.history.push("/home");
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (!this.state.fields.password.errorMessage && !this.state.fields.username.errorMessage) {
                this.props.dispatch(login({
                    username: this.state.fields.username.value,
                    password: this.state.fields.password.value
                }));
            }
        });
    };

    render() {
        const {translate} = this.props;

        return (
            <PublicLayout {...this.props}>
                <div className="col-lg-6 bg-light">
                    <div className="ht-100v d-flex align-items-center justify-content-center">
                        <form onKeyPress={this.submitForm}>

                            {!this.props.user.isLoading && (
                                <React.Fragment>
                                    <h3 className="tx-dark mg-b-5 tx-left">Sign In</h3>
                                    <p className="tx-gray-500 tx-15 mg-b-40 text-left">Welcome back! Please sign in to
                                        continue.</p>

                                    <div className="form-group tx-left">
                                        <label className="tx-gray-500 mg-b-5">Email address</label>
                                        <FieldText onChange={this.handleInputChange} {...this.state.fields.username}
                                                   placeholder={"email@domain.com"} type={"email"} addClass={"form-control"}/>
                                    </div>
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between mg-b-5">
                                            <label className="tx-gray-500 mg-b-0">Password</label>
                                            <Link to="/reset-password" className="tx-13 mg-b-0 tx-semibold">Forgot password?</Link>
                                        </div>
                                        <FieldPassword onChange={this.handleInputChange} {...this.state.fields.password}
                                                       placeholder={"Enter your password"} addClass={"form-control"}/>
                                    </div>

                                    <Button className="btn btn-brand btn-block" onClick={this.submit} text={"Sign In"}/>

                                    {this.props.user.error && (
                                        <ErrorMessage addClass={"text-left mg-t-10"} text={translate(this.props.user.errorMessage)}
                                                      onDismiss={() => this.props.dispatch(resetUserMessage())}/>
                                    )}
                                </React.Fragment>
                            )}

                            {this.props.user.isLoading && (
                                <LoaderSmall/>
                            )}
                        </form>
                    </div>
                </div>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(LoginView);