import React, {Component} from "react";
import {connect} from "react-redux";
import BasicLayout from "../../components/basic-layout";
import {
    checkFeature, ETA_FEATURE, getProp, getRouteStatus, LOCATION_ADDRESS_FEATURE, toFrontDateTimeNoSec,
    toFrontDateTimeNoSecFromUTC, toFrontDateTimeNoSecFromUTCAndZone
} from "../../util/util";
import {deleteResource, doneGetResource, getResource, resetResourceMessage} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {Field, FieldsManager} from "../../data/services/fields";
import LoaderSmall from "../../components/loader-small";
import {Link} from "react-router-dom";
import FieldText from "../../components/field-text";
import Pagination from "../../components/pagination";
import NoResults from "../../components/no-results";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import NotificationSuccessMessage from "../../components/notification-success-message";
import NotificationErrorMessage from "../../components/notification-error-message";
import FieldSelect from "../../components/field-select";
import {showAddLoadModal} from "../../data/actions/ui";
import Env from "../../util/env";
import {processResponse} from "../../data/services/api-util";
import axios from "axios";
import {deleteLoad, doneGetLoads, getLoads, resetLoadMessage} from "../../data/actions/loads";
import FieldCheckbox from "../../components/field-checkbox/index";
import { publishFlashMessage } from 'simple-redux-flash-message';

class HomeView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sort: "ASC",
            sortBy: "",
            offset: 0,
            limit: 15,
            paginationPage: 1,
            fields: {
                query: new Field("query", "", []),
                archived: new Field("archived", 'no', []),
                route_in_danger: new Field("route_in_danger", '', []),
                watched_routes: new Field("watched_routes", '', [])
            },
            deleteModal: false,
            expandedRows: false
        };

        this.sound = new Audio('/audio/text_notification.mp3');
    }

    componentDidMount() {
        this.fetchData();
        this.handle = setInterval(this.loadData, 15000);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevState.offset !== this.state.offset)
            || (prevState.sort !== this.state.sort)
            || (prevState.sortBy !== this.state.sortBy)
        ) {
            this.fetchData();
        }
    };

    loadData = () => {
        if (LocalStorage.has('user') && LocalStorage.get('user').Contact) {
            axios.get(
                Env.getApiUrl('api/cloud/routes', {
                    sort: this.state.sort,
                    sortBy: this.state.sortBy,
                    query: this.state.fields.query.value,
                    offset: this.state.offset,
                    limit: this.state.limit,
                    archived: this.state.fields.archived.value,
                    route_in_danger: this.state.fields.route_in_danger.value?1:0,
                    watched_routes: this.state.fields.watched_routes.value?1:0
                }),
                {
                    headers: {
                        'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                    }
                }
            )
                .then((response) => {
                    const result = processResponse(response);
                    if (result && result.status === 0) {
                        const routesMap = getProp(this.props, "loads.data.list", []).reduce((memo, item) => {
                            memo[item.route_id] = {
                                route_eta_status: item.route_eta_status,
                                route_tracking_status: item.route_tracking_status
                            };
                            return memo;
                        }, {});

                        result.data.list.forEach((item) => {
                            if ((item.route_tracking_status == "1") && (!routesMap[item.route_id] || (routesMap[item.route_id].route_tracking_status != "1"))) {
                                publishFlashMessage(this.props.dispatch, "Route " + item.load_id + " not synchronized.", false, () => {
                                    this.props.history.push("/route/" + item.route_id);
                                });
                                this.sound.play();
                            }
                            if ((item.route_eta_status == "1") && (!routesMap[item.route_id] || (routesMap[item.route_id].route_eta_status != "1"))) {
                                publishFlashMessage(this.props.dispatch, "Driver on route " + item.load_id + " may possibly be late.", false, () => {
                                    this.props.history.push("/route/" + item.route_id);
                                });
                                this.sound.play();
                            }
                        });
                        this.props.dispatch(doneGetLoads(result.data));
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    componentWillUnmount() {
        if (this.handle) {
            clearInterval(this.handle);
            this.handle = null;
        }
    }

    fetchData = () => {
        this.props.dispatch(getLoads({
            user: LocalStorage.get("user"),
            query: {
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                offset: this.state.offset,
                limit: this.state.limit,
                archived: this.state.fields.archived.value,
                route_in_danger: this.state.fields.route_in_danger.value?1:0,
                watched_routes: this.state.fields.watched_routes.value?1:0
            }
        }))
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, () => {
            this.fetchData();
        });
    };

    updateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        });
    };

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy)?(this.state.sort==="ASC"?"DESC":"ASC"):"ASC"
        })
    };

    toggleDeleteModal = (submit) => {
        this.setState({
            deleteModal: submit
        })
    };

    expandTableRows = () => {
        this.setState({
            expandedRows: !this.state.expandedRows
        })
    };

    render() {
        const {translate, resource, loads} = this.props;

        const loadData = getProp(loads, "data.list", []).map((item,i) => (
            <tr key={i}>
                <td>
                    <Link title={"View load"} className={"link-like-element"} to={"/route/" + item.route_id}>
                        {item.load_id}

                        {checkFeature(LocalStorage.get('user'), ETA_FEATURE) && (item.route_eta_status > 0) && (
                            <div className="danger-route-icon">
                                <i title="Driver will be possibly late!"
                                   className="ti-time tx-24 mg-r-10"></i>
                            </div>
                        )}
                        {(item.route_tracking_status > 0) && (
                            <div className="danger-route-icon">
                                <i title="No new locations detected for more than 15 minutes"
                                   className="ti-alert tx-24 mg-r-10"></i>
                            </div>
                        )}
                    </Link>
                </td>
                {this.state.expandedRows && (
                    <React.Fragment>
                        <td>{item.pickup_customer}</td>
                        <td>{toFrontDateTimeNoSecFromUTCAndZone(item.pickup_date, item.pickup_timezone)} {item.pickup_end_date? " - " + toFrontDateTimeNoSecFromUTCAndZone(item.pickup_end_date, item.pickup_timezone):""}</td>
                        <td>{item.destination_customer}</td>
                        <td>{toFrontDateTimeNoSecFromUTCAndZone(item.destination_date, item.destination_timezone)} {item.destination_end_date? " - " + toFrontDateTimeNoSecFromUTCAndZone(item.destination_end_date, item.destination_timezone):""}</td>
                    </React.Fragment>
                )}
                <td>{getRouteStatus(item.route_status)}</td>
                {this.state.expandedRows && (
                    <td>{item.driver_fname} {item.driver_lname}</td>
                )}
                {checkFeature(LocalStorage.get('user'), LOCATION_ADDRESS_FEATURE) && (
                    <td>{item.last_detected_address} {item.last_detected_address_eta_time?"(on " + toFrontDateTimeNoSecFromUTC(item.last_detected_address_eta_time) +")":""}</td>
                )}

                {checkFeature(LocalStorage.get('user'), ETA_FEATURE) && (
                    <td>~{toFrontDateTimeNoSecFromUTC(item.last_detected_eta)}</td>
                )}

                {this.state.expandedRows && (
                    <td>{item.route_desc}</td>
                )}
                <td className="table-actions text-center">
                    <Link className="icon-btn" to={"/route/" + item.route_id}>
                        <i title="View" className="ti-zoom-in tx-24 mg-r-10"></i>
                    </Link>

                    <span className="icon-btn" title={"Copy"}
                          onClick={() => {
                              if (!this.props.ui.viewAddLoadDialog) {
                                  this.props.dispatch(showAddLoadModal(item));
                              }
                          }}
                    >
                        <i title="Copy"
                       className="ti-layers-alt tx-24 mg-r-10"></i>
                    </span>

                    {/*
                        <span className="icon-btn"
                        >
                            <i title="Add to watch list" className="ti-lock tx-24 mg-r-10"></i>

                                <i title="Remove from watch list" className="ti-unlock tx-24 mg-r-10"></i>

                        </span>
                     */}

                    {!item.archived_date && (
                    <span className="icon-btn" title={"Archive"}
                          onClick={() => this.toggleDeleteModal(() => {
                              this.props.dispatch(deleteLoad({
                                  user: LocalStorage.get('user'),
                                  query: {
                                      id: item.route_id,
                                      sort: this.state.sort,
                                      sortBy: this.state.sortBy,
                                      query: this.state.fields.query.value,
                                      archived: this.state.fields.archived.value,
                                      offset: this.state.offset,
                                      limit: this.state.limit
                                  },
                                  resource: Resources.Loads,
                                  piggyResource: Resources.Loads
                              }));
                          })}
                    >
                        <i className="ti-archive tx-24"></i>
                    </span>
                    )}
                </td>
            </tr>
        ));

        return (
            <BasicLayout {...this.props} query={{
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                archived: this.state.fields.archived.value,
                offset: this.state.offset,
                limit: this.state.limit
            }}>

                <div className="page-inner mg-t-20">

                    <div className="wrapper">

                        <div className="pageheader pd-t-15 pd-b-25">
                            <div className="d-flex justify-content-between">
                                <div className="clearfix">
                                <h1 className="pd-0 mg-0 tx-26 tx-dark">Loads</h1>
                                    <div className="breadcrumb pd-0 mg-0 bg-white">
                                        <span className="breadcrumb-item active">company shared loads</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-group col-sm-3 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Search
                                    </span>
                                </div>
                                <FieldText addClass={"form-control"} onChange={this.handleInputChange} {...this.state.fields.query} />
                            </div>
                            <div className="input-group col-sm-3 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Show Archived
                                    </span>
                                </div>
                                <FieldSelect addClass={"form-control"} onChange={this.handleInputChange}
                                             {...this.state.fields.archived}>
                                    <option value={"no"}>No</option>
                                    <option value={"yes"}>Yes</option>
                                    <option value={"all"}>All</option>
                                </FieldSelect>
                            </div>

                            <div className="input-group col-sm-6 mb-3">
                                <React.Fragment>

                                    <span className="input-group-text">
                                        Show Problematic
                                    </span>

                                    <FieldCheckbox addClass={"custom-checkbox"}
                                                   onChange={this.handleInputChange}
                                                   {...this.state.fields.route_in_danger}
                                    />

                                </React.Fragment>

                                {!LocalStorage.get('user').Contact.resource_restrict && (
                                <React.Fragment>

                                    <span className="input-group-text mg-l-15">
                                        Show Watched
                                    </span>

                                    <FieldCheckbox addClass={"custom-checkbox"}
                                                   onChange={this.handleInputChange}
                                                   {...this.state.fields.watched_routes}
                                    />

                                </React.Fragment>
                                )}
                            </div>

                        </div>

                        <div className="row row-xs clearfix">
                            <div className="col-md-12 col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-dark table-striped table-hover load-table">
                                        <thead>
                                        <tr>
                                            <th title={this.state.sortBy === "load_id"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("load_id")}>
                                                Load number
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "load_id"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            {this.state.expandedRows && (
                                                <React.Fragment>
                                                    <th title={this.state.sortBy === "load_id"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("load_id")}>
                                                        Shipper
                                                        <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "load_id"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                                    </th>
                                                    <th title={this.state.sortBy === "pickup_date"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("pickup_date")}>
                                                        Pickup date and time
                                                        <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "pickup_date"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                                    </th>

                                                    <th title={this.state.sortBy === "load_id"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("load_id")}>
                                                        Consignee
                                                        <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "load_id"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                                    </th>

                                                    <th title={this.state.sortBy === "destination_date"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("destination_date")}>
                                                        Delivery date and time
                                                        <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "destination_date"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                                    </th>
                                                </React.Fragment>
                                            )}
                                                    <th title={this.state.sortBy === "route_status"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("route_status")}>
                                                        Load status
                                                        <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "route_status"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                                    </th>
                                            {this.state.expandedRows && (
                                                <th title={this.state.sortBy === "driver_fname"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("driver_fname")}>
                                                    Driver's name
                                                    <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "driver_fname"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                                </th>
                                            )}
                                            {checkFeature(LocalStorage.get('user'), LOCATION_ADDRESS_FEATURE) && (
                                                <th>
                                                    Last detected address
                                                </th>
                                            )}
                                            {checkFeature(LocalStorage.get('user'), ETA_FEATURE) && (
                                                <th>
                                                    ETA
                                                </th>
                                            )}
                                            {this.state.expandedRows && (
                                                <th>
                                                    Notes
                                                </th>
                                            )}
                                            <th className="text-center">
                                                Actions
                                                <span className="mg-l-10 control-btn link-like-element-table" title={this.state.expandedRows?"Collapse Table Rows":"Expand Table Rows"} onClick={this.expandTableRows}>
                                                    <i className={"ti-control-" + (this.state.expandedRows?"backward":"forward")}></i>
                                                </span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {!loads.isLoading && loadData}
                                        </tbody>
                                    </table>
                                </div>
                                {(loadData.length > 0) && (
                                    <Pagination
                                        updateOffset={this.updateOffset}
                                        paginationButtonLimit={5}
                                        limit={this.state.limit}
                                        offset={this.state.offset}
                                        count={ getProp(loads, "data.count", 0)}
                                        paginationPage={this.state.paginationPage}
                                    />
                                )}
                                {loads.isLoading  && (
                                    <LoaderSmall addClass={"text-center"}/>
                                )}
                                {loadData.length < 1 && !loads.isLoading && (
                                    <NoResults text={this.props.translate("text.no_result")}/>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="notification-message-wrapper">
                        {(resource.deleteResource || loads.deleteResource) && (
                            <NotificationSuccessMessage text={translate("label.load_archived")}
                                                        onDismiss={() => {
                                                            this.props.dispatch(resetResourceMessage());
                                                            this.props.dispatch(resetLoadMessage())
                                                        }}/>
                        )}
                        {resource.error && (
                            <NotificationErrorMessage addClass={"action-notification"} text={translate(resource.errorMessage)}
                                                      onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        )}
                    </div>

                </div>

                <ConfirmDialog
                    second_title={"Delete entry"}
                    text={this.props.translate("message.are_you_sure_archive_load")}
                    submit={this.state.deleteModal}
                    cancel={this.toggleDeleteModal}
                    visible={this.state.deleteModal} {...this.props}/>

            </BasicLayout>
        );
    }
}

export default connect(state => state)(HomeView);