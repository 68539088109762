import React, {Component} from "react";
import {connect} from "react-redux";
import BasicLayout from "../../components/basic-layout";
import Resources from "../../data/services/resources";
import {Field, FieldsManager} from "../../data/services/fields";
import {deleteResource, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {getProp, toFrontDateTime} from "../../util/util";
import LoaderSmall from "../../components/loader-small";
import Pagination from "../../components/pagination";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import FieldText from "../../components/field-text";
import NoResults from "../../components/no-results";
import AddCompanyDriverDialog from "../../components/dialog/add-companyDriver";
import EditCompanyDriverDialog from "../../components/dialog/edit-companyDriver";
import FieldSelect from "../../components/field-select";
import ReviewsDriverDialog from "../../components/dialog/reviews-driver";
import {getSecondResource} from "../../data/actions/secondResource";
import AddDriverReviewDialog from "../../components/dialog/add-driverReview";
import {downloadExcelReport} from "../../data/actions/data";

class CompanyDriversView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sort: "ASC",
            sortBy: "driver_fname",
            offset: 0,
            limit: 15,
            paginationPage: 1,
            fields: {
                query: new Field("query", "", []),
                archived: new Field("archived", 'no', [])
            },
            deleteModal: false,
            editCompanyDriverModal: false,
            addCompanyDriverModal: false,
            viewReviewsModal: false,
            addReviewModal: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevState.offset !== this.state.offset)
            || (prevState.sort !== this.state.sort)
            || (prevState.sortBy !== this.state.sortBy)
        ) {
            this.fetchData();
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, () => {
            this.fetchData();
        });
    };

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                offset: this.state.offset,
                limit: this.state.limit,
                archived: this.state.fields.archived.value
            },
            resource: Resources.CompanyDrivers
        }))
    };

    updateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        });
    };

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy)?(this.state.sort==="ASC"?"DESC":"ASC"):"ASC"
        })
    };

    toggleDeleteModal = (submit) => {
        this.setState({
            deleteModal: submit
        })
    };

    toggleEditCompanyDriverModal = (item) => {
        this.setState({
            companyDriverItem: item,
            editCompanyDriverModal: !this.state.editCompanyDriverModal
        })
    };

    toggleAddCompanyDriverModal = () => {
        this.setState({
            addCompanyDriverModal: !this.state.addCompanyDriverModal
        })
    };

    toggleAddReviewModal = () => {
        this.setState({
            addReviewModal: !this.state.addReviewModal
        })
    };

    toggleReviewsModal = (item) => {
        this.setState({
            viewReviewsModal: !this.state.viewReviewsModal,
            reviewSubject: item
        }, () => {
            !!this.state.viewReviewsModal && this.props.dispatch(getSecondResource({
                user: LocalStorage.get('user'),
                query: {
                    id: item.driver_id
                },
                resource: Resources.CompanyDriversReview,
                piggyResource: Resources.CompanyDriversReview
            }));
        })
    };

    printStars = (rating) => {
      return [...Array(Math.floor(rating/20.0)).keys()].map(() => (<i className='fa fa-star'></i>))
          .concat([...Array((rating%20.0 > 0)?1:0).keys()].map(() => (<i className='fa fa-star-half-empty'></i>)))
          .concat([...Array(Math.floor((100 - rating)/20.0)).keys()].map(() => (<i className='fa fa-star-o'></i>)));
    };

    downloadExcel = () => {
        this.props.dispatch(downloadExcelReport({
            user: LocalStorage.get("user"),
            query: {
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                archived: this.state.fields.archived.value,
                name: "activtrack_drivers.xlsx"
            },
            resource: Resources.AddressBookExport
        }));
    };

    render() {
        const {translate, resource, download} = this.props;

        const data = getProp(resource, "data.list", []).map((item,i) => (
            <tr key={i}>
                <td>{item.driver_fname} {item.driver_lname}</td>
                <td>{item.driver_phone}</td>
                <td title={item.driver_rating?(item.driver_rating + " / 100"):""}>
                    {(item.driver_rating && item.driver_rating > 0)
                        ?(this.printStars(item.driver_rating))
                        :"no rating yet"}
                </td>
                <td>{item.driver_notes}</td>
                <td className="table-actions text-center">

                    <span className="icon-btn" title={"Edit"}
                          onClick={() => this.toggleEditCompanyDriverModal(item)}
                    >
                        <i className="ti-pencil-alt tx-24 mg-r-15"></i>
                    </span>

                    <span className="icon-btn" title={"Reviews"}
                          onClick={() => this.toggleReviewsModal(item)}
                    >
                        <i className="ti-comment tx-24 mg-r-15"></i>
                    </span>

                    {!item.archived_date && (
                    <span className="icon-btn" title={"Archive"}
                          onClick={() => this.toggleDeleteModal(() => {
                              this.props.dispatch(deleteResource({
                                  user: LocalStorage.get('user'),
                                  query: {
                                      id: item.driver_id,
                                      sort: this.state.sort,
                                      sortBy: this.state.sortBy,
                                      query: this.state.fields.query.value,
                                      archived: this.state.fields.archived.value,
                                      offset: this.state.offset,
                                      limit: this.state.limit
                                  },
                                  resource: Resources.CompanyDrivers,
                                  piggyResource: Resources.CompanyDrivers
                              }));
                          })}
                    >
                        <i className="ti-archive tx-24 mg-r-15"></i>
                    </span>
                    )}



                </td>
            </tr>
        ));

        return (
            <BasicLayout {...this.props} query={{
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                archived: this.state.fields.archived.value,
                offset: this.state.offset,
                limit: this.state.limit
            }}>

                <div className="page-inner mg-t-20">

                    <div className="wrapper">

                        <div className="pageheader pd-t-15 pd-b-25">
                            <div className="d-flex justify-content-between">
                                <div className="clearfix">
                                    <h1 className="pd-0 mg-0 tx-26 tx-dark">Drivers</h1>
                                    <div className="breadcrumb pd-0 mg-0 bg-white">
                                        <span className="breadcrumb-item active">Company drivers</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-group col-sm-3 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Search
                                    </span>
                                </div>
                                <FieldText addClass={"form-control"} onChange={this.handleInputChange} {...this.state.fields.query} />
                            </div>

                            <div className="input-group col-sm-2 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Show Archived
                                    </span>
                                </div>
                                <FieldSelect addClass={"form-control"} onChange={this.handleInputChange}
                                             {...this.state.fields.archived}>
                                    <option value={"no"}>No</option>
                                    <option value={"yes"}>Yes</option>
                                    <option value={"all"}>All</option>
                                </FieldSelect>
                            </div>

                            <div className="input-group col-sm-3 mb-3">
                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.toggleAddCompanyDriverModal()}>
                                    Add New Driver
                                </button>

                                <React.Fragment>
                                    {!download.isLoading && (
                                        <div className={"general-btn-icon"}
                                             onClick={this.downloadExcel}
                                        >
                                            <img alt="" className={"not-active"} title={translate("btn.export_as_excel")} src="/images/buttons/excel.png"/>
                                            <img alt="" className={"active"} title={translate("btn.export_as_excel")} src="/images/buttons/excel-active.png"/>
                                        </div>
                                    )}

                                    {download.isLoading && (
                                        <LoaderSmall/>
                                    )}
                                </React.Fragment>
                            </div>

                            <div className="input-group col-sm-2 mb-3">

                            </div>
                        </div>

                        <div className="row clearfix">
                            <div className="col-md-12 col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-dark table-striped table-hover load-table">
                                        <thead>
                                        <tr>
                                            <th title={this.state.sortBy === "driver_fname"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("driver_fname")}>
                                                Name
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "driver_fname"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "driver_phone"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("driver_phone")}>
                                                Phone
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "driver_phone"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "driver_rating"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("driver_rating")}>
                                                Rating
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "driver_rating"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "driver_notes"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("driver_notes")}>
                                                Notes
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "driver_notes"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!resource.isLoading && data}
                                        </tbody>
                                    </table>
                                </div>
                                {data.length > 0 && !resource.isLoading && (
                                    <Pagination
                                        updateOffset={this.updateOffset}
                                        paginationButtonLimit={5}
                                        limit={this.state.limit}
                                        offset={this.state.offset}
                                        count={ getProp(resource, "data.count", 0)}
                                        paginationPage={this.state.paginationPage}
                                    />
                                )}

                                {resource.isLoading  && (
                                    <LoaderSmall addClass={"text-center"}/>
                                )}

                                {data.length < 1 && !resource.isLoading && (
                                    <NoResults text={this.props.translate("text.no_result")}/>
                                )}
                            </div>
                        </div>

                    </div>

                </div>

                {this.state.editCompanyDriverModal && (
                    <EditCompanyDriverDialog
                        cancel={this.toggleEditCompanyDriverModal}
                        value={this.state.companyDriverItem}
                        visible={this.state.editCompanyDriverModal}
                        {...this.props}
                    />
                )}

                {this.state.addCompanyDriverModal && (
                    <AddCompanyDriverDialog
                        cancel={this.toggleAddCompanyDriverModal}
                        visible={this.state.addCompanyDriverModal}
                        {...this.props}
                    />
                )}

                {this.state.viewReviewsModal && (
                    <ReviewsDriverDialog
                        cancel={this.toggleReviewsModal}
                        list={getProp(this.props, "secondResource.data.list", [])}
                        visible={this.state.viewReviewsModal}
                        reviewSubject={this.state.reviewSubject}
                        {...this.props}
                        toggleAddReviewModal={this.toggleAddReviewModal}
                    />
                )}

                <ConfirmDialog
                    second_title={"Delete driver"}
                    text={this.props.translate("message.are_you_sure_archive_driver")}
                    submit={this.state.deleteModal}
                    cancel={this.toggleDeleteModal}
                    btnText={"Archive"}
                    visible={this.state.deleteModal} {...this.props}
                />

                <AddDriverReviewDialog
                    cancel={this.toggleAddReviewModal}
                    visible={this.state.addReviewModal}
                    {...this.props}
                    driver_id={getProp(this.state, "reviewSubject.driver_id", 0)}
                />
            </BasicLayout>
        );
    }
}

export default connect(state => state)(CompanyDriversView);