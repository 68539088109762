import React, {Component} from "react";
import Dialog from "./index";
import {Field, FieldsManager} from "../../data/services/fields";
import Button from "../button";
import LoaderSmall from "../../components/loader-small";
import {createResource, resetResourceMessage} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import SuccessMessage from "../success-message";
import ErrorMessage from "../error-message";
import {fieldsToHtml} from "../../util/util";

export default class AddCarrierDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                carrier_name: new Field('carrier_name', '', ['empty']),
                carrier_city: new Field('carrier_city', '', []),
                carrier_state: new Field('carrier_state', '', []),
                carrier_zip: new Field('carrier_zip', '', []),
                carrier_address: new Field('carrier_address', '', []),

                carrier_phone: new Field('carrier_phone', '', []),
                carrier_phone2: new Field('carrier_phone2', '', []),
                carrier_email: new Field('carrier_email', '', []),

                carrier_mc: new Field('carrier_mc', '', []),
                carrier_ff: new Field('carrier_ff', '', []),
                carrier_dot: new Field('carrier_dot', '', []),
                carrier_scac: new Field('carrier_scac', '', [])
            }
        };
    }

    componentDidUpdate = (prevProps) => {
        if (((!prevProps.resource.create) && (this.props.resource.create)) ||
            ((!prevProps.resource.error) && (this.props.resource.error))) {
            setTimeout(() => this.props.dispatch(resetResourceMessage()), 5000);
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: FieldsManager.getFieldKeyValues(this.state.fields),
                    piggyResource: Resources.Carriers,
                    resource: Resources.Carriers,
                    query: this.props.query
                }));
            }
        })
    };

    render() {

        const {translate, resource} = this.props;

        const fields = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange);

        return (
            <Dialog active={this.props.visible}>

                <a className="close-dialog" title="Close"
                   onClick={this.props.cancel}
                ><i className="ti-close"></i> </a>

                <div className="dialog-title-wrapper">
                    <div className="dialog-main-title">Carrier</div>
                    <div className="dialog-second-title">add new entry</div>
                </div>

                {resource.create && (
                    <React.Fragment>
                        <SuccessMessage className={"action-success-big"} text={"New entry created!"}
                                        hideClose={true}/>
                        <button
                            className="btn mg-r-15"
                            onClick={() => {
                                this.setState({
                                    fields: {
                                        carrier_name: new Field('carrier_name', '', ['empty'])
                                    }
                                }, () => this.props.dispatch(resetResourceMessage()))
                            }}
                        >
                            {translate("btn.create_another_entry")}
                        </button>
                        <button
                            className="btn"
                            onClick={() => {
                                this.setState({
                                    fields: {
                                        carrier_name: new Field('carrier_name', '', ['empty'])
                                    }
                                });
                                this.props.dispatch(resetResourceMessage());
                                this.props.cancel();
                            }}
                        >
                            {translate("btn.done")}
                        </button>
                    </React.Fragment>
                )}

                {resource.error && (
                    <ErrorMessage className={"action-success-big"} text={resource.errorMessage}
                                  onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {!resource.isLoading && !resource.create && (
                    <form onKeyPress={this.submitForm}>

                        <React.Fragment>
                            <div className="form-row">
                                {fields}
                            </div>

                            <Button className="btn btn-brand mg-r-15" onClick={this.submit} text={"Create"}/>
                            <Button className="btn btn-brand" onClick={this.props.cancel} text={"Cancel"}/>
                        </React.Fragment>

                    </form>
                )}

                {resource.isLoading && (
                    <LoaderSmall addClass={"text-center"}/>
                )}
            </Dialog>
        )
    }

}