import React, {Component} from "react";
import {Field, FieldsManager} from "../../data/services/fields";
import Dialog from "./index";
import {hideAddLoadModal, minimizeAddLoadModal, unminimizeAddLoadModal} from "../../data/actions/ui";
import FieldText from "../field-text";
import FieldTextarea from "../field-textarea";
import Button from "../button";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import SuccessMessage from "../success-message";
import ErrorMessage from "../error-message";
import LoaderSmall from "../../components/loader-small";
import FieldDate from "../field-date";
import {getSecondResource} from "../../data/actions/secondResource";
import {getProp, getStopType, toBackDate, toFrontDateTimeNoSec} from "../../util/util";
import Pagination from "../../components/pagination";
import FieldSelect from "../field-select/index";
import {createLoad, resetLoadMessage} from "../../data/actions/loads";
import NoResults from "../../components/no-results";
import FieldCheckbox from "../field-checkbox/index";
import CreatableSelect from 'react-select/creatable';
import FieldDropdownSelect from "../../components/field-dropdown-select/index";
import Env from "../../util/env";
import {processResponse} from "../../data/services/api-util";
import axios from "axios";

export default class AddLoadDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                load_id: new Field('load_id', '', ['integer']),
                pickup_date: new Field('pickup_date', this.props.ui.viewAddLoadDialog.pickup_date, ['empty']),
                pickup_end_date: new Field('pickup_end_date', this.props.ui.viewAddLoadDialog.pickup_end_date, ['']),
                destination_date: new Field('destination_date', this.props.ui.viewAddLoadDialog.destination_date, ['empty']),
                destination_end_date: new Field('destination_end_date', this.props.ui.viewAddLoadDialog.destination_end_date, ['']),
                driver_id: new Field('driver_id', this.props.ui.viewAddLoadDialog.driver_id, ['empty']),
                carrier_id: new Field('carrier_id', this.props.ui.viewAddLoadDialog.carrier_id, []),
                pickup_customer_id: new Field('pickup_customer_id', this.props.ui.viewAddLoadDialog.pickup_customer_id, ['empty']),
                destination_customer_id: new Field('destination_customer_id', this.props.ui.viewAddLoadDialog.destination_customer_id, ['empty']),
                route_desc: new Field('route_desc', this.props.ui.viewAddLoadDialog.route_desc, ['']),
            },
            fields_customer: {
                query: new Field("query", "", [])
            },
            fields_driver: {
                query: new Field("query", "", []),
                driver_fname: new Field('driver_fname', '', ['empty']),
                driver_lname: new Field('driver_lname', '', ['empty']),
                driver_phone: new Field('driver_phone', '', ['empty'])
            },
            fields_carrier: {
                query: new Field("query", "", []),
                carrier_name: new Field('carrier_name', '', ['empty']),
                carrier_mc: new Field('carrier_mc', '', []),
                carrier_ff: new Field('carrier_ff', '', []),
                carrier_dot: new Field('carrier_dot', '', []),
                carrier_scac: new Field('carrier_scac', '', []),
                carrier_phone: new Field('carrier_phone', '', [])
            },
            attrs: [],
            creatingDriver: false,
            selectDriverMode: 1,

            selectingCarrier: false,
            selectingCarrierMode: 1,
            selectedCarrier: null,

            fields_stops: {
                stop_date: new Field("stop_date", "", ['empty']),
                stop_type: new Field("stop_type", "", ['empty'])
            },
            selectedShipper: this.props.ui.viewAddLoadDialog.pickup_customer,
            selectedConsignee: this.props.ui.viewAddLoadDialog.destination_customer,
            selectedDriver: this.props.ui.viewAddLoadDialog.driver,
            selectingConsigne: false,
            selectingShipper: false,
            selectingDriver: false,
            rangePickup: false,
            rangeDelivery: false,
            sort: "ASC",
            sortBy: "customer_name",
            offset: 0,
            limit: 10,
            paginationPage: 1,
            stops: [],
            addingStop: false
        };
    }

    fetchCustomers = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {
                query: this.state.fields_customer.query.value,
                offset: this.state.offset,
                limit: this.state.limit,
                archived: "no"
            },
            resource: Resources.AddressBook
        }))
    };

    fetchDrivers = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {
                query: this.state.fields_driver.query.value,
                offset: this.state.offset,
                limit: this.state.limit,
                archived: "no"
            },
            resource: Resources.CompanyDrivers
        }))
    };

    fetchCarriers = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {
                query: this.state.fields_carrier.query.value,
                offset: this.state.offset,
                limit: this.state.limit,
                archived: "no"
            },
            resource: Resources.Carriers
        }))
    };

    selectCustomer(name, id) {
        if (this.state.selectingConsigne) {
            this.setState({
                fields: FieldsManager.updateField(this.state.fields, "destination_customer_id", id),
                selectedConsignee: name,
                selectingConsigne: false,
                offset: 0,
                limit: 10,
                paginationPage: 1
            })
        } else if (this.state.selectingShipper) {
            this.setState({
                fields: FieldsManager.updateField(this.state.fields, "pickup_customer_id", id),
                selectedShipper: name,
                selectingShipper: false,
                offset: 0,
                limit: 10,
                paginationPage: 1
            })
        } else if (this.state.addingStop) {
            this.setState({fields_stops: FieldsManager.validateFields(this.state.fields_stops)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields_stops)) {
                    let stops = this.state.stops;
                    stops.push({
                        customer_id: id,
                        customer_name: name,
                        stop_date: this.state.fields_stops.stop_date.value,
                        stop_type: this.state.fields_stops.stop_type.value
                    });
                    this.setState({
                        addingStop: false,
                        stops: stops,
                        fields_stops: {
                            stop_date: new Field("stop_date", "", ['empty']),
                            stop_type: new Field("stop_type", "", ['empty'])
                        }
                    });
                }
            });
        }
    };

    selectDriver = (name, id) => {
        if (this.state.selectingDriver) {
            this.setState({
                fields: FieldsManager.updateField(this.state.fields, "driver_id", id),
                selectedDriver: name,
                selectingDriver: false,
                creatingDriver: false,
                offset: 0,
                limit: 10,
                paginationPage: 1
            })
        }
    };

    createDriver = () => {
        this.setState({fields_driver: FieldsManager.validateFields(this.state.fields_driver)}, () => {
            if (this.state.selectingDriver && FieldsManager.checkFieldsForErrors(this.state.fields_driver)) {
                this.setState({
                    fields: FieldsManager.updateField(this.state.fields, "driver_id", -1),
                    selectedDriver: this.state.fields_driver.driver_fname.value + " " + this.state.fields_driver.driver_lname.value,
                    selectingDriver: false,
                    creatingDriver: true,
                    offset: 0,
                    limit: 10,
                    paginationPage: 1
                })
            }
        });
    };

    selectCarrier = (name, id) => {
        if (this.state.selectingCarrier) {
            this.setState({
                fields: FieldsManager.updateField(this.state.fields, "carrier_id", id),
                selectedCarrier: name,
                selectingCarrier: false,
                creatingCarrier: false,
                offset: 0,
                limit: 10,
                paginationPage: 1
            })
        }
    };

    createCarrier = () => {
        this.setState({fields_carrier: FieldsManager.validateFields(this.state.fields_carrier)}, () => {
            if (this.state.selectingCarrier && FieldsManager.checkFieldsForErrors(this.state.fields_carrier)) {
                this.setState({
                    fields: FieldsManager.updateField(this.state.fields, "carrier_id", -1),
                    selectedCarrier: this.state.fields_carrier.carrier_name.value,
                    selectingCarrier: false,
                    creatingCarrier: true,
                    offset: 0,
                    limit: 10,
                    paginationPage: 1
                })
            }
        });
    };

    handleQueryInputChange = (name, value) => {
        this.setState({fields_customer: FieldsManager.updateField(this.state.fields_customer, name, value)}, this.fetchCustomers);
    };

    handleQueryDriversInputChange = (name, value) => {
        this.setState({fields_driver: FieldsManager.updateField(this.state.fields_driver, name, value)}, this.fetchDrivers);
    };

    handleDriversInputChange = (name, value) => {
        this.setState({fields_driver: FieldsManager.updateField(this.state.fields_driver, name, value)});
    };

    handleQueryCarriersInputChange = (name, value) => {
        this.setState({fields_carrier: FieldsManager.updateField(this.state.fields_carrier, name, value)}, this.fetchCarriers);
    };

    handleCarriersInputChange = (name, value) => {
        this.setState({fields_carrier: FieldsManager.updateField(this.state.fields_carrier, name, value)});
    };

    handleStopsInputChange = (name, value) => {
        this.setState({fields_stops: FieldsManager.updateField(this.state.fields_stops, name, value)});
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    updateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, this.fetchCustomers);
    };

    updateOffsetDrivers = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, this.fetchDrivers);
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {

                const params = Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                    stops: this.state.stops.map((item) => {
                        item.stop_date = toBackDate(item.stop_date);
                        return item;
                    })
                }, {
                    attributes: this.state.attrs.filter((item) => (!!item.key && !!item.value)).map((item) => {
                        return {
                            attribute_key: item.key.label,
                            attribute_value: item.value,
                            is_public: !!item.is_public ? 1 : 0
                        }
                    }),
                    pickup_date: toBackDate(this.state.fields.pickup_date.value),
                    pickup_end_date: toBackDate(this.state.fields.pickup_end_date.value),
                    destination_date: toBackDate(this.state.fields.destination_date.value),
                    destination_end_date: toBackDate(this.state.fields.destination_end_date.value),
                },
                    this.state.creatingDriver?{
                        driver_fname: this.state.fields_driver.driver_fname.value,
                        driver_lname: this.state.fields_driver.driver_lname.value,
                        driver_phone: this.state.fields_driver.driver_phone.value
                    }: {},
                    this.state.creatingCarrier? {
                        carrier_name: this.state.fields_carrier.carrier_name.value,
                    }: {}
                );

                this.props.dispatch(createLoad({
                    user: LocalStorage.get('user'),
                    params: params,
                    piggyResource: this.props.history.location.pathname === "/home"?Resources.Loads:null,
                    resource: Resources.Loads,
                    query: !!this.props.ui.viewAddLoadDialog.query?this.props.ui.viewAddLoadDialog.query:{}
                }));
            }
        })
    };

    changeRangePickup = () => {
        this.setState({
            rangePickup: !this.state.rangePickup
        })
    };

    changeRangeDelivery = () => {
        this.setState({
            rangeDelivery: !this.state.rangeDelivery
        })
    };

    render() {
        const {translate, resource, secondResource, loads} = this.props;

        const customers = getProp(secondResource, "data.list", []).map((item) => {
            return (
                <tr
                    className="has-cursor"
                    onClick={() => this.selectCustomer(item.customer_name, item.customer_id)}
                >
                    <td className="link-like-element-table">{item.customer_name}</td>
                    <td>{item.customer_address}</td>
                    <td>{item.customer_city}</td>
                    <td>{item.customer_state}</td>
                    <td>{item.customer_zip}</td>
                </tr>
            )
        });

        const drivers = getProp(secondResource, "data.list", []).map((item) => {
            return (
                <tr
                    className="has-cursor"
                    onClick={() => this.selectDriver((item.driver_fname + " " + item.driver_lname), item.driver_id)}
                >
                    <td className="link-like-element-table">{item.driver_fname} {item.driver_lname}</td>
                    <td>{item.driver_phone}</td>
                    <td>{item.driver_notes}</td>
                </tr>
            )
        });

        const carriers = getProp(secondResource, "data.list", []).map((item) => {
            return (
                <tr
                    className="has-cursor"
                    onClick={() => this.selectCarrier((item.carrier_name), item.carrier_id)}
                >
                    <td className="link-like-element-table">{item.carrier_name}</td>
                    <td>{item.carrier_mc}</td>
                    <td>{item.carrier_ff}</td>
                    <td>{item.carrier_dot}</td>
                    <td>{item.carrier_scac}</td>
                </tr>
            )
        });

        const stops = this.state.stops.map((item) => {
            return (
                <div className="block">
                    <div>Customer: {item.customer_name}</div>
                    <div>Date: {toFrontDateTimeNoSec(item.stop_date)}</div>
                    <div>Type: {getStopType(item.stop_type)}</div>
                </div>
            );
        });

        const attrs = this.state.attrs.map((item, i) => {
            return (
                <div>
                    <CreatableSelect
                        className={"attr-field"}
                        isClearable
                        onChange={(value) => {
                            let attrs = this.state.attrs;
                            attrs[i].key = value;

                            this.setState({
                                attrs: attrs
                            });
                        }}
                        onInputChange={(inputValue, meta) => {

                        }}
                        options={[
                            { value: 'Cargo', label: 'Cargo' },
                            { value: 'Units Num', label: 'Units Num' },
                            { value: 'Price', label: 'Price' },
                            { value: 'Weight', label: 'Weight' }
                        ]}
                    />

                    <FieldText addClass="form-control attr-field" onChange={(name, value) => {
                        let attrs = this.state.attrs;
                        attrs[i].value = value;

                        this.setState({
                            attrs: attrs
                        });

                    }} value={this.state.attrs[i].value} />

                    <span>Public</span>
                    <FieldCheckbox
                        addClass="form-control attr-field" onChange={(name, value) => {
                        let attrs = this.state.attrs;
                        attrs[i].is_public = value;

                        this.setState({
                            attrs: attrs
                        }, () => {
                            console.log(attrs);
                        });

                    }} value={this.state.attrs[i].is_public}
                    />
                </div>
            )
        });

        return (
            <React.Fragment>
            <div className="minimized">
                <a className="" title="Expand"
                   onClick={() => {
                       this.props.dispatch(
                           unminimizeAddLoadModal()
                       )
                   }}
                ><i className="ti-plus"></i> </a>
            </div>
            <Dialog active={this.props.ui && !this.props.ui.minimizeAddLoadDialog}>

                <a className="minimize-dialog" title="Minimize"
                   onClick={() => {
                       this.props.dispatch(
                           minimizeAddLoadModal()
                       )
                   }}
                ><i className="ti-minus"></i> </a>

                <a className="close-dialog" title="Close"
                   onClick={() => {
                       this.props.dispatch(
                           hideAddLoadModal()
                       )
                   }}
                ><i className="ti-close"></i> </a>

                <div className="dialog-title-wrapper">
                    <div className="dialog-main-title">Load</div>
                    <div className="dialog-second-title">setup new load</div>
                </div>

                {loads.create && (
                    <React.Fragment>
                        <SuccessMessage className={"action-success-big"} text={"New entry created!"}
                                        hideClose={true}/>
                        <button
                            className="btn"
                            onClick={() => {
                                this.setState({
                                    fields: {
                                        load_id: new Field('load_id', '', ['integer']),
                                        pickup_date: new Field('pickup_date', '', ['empty']),
                                        pickup_end_date: new Field('pickup_end_date', '', ['']),
                                        destination_date: new Field('destination_date', '', ['empty']),
                                        destination_end_date: new Field('destination_end_date', '', ['']),
                                        driver_name: new Field('driver_name', '', ['empty']),
                                        driver_phone: new Field('driver_phone', '', ['empty']),
                                        pickup_customer_id: new Field('pickup_customer_id', '', ['empty']),
                                        destination_customer_id: new Field('destination_customer_id', '', ['empty']),
                                        route_desc: new Field('route_desc', '', [''])
                                    },
                                    selectedShipper: null,
                                    selectedConsignee: null,
                                    selectingConsigne: false,
                                    selectingShipper: false,
                                    rangePickup: false,
                                    rangeDelivery: false,
                                    customer: {
                                        query: "",
                                        offset: 0,
                                        limit: 10
                                    }
                                }, () => this.props.dispatch(resetLoadMessage()))
                            }}
                        >
                            Create another entry
                        </button>

                        <button
                            className="btn"
                            onClick={() => {
                                this.props.dispatch(resetLoadMessage());
                                this.props.dispatch(hideAddLoadModal());
                                this.props.history.push("/route/" + loads.create.id);
                            }}>
                            Go to load
                        </button>

                        <button
                            className="btn"
                            onClick={() => {
                                this.setState({
                                    fields: {
                                        load_id: new Field('load_id', '', ['integer']),
                                        pickup_date: new Field('pickup_date', '', ['empty']),
                                        pickup_end_date: new Field('pickup_end_date', '', ['']),
                                        destination_date: new Field('destination_date', '', ['empty']),
                                        destination_end_date: new Field('destination_end_date', '', ['']),
                                        driver_name: new Field('driver_name', '', ['empty']),
                                        driver_phone: new Field('driver_phone', '', ['empty']),
                                        pickup_customer_id: new Field('pickup_customer_id', '', ['empty']),
                                        destination_customer_id: new Field('destination_customer_id', '', ['empty']),
                                        route_desc: new Field('route_desc', '', [''])
                                    },
                                    selectedShipper: null,
                                    selectedConsignee: null,
                                    selectingConsigne: false,
                                    selectingShipper: false,
                                    rangePickup: false,
                                    rangeDelivery: false,
                                    customer: {
                                        query: "",
                                        offset: 0,
                                        limit: 10
                                    }
                                });
                                this.props.dispatch(resetLoadMessage());
                                this.props.dispatch(hideAddLoadModal());
                            }}
                        >
                            Done
                        </button>
                    </React.Fragment>
                )}

                {loads.error && (
                    <ErrorMessage className={"action-success-big"} text={translate(loads.errorMessage)}
                                  onDismiss={() => this.props.dispatch(resetLoadMessage())}/>
                )}

                {this.state.addingStop && (
                    <React.Fragment>
                        <h3>{translate("text.select_stop_customer")}</h3>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Search
                                    </span>
                            </div>
                            <FieldText addClass={"form-control"} onChange={this.handleQueryInputChange} {...this.state.fields_customer.query} />
                        </div>
                        <div className="input-group mb-3">
                            <label>Time*</label>
                            <FieldDate addClass={"form-control"} onChange={this.handleStopsInputChange} {...this.state.fields_stops.stop_date} />

                        </div>
                        <div className="input-group mb-3">
                            <label>Type*</label>
                            <FieldSelect addClass={"form-control"} onChange={this.handleStopsInputChange} {...this.state.fields_stops.stop_type}>
                                <option value={1}>Pickup</option>
                                <option value={2}>Delivery</option>
                                <option value={3}>Pause</option>
                            </FieldSelect>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-dark table-striped table-hover load-table">
                                <thead>
                                <tr>
                                    <th>
                                        NAME
                                    </th>
                                    <th>
                                        ADDRESS
                                    </th>
                                    <th>
                                        CITY
                                    </th>
                                    <th>
                                        STATE
                                    </th>
                                    <th>
                                        ZIP
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {customers}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            updateOffset={this.updateOffset}
                            paginationButtonLimit={5}
                            limit={this.state.limit}
                            offset={this.state.offset}
                            count={getProp(secondResource, "data.count", 0)}
                            paginationPage={this.state.paginationPage}
                        />

                        <Button className="btn btn-brand"  onClick={() => this.setState({addingStop: false, fields_stops: {
                            stop_date: new Field("stop_date", "", ['empty']),
                            stop_type: new Field("stop_type", "", ['empty'])
                        }})} text={"Cancel"}/>

                        {secondResource.isLoading && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}
                    </React.Fragment>
                )}

                {this.state.selectingShipper && (
                    <React.Fragment>
                        <h3>{translate("text.select_shipper")}</h3>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Search
                                    </span>
                            </div>
                            <FieldText addClass={"form-control"} onChange={this.handleQueryInputChange} {...this.state.fields_customer.query} />
                        </div>
                        <div className="table-responsive">
                            <table className="table table-dark table-striped table-hover load-table">
                                <thead>
                                    <tr>
                                        <th>
                                            NAME
                                        </th>
                                        <th>
                                            ADDRESS
                                        </th>
                                        <th>
                                            CITY
                                        </th>
                                        <th>
                                            STATE
                                        </th>
                                        <th>
                                            ZIP
                                        </th>
                                    </tr>
                                </thead>
                                    <tbody>
                                        {customers}
                                    </tbody>
                            </table>
                        </div>
                        <Pagination
                            updateOffset={this.updateOffset}
                            paginationButtonLimit={5}
                            limit={this.state.limit}
                            offset={this.state.offset}
                            count={getProp(secondResource, "data.count", 0)}
                            paginationPage={this.state.paginationPage}
                        />

                        {!secondResource.isLoading && (customers.length < 1) && (
                            <React.Fragment>
                                <NoResults text={this.props.translate("text.no_result")}/>
                            </React.Fragment>
                        )}

                        <Button className="btn btn-brand" onClick={() => this.setState({selectingShipper: false})} text={"Cancel"}/>

                        {secondResource.isLoading && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}
                    </React.Fragment>
                )}

                {this.state.selectingConsigne && (
                    <React.Fragment>
                        <h3>{translate("text.select_consignee")}</h3>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Search
                                    </span>
                            </div>
                            <FieldText addClass={"form-control"} onChange={this.handleQueryInputChange} {...this.state.fields_customer.query} />
                        </div>
                        <div className="table-responsive">
                            <table className="table table-dark table-striped table-hover load-table">
                                <thead>
                                <tr>
                                    <th>
                                        NAME
                                    </th>
                                    <th>
                                        ADDRESS
                                    </th>
                                    <th>
                                        CITY
                                    </th>
                                    <th>
                                        STATE
                                    </th>
                                    <th>
                                        ZIP
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {customers}
                                </tbody>
                            </table>
                            <Pagination
                                updateOffset={this.updateOffset}
                                paginationButtonLimit={5}
                                limit={this.state.limit}
                                offset={this.state.offset}
                                count={getProp(secondResource, "data.count", 0)}
                                paginationPage={this.state.paginationPage}
                            />
                        </div>

                        {!secondResource.isLoading && (customers.length < 1) && (
                            <React.Fragment>
                                <NoResults text={this.props.translate("text.no_result")}/>
                            </React.Fragment>
                        )}

                        <Button className="btn btn-brand"  onClick={() => this.setState({selectingConsigne: false})} text={"Cancel"}/>

                        {secondResource.isLoading && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}
                    </React.Fragment>
                )}

                {this.state.selectingDriver && (
                    <React.Fragment>
                        <div className="card">
                            <div className="card-header">
                                <div className="nav nav-pills">
                                    <a href="#" className={"nav-link" + ((this.state.selectDriverMode === 1)?" active":"")} onClick={() => this.setState({selectDriverMode: 1})}>{translate("text.select_driver")}</a>
                                    <a href="#" className={"nav-link" + ((this.state.selectDriverMode === 2)?" active":"")}  onClick={() => this.setState({selectDriverMode: 2})}>{translate("text.create_driver")}</a>
                                </div>
                            </div>
                            <div className="card-body">
                            {(this.state.selectDriverMode === 2) && (
                                <React.Fragment>
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label>First Name*</label>
                                            <FieldText addClass="form-control" onChange={this.handleDriversInputChange} {...this.state.fields_driver.driver_fname} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Last Name*</label>
                                            <FieldText addClass="form-control" onChange={this.handleDriversInputChange} {...this.state.fields_driver.driver_lname} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Phone*</label>
                                            <FieldText addClass="form-control" onChange={this.handleDriversInputChange} {...this.state.fields_driver.driver_phone} />
                                        </div>
                                    </div>

                                    <Button className="btn btn-brand" onClick={this.createDriver} text={"Create"}/>
                                </React.Fragment>
                            )}

                            {(this.state.selectDriverMode === 1) && (
                                <React.Fragment>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    Search
                                                </span>
                                        </div>
                                        <FieldText addClass={"form-control"} onChange={this.handleQueryDriversInputChange} {...this.state.fields_driver.query} />
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-dark table-striped table-hover load-table">
                                            <thead>
                                            <tr>
                                                <th>
                                                    NAME
                                                </th>
                                                <th>
                                                    PHONE
                                                </th>
                                                <th>
                                                    NOTES
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {drivers}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination
                                        updateOffset={this.updateOffsetDrivers}
                                        paginationButtonLimit={5}
                                        limit={this.state.limit}
                                        offset={this.state.offset}
                                        count={getProp(secondResource, "data.count", 0)}
                                        paginationPage={this.state.paginationPage}
                                    />
                                </React.Fragment>
                            )}

                            {!secondResource.isLoading && (drivers.length < 1) && (
                                <React.Fragment>
                                    <NoResults text={this.props.translate("text.no_result")}/>
                                </React.Fragment>
                            )}

                            <Button className="btn btn-brand mg-l-5" onClick={() => this.setState({selectingDriver: false})} text={"Cancel"}/>

                            {secondResource.isLoading && (
                                <LoaderSmall addClass={"text-center"}/>
                            )}
                            </div>
                        </div>
                    </React.Fragment>
                )}

                {this.state.selectingCarrier && (
                    <React.Fragment>
                        <div className="card">
                            <div className="card-header">
                                <div className="nav nav-pills">
                                    <a href="#" className={"nav-link" + ((this.state.selectingCarrierMode === 1)?" active":"")} onClick={() => this.setState({selectingCarrierMode: 1})}>{translate("text.select_carrier")}</a>
                                    <a href="#" className={"nav-link" + ((this.state.selectingCarrierMode === 2)?" active":"")}  onClick={() => this.setState({selectingCarrierMode: 2})}>{translate("text.create_carrier")}</a>
                                </div>
                            </div>
                            <div className="card-body">
                                {(this.state.selectingCarrierMode === 2) && (
                                    <React.Fragment>
                                        <div className="form-row">
                                            <div className="col-md-6 mb-3">
                                                <label>Name*</label>
                                                <FieldText addClass="form-control" onChange={this.handleCarriersInputChange} {...this.state.fields_carrier.carrier_name} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label>MC#</label>
                                                <FieldText addClass="form-control" onChange={this.handleCarriersInputChange} {...this.state.fields_carrier.carrier_mc} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label>FF#</label>
                                                <FieldText addClass="form-control" onChange={this.handleCarriersInputChange} {...this.state.fields_carrier.carrier_ff} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label>DOT#</label>
                                                <FieldText addClass="form-control" onChange={this.handleCarriersInputChange} {...this.state.fields_carrier.carrier_dot} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label>SCAC#</label>
                                                <FieldText addClass="form-control" onChange={this.handleCarriersInputChange} {...this.state.fields_carrier.carrier_scac} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label>Phone</label>
                                                <FieldText addClass="form-control" onChange={this.handleCarriersInputChange} {...this.state.fields_carrier.carrier_phone} />
                                            </div>
                                        </div>

                                        <Button className="btn btn-brand" onClick={this.createCarrier} text={"Create"}/>
                                    </React.Fragment>
                                )}

                                {(this.state.selectingCarrierMode === 1) && (
                                    <React.Fragment>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    Search
                                                </span>
                                            </div>
                                            <FieldText addClass={"form-control"} onChange={this.handleQueryCarriersInputChange} {...this.state.fields_carrier.query} />
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-dark table-striped table-hover load-table">
                                                <thead>
                                                <tr>
                                                    <th>
                                                        NAME
                                                    </th>
                                                    <th>
                                                        MC#
                                                    </th>
                                                    <th>
                                                        FF#
                                                    </th>
                                                    <th>
                                                        DOT#
                                                    </th>
                                                    <th>
                                                        SCAC#
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {carriers}
                                                </tbody>
                                            </table>
                                        </div>
                                        <Pagination
                                            updateOffset={this.updateOffsetDrivers}
                                            paginationButtonLimit={5}
                                            limit={this.state.limit}
                                            offset={this.state.offset}
                                            count={getProp(secondResource, "data.count", 0)}
                                            paginationPage={this.state.paginationPage}
                                        />
                                    </React.Fragment>
                                )}

                                {!secondResource.isLoading && (drivers.length < 1) && (
                                    <React.Fragment>
                                        <NoResults text={this.props.translate("text.no_result")}/>
                                    </React.Fragment>
                                )}

                                <Button className="btn btn-brand mg-l-5" onClick={() => this.setState({selectingCarrier: false})} text={"Cancel"}/>

                                {secondResource.isLoading && (
                                    <LoaderSmall addClass={"text-center"}/>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}

                {!loads.isLoading && (!this.state.selectingShipper && !this.state.selectingConsigne && !this.state.selectingDriver && !this.state.addingStop && !this.state.selectingCarrier) && !loads.create && (
                    <form onKeyPress={this.submitForm}>
                        <React.Fragment>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label>Load Number*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.load_id} />
                                </div>
                                <div className="col-md-6 mb-3"></div>

                                <div className="dialog-section-title col-sm-12">Addresses</div>

                                <div className="col-md-6 mb-3">
                                    <label>Shipper*</label>

                                    <div className="input-group">

                                        <div className="input-group-prepend" title="Select Contact">
                                            <span className="input-group-text link-like-element" onClick={() => this.setState({selectingShipper: true},  () => {
                                                this.fetchCustomers();
                                            })}>
                                                <i className="ti-user"></i><i className="ti-angle-down"></i>
                                            </span>
                                        </div>

                                        <FieldDropdownSelect
                                            onChange={(name, value) => {
                                                this.setState({
                                                    selectedShipper: value.label,
                                                    fields: FieldsManager.updateField(this.state.fields, "pickup_customer_id", value.value)
                                                })
                                            }}
                                            addClass="form-control-select"
                                            name={"pickup_customer_id"}
                                            value={{
                                                value: this.state.fields.pickup_customer_id.value,
                                                label: this.state.selectedShipper
                                            }}
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl('api/cloud/address-book', {query: inputValue}),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                            }
                                                        }
                                                    )
                                                        .then((response) => {
                                                            const result = processResponse(response);
                                                            if (result && result.status === 0) {
                                                                const list = result.data.list.map((item) => {
                                                                    return {
                                                                        value: item.customer_id,
                                                                        label: item.customer_name
                                                                    }
                                                                });
                                                                callback(list);
                                                            }
                                                        })
                                                        .catch((error) => {
                                                        });
                                                }
                                            }
                                        />

                                        {this.state.fields.destination_customer_id.errorMessage && (
                                            <div className={"invalid-feedback"}>
                                                {"Field required*"}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Consignee*</label>

                                    <div className="input-group">

                                        <div className="input-group-prepend" title="Select Contact">
                                            <span className="input-group-text link-like-element" onClick={() => this.setState({selectingConsigne: true},  () => {
                                                this.fetchCustomers();
                                            })}>
                                                <i className="ti-user"></i><i className="ti-angle-down"></i>
                                            </span>
                                        </div>

                                        <FieldDropdownSelect
                                            onChange={(name, value) => {
                                                this.setState({
                                                    selectedConsignee: value.label,
                                                    fields: FieldsManager.updateField(this.state.fields, "destination_customer_id", value.value)
                                                })
                                            }}
                                            addClass="form-control-select"
                                            name={"destination_customer_id"}
                                            value={{
                                                value: this.state.fields.destination_customer_id.value,
                                                label: this.state.selectedConsignee
                                            }}
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl('api/cloud/address-book', {query: inputValue}),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                            }
                                                        }
                                                    )
                                                        .then((response) => {
                                                            const result = processResponse(response);
                                                            if (result && result.status === 0) {
                                                                const list = result.data.list.map((item) => {
                                                                    return {
                                                                        value: item.customer_id,
                                                                        label: item.customer_name
                                                                    }
                                                                });
                                                                callback(list);
                                                            }
                                                        })
                                                        .catch((error) => {
                                                        });
                                                }
                                            }
                                        />

                                        {this.state.fields.destination_customer_id.errorMessage && (
                                            <div className={"invalid-feedback"}>
                                                {"Field required*"}
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>

                            <div className="form-row">
                                <div className="dialog-section-title col-sm-12">Pickup date and time (in local time zone)</div>
                                <div className="col-md-6"><span className="link-like-element" onClick={this.changeRangePickup}>{this.state.rangePickup?"Disable range":"Enable range"}</span></div>
                            </div>

                            <div className="form-row">
                                <div className="mb-3 col-md-6">
                                    <label>From*</label>
                                    <FieldDate addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.pickup_date} />
                                </div>
                                {this.state.rangePickup && (
                                    <div className="col-md-6 mb-3">
                                        <label>To</label>
                                        <FieldDate addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.pickup_end_date} />
                                    </div>
                                )}
                            </div>

                            <div className="form-row">
                                <div className="dialog-section-title col-sm-12">Delivery date and time (in local time zone)</div>
                                <div className="col-md-6"><span className="link-like-element" onClick={this.changeRangeDelivery}>{this.state.rangeDelivery?"Disable range":"Enable range"}</span></div>
                            </div>
                            <div className="form-row">
                                <div className={"mb-3 col-md-6"}>
                                    <label>From*</label>
                                    <FieldDate addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.destination_date} />
                                </div>
                                {this.state.rangeDelivery && (
                                    <div className="col-md-6 mb-3">
                                        <label>To</label>
                                        <FieldDate addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.destination_end_date} />
                                    </div>
                                )}
                            </div>

                            <div className="form-row">
                                <div className="dialog-section-title col-sm-12">Load stops</div>
                                <div className="col-md-6"><span className="link-like-element" onClick={() => this.setState({addingStop: true}, this.fetchCustomers)}>{translate("text.add_stop")}</span></div>
                            </div>

                            <div className="form-row">
                                {stops}
                            </div>

                            <div className="form-row">
                                <div className="dialog-section-title col-sm-12">Driver details</div>

                                <div className="col-md-6 mb-3">
                                    <label>Driver*</label>

                                    <div className="input-group">

                                        <div className="input-group-prepend" title="Select Driver">
                                            <span className="input-group-text link-like-element" onClick={() => this.setState({selectingDriver: true},  () => {
                                                this.fetchDrivers();
                                            })}>
                                                <i className="ti-user"></i><i className="ti-angle-down"></i>
                                            </span>
                                        </div>

                                        <FieldDropdownSelect
                                            onChange={(name, value) => {
                                                this.setState({
                                                    selectedDriver: value.label,
                                                    fields: FieldsManager.updateField(this.state.fields, "driver_id", value.value)
                                                })
                                            }}
                                            addClass="form-control-select"
                                            name={"driver_id"}
                                            value={{
                                                value: this.state.fields.driver_id.value,
                                                label: this.state.selectedDriver
                                            }}
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl('api/cloud/company-drivers', {query: inputValue}),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                            }
                                                        }
                                                    )
                                                    .then((response) => {
                                                        const result = processResponse(response);
                                                        if (result && result.status === 0) {
                                                            const list = result.data.list.map((item) => {
                                                                return {
                                                                    value: item.driver_id,
                                                                    label: item.driver_fname + " " + item.driver_lname
                                                                }
                                                            });
                                                            callback(list);
                                                        }
                                                    })
                                                    .catch((error) => {});
                                                }
                                            }
                                        />

                                        {this.state.fields.driver_id.errorMessage && (
                                            <div className={"invalid-feedback"}>
                                                {"Field required*"}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="dialog-section-title col-sm-12">Load additional details</div>

                                <div className="col-md-12 mb-3">
                                    <label>Attributes</label>
                                    <span className="link-like-element display-block" onClick={() => {
                                        let attrs = this.state.attrs;
                                        attrs.push({
                                            key: "",
                                            value: ""
                                        });

                                        this.setState({
                                            attrs: attrs
                                        });
                                    }}>{translate("text.add_attributes")}
                                    </span>

                                    {attrs}
                                </div>

                                <div className="dialog-section-title col-sm-12">Carrier details</div>

                                <div className="col-md-6 mb-3">
                                    <label>Carrier</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend" title="Select Carrier">
                                            <span className="input-group-text link-like-element" onClick={() => this.setState({selectingCarrier: true},  () => {
                                                this.fetchCarriers();
                                            })}>
                                                <i className="ti-user"></i><i className="ti-angle-down"></i>
                                            </span>
                                        </div>

                                        <FieldDropdownSelect
                                            onChange={(name, value) => {
                                                this.setState({
                                                    selectedCarrier: value.label,
                                                    fields: FieldsManager.updateField(this.state.fields, "carrier_id", value.value)
                                                })
                                            }}
                                            addClass="form-control-select"
                                            name={"carrier_id"}
                                            value={{
                                                value: this.state.fields.driver_id.value,
                                                label: this.state.selectedCarrier
                                            }}
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl('api/cloud/carriers', {query: inputValue}),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                            }
                                                        }
                                                    )
                                                    .then((response) => {
                                                        const result = processResponse(response);
                                                        if (result && result.status === 0) {
                                                            const list = result.data.list.map((item) => {
                                                                return {
                                                                    value: item.carrier_id,
                                                                    label: item.carrier_name
                                                                }
                                                            });
                                                            callback(list);
                                                        }
                                                    }).catch((error) => {});
                                                }
                                            }
                                        />

                                    </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label>Notes</label>
                                    <FieldTextarea addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.route_desc} />
                                </div>
                            </div>

                            <Button className="btn btn-brand" onClick={this.submit} text={"Add New Load"}/>
                        </React.Fragment>
                    </form>
                )}

                {loads.isLoading && (
                    <LoaderSmall addClass={"text-center"}/>
                )}
            </Dialog>
            </React.Fragment>
        )
    }
}