import React, {Component} from "react";
import {Link} from "react-router-dom";

export default class PublicLayout extends Component {

    componentDidMount() {

    }

    render() {
        const {translate} = this.props;

        return (
            <React.Fragment>

                <div className="ht-100v text-center">
                    <div className="row pd-0 mg-0">
                        <div className="col-lg-6 login-image-background hidden-sm">
                            <div className="ht-100v d-flex">
                                <div className="align-self-center login-background-black">
                                    <img src="/images/large-logo-white.png" className="img-fluid pd-t-30 login-logo" alt=""/>
                                    <h1 className="tx-36 tx-semibold tx-gray-100 pd-t-30 tx-ibm">ACTIV8TRACK</h1>
                                    <h3 className="tx-20 tx-semibold tx-gray-100 pd-t-50">SOFTWARE FOR YOUR TRUCK FLEET</h3>
                                    <p className="pd-y-15 pd-x-10 pd-md-x-100 tx-gray-200">See where Your truck load or driver
                                        is at any given moment. Easy to use, easy to integrate and very reliable.</p>
                                </div>
                            </div>
                        </div>

                        {this.props.children}

                    </div>
                </div>

            </React.Fragment>
        )
    }
}