import React, {Component} from "react";
import Dialog from "./index";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldText from "../field-text";
import FieldTextarea from "../field-textarea";
import Button from "../button";
import LoaderSmall from "../../components/loader-small";
import {createResource, resetResourceMessage} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import SuccessMessage from "../success-message";
import ErrorMessage from "../error-message";

export default class AddCompanyDriverDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                driver_fname: new Field('driver_fname', '', ['empty']),
                driver_lname: new Field('driver_lname', '', ['empty']),
                driver_phone: new Field('driver_phone', '', ['empty']),
                driver_notes: new Field('driver_notes', '', ['']),
            }
        };
    }

    componentDidUpdate = (prevProps) => {
        if (((!prevProps.resource.create) && (this.props.resource.create)) ||
            ((!prevProps.resource.error) && (this.props.resource.error))) {
            setTimeout(() => this.props.dispatch(resetResourceMessage()), 5000);
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: FieldsManager.getFieldKeyValues(this.state.fields),
                    piggyResource: Resources.CompanyDrivers,
                    resource: Resources.CompanyDrivers,
                    query: this.props.query
                }));
            }
        })
    };

    render() {

        const {translate, resource} = this.props;

        return (
            <Dialog active={this.props.visible}>

                <a className="close-dialog" title="Close"
                   onClick={this.props.cancel}
                ><i className="ti-close"></i> </a>

                <div className="dialog-title-wrapper">
                    <div className="dialog-main-title">Company Driver</div>
                    <div className="dialog-second-title">add new entry</div>
                </div>

                {resource.create && (
                    <React.Fragment>
                        <SuccessMessage className={"action-success-big"} text={"New entry created!"}
                                        hideClose={true}/>
                        <button
                            className="btn"
                            onClick={() => {
                                this.setState({
                                    fields: {
                                        driver_fname: new Field('driver_fname', '', ['empty']),
                                        driver_lname: new Field('driver_lname', '', ['empty']),
                                        driver_phone: new Field('driver_phone', '', ['empty']),
                                        driver_notes: new Field('driver_notes', '', ['']),
                                    }
                                }, () => this.props.dispatch(resetResourceMessage()))
                            }}
                        >
                            Create another entry
                        </button>
                        <button
                            className="btn"
                            onClick={() => {
                                this.setState({
                                    fields: {
                                        driver_fname: new Field('driver_fname', '', ['empty']),
                                        driver_lname: new Field('driver_lname', '', ['empty']),
                                        driver_phone: new Field('driver_phone', '', ['empty']),
                                        driver_notes: new Field('driver_notes', '', ['']),
                                    }
                                });
                                this.props.dispatch(resetResourceMessage());
                                this.props.cancel();
                            }}
                        >
                            Done
                        </button>
                    </React.Fragment>
                )}

                {resource.error && (
                    <ErrorMessage className={"action-success-big"} text={resource.errorMessage}
                                  onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {!resource.isLoading && !resource.create && (
                    <form onKeyPress={this.submitForm}>

                        <React.Fragment>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label>First Name*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.driver_fname} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label>Last Name*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.driver_lname} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Phone*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.driver_phone} />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label>Notes</label>
                                    <FieldTextarea addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.driver_notes} />
                                </div>

                            </div>

                            <Button className="btn btn-brand mg-r-15" onClick={this.submit} text={"Create"}/>
                            <Button className="btn btn-brand" onClick={this.props.cancel} text={"Cancel"}/>
                        </React.Fragment>

                    </form>
                )}

                {resource.isLoading && (
                    <LoaderSmall addClass={"text-center"}/>
                )}

            </Dialog>
        )
    }

}