import React, { Component } from 'react';
import {connect} from "react-redux";
import {FlashMessage} from "./components/flash-message/index";
import {getProp} from "./util/util";
import AddLoadDialog from "./components/dialog/add-load";
import AddAddressBookDialog from "./components/dialog/add-addressBook";

class PropsReceiver extends Component {

    render() {
        return (
            <React.Fragment>
                {this.props.ui && this.props.ui.viewAddAddressBookDialog && (
                    <AddAddressBookDialog {...this.props}/>
                )}

                {this.props.ui && this.props.ui.viewAddLoadDialog && (
                    <AddLoadDialog
                        visible={this.props.ui.minimizeAddLoadDialog}
                        {...this.props}
                    />
                )}

                <FlashMessage messages={getProp(this.props, "flashMessage.messages", [])}/>
            </React.Fragment>
        );
    }
}

export default connect(state => state)(PropsReceiver)