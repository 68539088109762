import React from "react";
import LocalStorage from "./localStorage";
import moment from "moment-timezone";
import FieldTextarea from "../components/field-textarea/index";
import FieldCheckbox from "../components/field-checkbox/index";
import FieldText from "../components/field-text/index";
import FieldDate from "../components/field-date/index";
import FieldSelectSearch from "../components/field-select-search/index";

export const READ_PERM = 1;
export const CREATE_PERM = 2;
export const UPDATE_PERM = 4;
export const DELETE_PERM = 8;

export const ETA_FEATURE = "eta";
export const LOCATION_ADDRESS_FEATURE = "address_location";

export function checkFeature(user, feature) {
    const companyFeatures = getProp(user, "Contact.features", "").split(",");
    return companyFeatures.includes(feature);
}

export function checkPerm(key, check) {
    const perm = parseInt(getProp(LocalStorage.get('user'), 'permissions.' + key, 0));
    return (perm & check) !== 0;
}

export function getProp(object, keys, defaultVal) {
    if (object === undefined || object === null) {
        return defaultVal;
    }
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1), defaultVal);
    }
    return (object === undefined || object === null) ? defaultVal : object;
}

export function getCurrentTimeSeconds() {
    const d = new Date();
    return Math.round(d.getTime() / 1000);
}

export function toBackDateTime(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000") {
        return "no data available";
    }
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss") : "no data available");
}

export function toBackDate(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000") {
        return "";
    }
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss") : "");
}

export function toBackDateUtc(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000") {
        return "";
    }
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss', LocalStorage.get('user').Contact.user_zone).tz("UTC").format("YYYY-MM-DD HH:mm:ss") : "");
}

export function toFrontDateTimeNoSec(dateEntered) {
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format(LocalStorage.get('user').Contact.user_datetimeformat) : "");
}

export function toFrontDateTimeNoSecFromUTC(dateEntered) {
    return (dateEntered ? moment.utc(dateEntered, 'YYYY-MM-DD HH:mm:ss', 'UTC').tz(LocalStorage.get('user').Contact.user_zone).format(LocalStorage.get('user').Contact.user_datetimeformat) : "");
}

export function toFrontDateTimeNoSecFromUTCAndZone(dateEntered, zone = "UTC") {
    return (dateEntered ? moment.utc(dateEntered, 'YYYY-MM-DD HH:mm:ss', 'UTC').tz(zone).format(LocalStorage.get('user').Contact.user_datetimeformat) : "");
}

export function toFrontDateTimeNoUTCFormat(dateEntered) {
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("MM/DD/YYYY HH:mm") : "no data available");
}

export function numberWithCommasInputChange(n) {
    if (n) {
        let original = n.toString().split(".");
        if (original[1] && original[1].length > 1) {
            return original[0] + (original[1] ? "." + original[1].charAt(0) + original[1].charAt(1) : "");
        } else {
            n = n.replace(/[^0-9.]/g, '');
            n = n.replace(/,/g, "");
            n = n.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return n;
        }
    }
}

export function getStopType(type) {
    return (<span>{getStopTypeString(type)}</span>)
}

export function getStopTypeString(type) {
    switch (type) {
        case '1':
            return ("Pickup");
        case '2':
            return ("Delivery");
        case '3':
        default:
            return ("Pause");
    }
}

const generateIconCache = {};
let imageHeight = 0;

export function generateIcon(number, callback, hex = '#2063C6') {
    if (generateIconCache[number + hex] !== undefined) {
        callback(generateIconCache[number + hex]);
    }

    var fontSize = 16, imageWidth = imageHeight = 25;

    if (isNaN(number)) {

    } else if (number >= 1000) {
        fontSize = 10;
        imageWidth = imageHeight = 45;
    } else if (number < 1000 && number > 100) {
        fontSize = 14;
        imageWidth = imageHeight = 35;
    }

    var svg = window.d3.select(document.createElement('div')).append('svg')
        .attr('viewBox', '0 0 54.4 54.4')
        .append('g')

    var circles = svg.append('circle')
        .attr('cx', '27.2')
        .attr('cy', '27.2')
        .attr('r', '21.2')
        .style('fill', hex);

    var path = svg.append('path')
        .attr('d', 'M27.2,0C12.2,0,0,12.2,0,27.2s12.2,27.2,27.2,27.2s27.2-12.2,27.2-27.2S42.2,0,27.2,0z M6,27.2 C6,15.5,15.5,6,27.2,6s21.2,9.5,21.2,21.2c0,11.7-9.5,21.2-21.2,21.2S6,38.9,6,27.2z')
        .attr('fill', '#FFFFFF');

    var text = svg.append('text')
        .attr('dx', 27)
        .attr('dy', 32)
        .attr('text-anchor', 'middle')
        .attr('style', 'font-size:' + fontSize + 'px; fill: #FFFFFF; font-family: Arial, Verdana; font-weight: bold')
        .text(number);

    var svgNode = svg.node().parentNode.cloneNode(true),
        image = new Image();

    window.d3.select(svgNode).select('clippath').remove();

    var xmlSource = (new XMLSerializer()).serializeToString(svgNode);

    image.onload = (function(imageWidth, imageHeight) {
        var canvas = document.createElement('canvas'),
            context = canvas.getContext('2d'),
            dataURL;

        window.d3.select(canvas)
            .attr('width', imageWidth)
            .attr('height', imageHeight);

        context.drawImage(image, 0, 0, imageWidth, imageHeight);

        dataURL = canvas.toDataURL();
        generateIconCache[number + hex] = dataURL;

        callback(dataURL);
    }).bind(this, imageWidth, imageHeight);

    image.src = 'data:image/svg+xml;base64,' + btoa(encodeURIComponent(xmlSource).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
}

export function getKeyStatus(status) {
    switch (status) {
        case "0":
            return (<span className="badge badge-outline-warning">Created</span>);
        case "1":
            return (<span className="badge badge-outline-success">Activated</span>);
        case "2":
            return (<span className="badge badge-outline-info">Finished</span>);
    }
}

export function getRouteStatus(status) {
    switch (status) {
        case "0":
            return (<span className="badge badge-outline-warning">Created</span>);
        case "1":
            return (<span className="badge badge-outline-warning">Code Sent</span>);
        case "2":
            return (<span className="badge badge-outline-success">Activated</span>);
        case "3":
            return (<span className="badge badge-outline-success">Tracked</span>);
        case "4":
            return (<span className="badge badge-outline-info">Finished by Driver</span>);
        case "5":
            return (<span className="badge badge-outline-info">Finished by Operator</span>);
        case "9":
            return (<span className="badge badge-outline-danger">Aborted</span>);
    }
};

export function getEventColor(type) {
    switch (parseInt(type)) {
        case 1:
            return '#28a745';
        case 2:
            return '#007bff';
        case 3:
            return '#ffc107';
    }
}
export function fieldsToHtml(fieldsCpy, translate, handleInputChange, split = false, selects = {}) {
    let result = fieldsCpy.reduce((memo, item, i) => {

        const req = (item.validate.includes("empty") || item.validate.includes("integer") || item.validate.includes("float"));

        let field = null;

        console.log(item.type);
        if (item.type === 'dialog') {
            field = (
                <div className="form-element-container" key={i}>
                    <div className="element-name align-vertical-middle">{translate("field." + item.name)}{req ? "*" : ""}</div>
                    <div className="element-input align-vertical-middle">
                        <span className="has-cursor link-like-element" title={translate("text.view_contact_card")}
                              onClick={() => {
                                  selects[item.name].onTrigger()
                              }}>
                            {selects[item.name].formatValue(item)}
                        </span>
                    </div>
                </div>
            );
        } else if (item.type === 'hidden') {
            field = null;
        } else if (item.type === "textarea") {
            field = (
                <div className="col-md-6 mb-3">
                    <label>{translate("field." + item.name)}{req ? "*" : ""}</label>
                    <FieldTextarea addClass="form-control" onChange={handleInputChange} {...item} />
                </div>
            );
        } else if (item.type === "checkbox") {
            field = (
                <div className="form-element-container" key={i}>
                    <div className="element-name">{translate("field." + item.name)}{req ? "*" : ""}</div>
                    <div className="element-input">
                        <FieldCheckbox onChange={handleInputChange} {...item} />
                    </div>
                </div>
            );
        } else if (item.type === "select") {
            let values = {};
            if (!!selects[item.name]) {
                values = selects[item.name];
            }

            field = (
                <div className="form-element-container" key={i}>
                    <div className="element-name">{translate("field." + item.name)}{req ? "*" : ""}</div>
                    <div className="element-input">
                        <FieldSelectSearch onChange={handleInputChange}
                                           values={values}
                                           {...item}>
                        </FieldSelectSearch>
                    </div>
                </div>
            );
        } else if (item.type === "date") {
            field = (
                <div className="form-element-container" key={i}>
                    <div
                        className="element-name align-vertical-middle">{translate("field." + item.name)}{req ? "*" : ""}</div>
                    <div className="element-input">
                        <FieldDate onChange={handleInputChange} {...item} />
                    </div>
                </div>
            );
        } else {
            field = (
                <div className="col-md-6 mb-3">
                    <label>{translate("field." + item.name)}{req ? "*" : ""}</label>
                    <FieldText addClass="form-control" onChange={handleInputChange} {...item} />
                </div>
            );
        }
        memo.push(field);

        return memo;
    }, []);

    return result;
}