import React from "react";
import {Link} from "react-router-dom";
import Logout from "../../logout";
import {checkPerm, READ_PERM} from "../../../util/util";
import Resources from "../../../data/services/resources";
import {showAddSupportModal} from "../../../data/actions/ui";

export default (props) => {

    return (
        <div className="page-sidebar">
            <div className="logo has-cursor" onClick={props.onClick}>
                <a className="logo-img logo-text">
                    <img src="/images/large-logo-white.png" className="" alt=""/>
                    <span className="desktop-logo text-white">ACTIV8TRACK</span>
                </a>
                <a id="sidebar-toggle-button-close"><i className="wd-20" data-feather="x"></i> </a>
            </div>

            <div className="page-sidebar-inner">
                <div className="page-sidebar-menu">
                    <ul className="accordion-menu">

                        <li className="mg-l-20-force mg-t-25-force mg-b-15-force menu-navigation tx-18-force">Navigation</li>
                        {checkPerm(Resources.Loads, READ_PERM) && (
                            <li className="mg-l-5-force">
                                <Link to="/home">
                                    <i className="ti-map-alt mg-r-10-force tx-26"></i> <span>Loads</span>
                                </Link>
                            </li>
                        )}
                        {checkPerm(Resources.CompanyDrivers, READ_PERM) && (
                            <li className="mg-l-5-force">
                                <Link to="/companyDrivers">
                                    <i className="fa fa-road mg-r-10-force tx-26"></i> <span>Drivers</span>
                                </Link>
                            </li>
                        )}
                        {checkPerm(Resources.AddressBook, READ_PERM) && (
                            <li className="mg-l-5-force">
                                <Link to="/addressBook">
                                    <i className="ti-book mg-r-10-force tx-26"></i> <span>Address book</span>
                                </Link>
                            </li>
                        )}

                        {checkPerm(Resources.Carriers, READ_PERM) && (
                            <li className="mg-l-5-force">
                                <Link to="/carriers">
                                    <i className="ti-truck mg-r-10-force tx-26"></i> <span>Carriers</span>
                                </Link>
                            </li>
                        )}

                        {(checkPerm(Resources.CompanyUsers, READ_PERM) || checkPerm(Resources.CompanyDetails, READ_PERM)) && (
                            <li className="mg-l-20-force mg-t-25-force mg-b-15-force menu-navigation tx-18-force">Company</li>
                        )}
                        
                        {checkPerm(Resources.CompanyDetails, READ_PERM) && (
                            <li className="mg-l-5-force">
                                <Link to="/companyDetails">
                                    <i className="fa fa-building mg-r-10-force tx-26"></i> <span>Information</span>
                                </Link>
                            </li>
                        )}
                        {checkPerm(Resources.CompanyUsers, READ_PERM) && (
                            <li className="mg-l-5-force">
                                <Link to="/companyUsers">
                                    <i className="fa fa-users mg-r-10-force tx-26"></i> <span>Users</span>
                                </Link>
                            </li>
                        )}

                        <li className="mg-l-20-force mg-t-25-force mg-b-15-force menu-navigation tx-18-force">Personal</li>
                        <li className="mg-l-5-force">
                            <Link to="/profile">
                                <i className="ti-user mg-r-10-force tx-26"></i> <span>Profile</span>
                            </Link>
                        </li>

                        <li className="mg-l-20-force mg-t-25-force mg-b-15-force menu-navigation tx-18-force">Help</li>

                        <li className="mg-l-5-force">
                            <Link onClick={() => {
                                if (!props.ui.viewAddSupportDialog) {
                                    props.dispatch(showAddSupportModal());
                                }
                            }}>
                                <i className="ti-ticket mg-r-10-force tx-26"></i> <span>Support</span>
                            </Link>
                        </li>

                        <li className="mg-l-5-force">
                            <Link to="/faq">
                                <i className="ti-announcement mg-r-10-force tx-26"></i> <span>FAQ</span>
                            </Link>
                        </li>

                    </ul>
                </div>
            </div>

            <div className="sidebar-footer">
                <Link to="/profile" title="Profile" className={"pull-left"}><i className="ti-user tx-22-force"></i></Link>
                <Logout addClass={"pull-left"} title={"Logout"} history={props.history} translate={props.translate} dispatch={props.dispatch}/>
            </div>
        </div>
    )
};


