export function getLoads(data) {
    return {
        type: 'GET_LOADS',
        data: data
    };
}

export function doneGetLoads(data) {
    return {
        type: 'DONE_GET_LOADS',
        data: data
    };
}

export function errorGetLoads(data) {
    return {
        type: 'ERROR_GET_LOADS',
        data: data
    };
}

export function createLoad(data) {
    return {
        type: 'CREATE_LOAD',
        data: data
    };
}

export function doneCreateLoad(data) {
    return {
        type: 'DONE_CREATE_LOAD',
        data: data
    };
}

export function errorCreateLoad(data) {
    return {
        type: 'ERROR_CREATE_LOAD',
        data: data
    };
}

export function deleteLoad(data) {
    return {
        type: 'DELETE_LOAD',
        data: data
    };
}

export function doneDeleteLoad(data) {
    return {
        type: 'DONE_DELETE_LOAD',
        data: data
    };
}

export function errorDeleteLoad(data) {
    return {
        type: 'ERROR_DELETE_LOAD',
        data: data
    };
}

export function resetLoadMessage() {
    return {
        type: 'RESET_LOAD_MESSAGE'
    }
}