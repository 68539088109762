import React, {Component} from "react";
import Button from "../button/index";
import Dialog from "./index";

export default class ConfirmDialog extends Component {

    render() {

        return (
            <Dialog active={this.props.visible} addClass={"small"}>

                <a className="close-dialog" title="Close"
                   onClick={() => {
                       this.props.cancel();
                   }}
                ><i className="ti-close"></i> </a>

                <div className="dialog-title-wrapper">
                    <div className="dialog-main-title">Confirm</div>
                    <div className="dialog-second-title">{this.props.second_title}</div>
                </div>
                <div className="dialog-text-wrapper">
                    {this.props.text}
                </div>
                <div>
                    <Button className="btn btn-brand" onClick={() => { this.props.submit(); this.props.cancel(); }} text={this.props.btnText?this.props.btnText:"Confirm"}/>
                </div>
            </Dialog>
        )
    }
}