import React, {Component} from "react";
import Dialog from "./index";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldText from "../field-text";
import FieldSelect from "../field-select";
import FieldTextarea from "../field-textarea";
import Button from "../button";
import LoaderSmall from "../../components/loader-small";
import {resetResourceMessage, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import SuccessMessage from "../success-message";
import ErrorMessage from "../error-message";
import FieldGooglePlaces from "../field-google-places";

export default class EditAddressBookDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {

            }
        };
    }

    componentDidMount() {
        this.setState({
                fields: {
                    customer_name: new Field('customer_name', this.props.value.customer_name, ['empty']),
                    customer_address: new Field('customer_address', this.props.value.customer_address, ['empty']),
                    customer_city: new Field('customer_city', this.props.value.customer_city, ['empty']),
                    customer_state: new Field('customer_state', this.props.value.customer_state, ['empty']),
                    customer_zip: new Field('customer_zip', this.props.value.customer_zip, ['empty']),
                    customer_phone: new Field('customer_phone', this.props.value.customer_phone, []),
                    customer_phone2: new Field('customer_phone2', this.props.value.customer_phone2, []),
                    customer_emergency_phone: new Field('customer_emergency_phone', this.props.value.customer_emergency_phone, []),
                    customer_email: new Field('customer_email', this.props.value.customer_email, []),
                    customer_hours: new Field('customer_hours', this.props.value.customer_hours, []),
                    customer_contact: new Field('customer_contact', this.props.value.customer_contact, []),
                    customer_appointment: new Field('customer_appointment', this.props.value.customer_appointment, []),
                    customer_directions: new Field('customer_directions', this.props.value.customer_directions, []),
                    customer_desc: new Field('customer_desc', this.props.value.customer_desc, []),
                }
            }
        )
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };


    submit = (event) => {
        event && event.preventDefault();
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {

                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({id: this.props.value.customer_id}, FieldsManager.getFieldKeyValues(this.state.fields)),
                    piggyResource: Resources.AddressBook,
                    resource: Resources.AddressBook,
                    query: this.props.query
                }));

            }
        })
    };

    setLocations = (fields) => {
        let stateFields = this.state.fields
        stateFields.customer_zip.value = fields.PostalCode
        stateFields.customer_city.value = fields.CityName
        stateFields.customer_state.value = fields.State
        this.setState({fields: stateFields})
    };

    render() {

        const {translate, resource} = this.props;

        return (
            <Dialog active={this.props.visible}>

                <a className="close-dialog" title="Close"
                   onClick={this.props.cancel}
                ><i className="ti-close"></i> </a>

                <div className="dialog-title-wrapper">
                    <div className="dialog-main-title">Address Book</div>
                    <div className="dialog-second-title">edit entry</div>
                </div>

                {resource.update && (
                    <SuccessMessage className={"action-success-big"} text={translate("label.entry_updated")}
                                    onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {resource.error && (
                    <ErrorMessage className={"action-success-big"} text={resource.errorMessage}
                                  onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {!resource.isLoading && (
                    <form onKeyPress={this.submitForm}>

                        <React.Fragment>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label>Name*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_name} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label>Address*</label>
                                    <FieldGooglePlaces
                                        addClass="form-control"
                                        autoFocus
                                        handleInputChange={this.handleInputChange}
                                        setLocations={this.setLocations}
                                        stateFields={this.state.fields}
                                        placeholderText={"Enter Address Name"}
                                        {...this.state.fields.customer_address}
                                    />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>City*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_city} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>State*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_state} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Zip*</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_zip} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Phone</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_phone} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Phone 2</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_phone2} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Emergency Phone</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_emergency_phone} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Email</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_email} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Work hours</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_hours} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Contact Person</label>
                                    <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_contact} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Appointment</label>
                                    <FieldSelect addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_appointment}>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </FieldSelect>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label>Directions</label>
                                    <FieldTextarea addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_directions} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label>Description</label>
                                    <FieldTextarea addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_desc} />
                                </div>
                            </div>

                            <Button className="btn btn-brand mg-r-15" onClick={this.submit} text={"Update"}/>
                            <Button className="btn btn-brand" onClick={this.props.cancel} text={"Cancel"}/>
                        </React.Fragment>

                    </form>
                )}

                {resource.isLoading && (
                    <LoaderSmall addClass={"text-center"}/>
                )}

            </Dialog>
        )
    }

}
