import React, {Component} from "react";
import PublicLayout from "../../components/public-layout";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import LoaderSmall from "../../components/loader-small";
import ErrorMessage from "../../components/error-message";
import {Field, FieldsManager} from "../../data/services/fields";
import {resetPasswordConfirm} from "../../data/actions/user";
import FieldPassword from "../../components/field-password/index";
import Button from "../../components/button/index";

class ResetPasswordView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            no_match: false,
            fields: {
                password: new Field('password', '', ['empty']),
                password_confirm: new Field('password_confirm', '', ['empty']),
            },
            is_new: this.props.match.params && !!this.props.match.params.is_new
        };
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();

        const email = this.props.match.params && this.props.match.params.email;
        const token = this.props.match.params && this.props.match.params.token;
        this.setState({no_match: false, fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (this.state.fields.password.value === this.state.fields.password_confirm.value) {
                    this.props.dispatch(resetPasswordConfirm({
                        username: email,
                        token: token,
                        password: this.state.fields.password.value
                    }))
                } else {
                    this.setState({
                        no_match: true
                    })
                }
            }
        })
    };

    render() {
        const {translate} = this.props;

        return (
            <PublicLayout {...this.props}>
                <div className="col-lg-6 bg-light">
                    <div className="ht-100v d-flex align-items-center justify-content-center">
                        {!this.props.user.isLoading && !this.props.user.resetConfirm && (
                            <React.Fragment>

                                <form onKeyPress={this.submitForm}>
                                    {this.state.no_match && (
                                        <div className="form-msg-response font_danger">{translate("reset_password.no_match")}</div>
                                    )}

                                    <h3 className="tx-dark mg-b-5 tx-left">{this.state.is_new ? "CREATE PASSWORD": "RESET PASSWORD CONFIRM"}</h3>

                                    <div className="form-group">
                                        <label className="tx-gray-500 mg-b-5">Password</label>
                                        <FieldPassword
                                            addClass={"form-control"}
                                            onChange={this.handleInputChange} {...this.state.fields.password}
                                                       placeholder={""}/>
                                    </div>

                                    <div className="form-group">
                                        <label className="tx-gray-500 mg-b-5">Password confirm</label>
                                        <FieldPassword
                                            addClass={"form-control"}
                                            onChange={this.handleInputChange} {...this.state.fields.password_confirm}
                                                       placeholder={""}/>
                                    </div>

                                    <Button className="btn btn-brand btn-block" onClick={this.submit}
                                            text={this.state.is_new ? translate("btn.create_password") : translate("btn.reset_password")}/>
                                </form>
                            </React.Fragment>
                        )}

                        {this.props.user.resetConfirm && (
                            <React.Fragment>
                                <div className="">
                                    <h3 className="tx-dark mg-b-5 tx-left">You have successfully set new password.</h3>
                                    <div className="mg-t-10 tx-gray-500">
                                        <Link className="form-btn-submit" to={`/login`}>{"Back to login"}</Link>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                        {this.props.user.isLoading && (
                            <LoaderSmall/>
                        )}

                        {this.props.user.error && (
                            <ErrorMessage text={translate(this.props.user.errorMessage)}/>
                        )}
                    </div>
                </div>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(ResetPasswordView);