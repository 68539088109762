import React, {Component} from "react";
import {connect} from "react-redux";
import BasicLayout from "../../components/basic-layout";
import {Field, FieldsManager} from "../../data/services/fields";
import {getResource, resetResourceMessage, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {getProp} from "../../util/util";
import FieldText from "../../components/field-text";
import FieldTextarea from "../../components/field-textarea";
import Button from "../../components/button";
import NotificationSuccessMessage from "../../components/notification-success-message";
import NotificationErrorMessage from "../../components/notification-error-message";
import LoaderSmall from "../../components/loader-small";

class CompanyDetailsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {

            },
            loaded: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const info = getProp(nextProps, "resource.data", {});

        if (!prevState.loaded && !!info.company_address) {
            return {
                fields: {
                    company_name: new Field('company_name', info.company_name, ['empty']),
                    company_address: new Field('company_address', info.company_address, ['empty']),
                    company_description: new Field('company_description', info.company_description, ['empty']),
                    company_email: new Field('company_email', info.company_email, ['empty']),
                    company_phone: new Field('company_phone', info.company_phone, ['empty'])
                },
                loaded: true
            }
        }
        else return null;
    }

    componentDidUpdate = (prevProps) => {
        if (((!prevProps.resource.update) && (this.props.resource.update)) ||
            ((!prevProps.resource.error) && (this.props.resource.error))) {
            setTimeout(() => this.props.dispatch(resetResourceMessage()), 5000);
        }
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: Resources.CompanyDetails
        }))
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({
                            id: this.props.id,
                        }, FieldsManager.getFieldKeyValues(this.state.fields)
                    ),
                    resource: Resources.CompanyDetails
                }));
            }
        })
    };

    render() {
        const {translate, resource} = this.props;

        return (
            <BasicLayout {...this.props}>

                <div className="page-inner mg-t-20">

                    <div className="wrapper">

                        <div className="pageheader pd-t-15 pd-b-25">
                            <div className="d-flex justify-content-between">
                                <div className="clearfix">
                                    <h1 className="pd-0 mg-0 tx-26 tx-dark">Information</h1>
                                    <div className="breadcrumb pd-0 mg-0 bg-white">
                                        <span className="breadcrumb-item active">Company details</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {resource.isLoading  && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}

                        {!resource.isLoading && (
                        <div className="row row-xs clearfix">
                            <div className="col-md-12 col-lg-12">
                                <div className="card">

                                    <div className="card-body">
                                        <form onKeyPress={this.submitForm}>


                                                <React.Fragment>
                                                    <div className="form-row">

                                                        <div className="col-md-6 mb-3">
                                                            <label>Company Name</label>
                                                            <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.company_name} />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label>Company Address</label>
                                                            <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.company_address} />
                                                        </div>

                                                        <div className="col-md-6 mb-3">
                                                            <label>Company Phone</label>
                                                            <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.company_phone} />
                                                        </div>

                                                        <div className="col-md-6 mb-3">
                                                            <label>Company Email</label>
                                                            <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.company_email} />
                                                        </div>

                                                        <div className="col-md-6 mb-3">
                                                            <label>Company Description</label>
                                                            <FieldTextarea addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.company_description} />
                                                        </div>
                                                    </div>

                                                    <Button className="btn btn-brand" onClick={this.submit} text={"Edit details"}/>
                                                </React.Fragment>

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                        )}

                    </div>

                    <div className="notification-message-wrapper">
                        {resource.update && (
                            <NotificationSuccessMessage text={translate("label.company_info_updated")}
                                            onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        )}
                        {resource.error && (
                            <NotificationErrorMessage addClass={"action-notification"} text={translate(resource.errorMessage)}
                                          onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        )}
                    </div>

                </div>

            </BasicLayout>
        );
    }
}

export default connect(state => state)(CompanyDetailsView);