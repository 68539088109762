import React, {Component} from "react";

export default class FieldCheckbox extends Component {

    render() {
        return (
            <React.Fragment>
                <input type={"checkbox"}
                       className={this.props.addClass}
                       name={this.props.name}
                       checked={!!this.props.value && (this.props.value !== "0")}
                       onChange={(event) => {
                           this.props.onChange(this.props.name, !!event.target.checked);
                       }}
                       disabled={this.props.disabled}
                />
                {this.props.errorMessage && (
                    <div className={"invalid-feedback text-left"}>
                        {this.props.errorLabelMessage?this.props.errorLabelMessage:"Field required*"}
                    </div>
                )}
            </React.Fragment>
        )
    }
}