import React, {Component} from "react";
import {Field, FieldsManager} from "../../data/services/fields";
import Dialog from "./index";
import {hideAddSupportModal} from "../../data/actions/ui";
import FieldText from "../field-text";
import FieldTextarea from "../field-textarea";
import Button from "../button";
import {createResource, resetResourceMessage} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import FieldSelect from "../field-select";
import SuccessMessage from "../success-message";
import ErrorMessage from "../error-message";
import LoaderSmall from "../../components/loader-small";

export default class AddSupportDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                user_name: new Field('user_name', LocalStorage.get('user').Contact.user_fname + " " + LocalStorage.get('user').Contact.user_lname, ['empty']),
                user_email: new Field('user_email', LocalStorage.get('user').Contact.user_email, ['empty']),
                support_type: new Field('support_type', '', ['empty']),
                message: new Field('message', '', ['empty'])
            }
        };
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {

                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: FieldsManager.getFieldKeyValues(this.state.fields),
                    piggyResource: this.props.history.location.pathname === "/support"?Resources.Support:null,
                    resource: Resources.Support,
                    query: this.props.query
                }));
            }
        })
    };


    render() {
        const {resource} = this.props;

        return (
            <Dialog addClass="small-dialog" active={this.props.ui && this.props.ui.viewAddSupportDialog}>

                <a className="close-dialog" title="Close"
                   onClick={() => {
                       this.props.dispatch(
                           hideAddSupportModal()
                       )
                   }}
                ><i className="ti-close"></i> </a>

                <div className="dialog-title-wrapper">
                    <div className="dialog-main-title">Support</div>
                    <div className="dialog-second-title">write us a ticket</div>
                </div>

                {resource.error && (
                    <ErrorMessage className={"action-success-big"} text={resource.errorMessage}
                                  onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {resource.create && (
                    <React.Fragment>
                        <SuccessMessage className={"action-success-big"} text={"Support contacted! We will respond to your email."}
                                        hideClose={true}/>

                        <button
                            className="btn"
                            onClick={() => {
                                this.setState({
                                    fields: {
                                        user_name: new Field('user_name', LocalStorage.get('user').Contact.user_fname + " " + LocalStorage.get('user').Contact.user_lname, ['empty']),
                                        user_email: new Field('user_email', LocalStorage.get('user').Contact.user_email, ['empty']),
                                        support_type: new Field('support_type', '', ['empty']),
                                        message: new Field('message', '', ['empty'])
                                    }
                                }, () => this.props.dispatch(resetResourceMessage()))
                            }}
                        >
                            Send another ticket
                        </button>
                        <button
                            className="btn"
                            onClick={() => {
                                this.setState({
                                    fields: {
                                        user_name: new Field('user_name', LocalStorage.get('user').Contact.user_fname + " " + LocalStorage.get('user').Contact.user_lname, ['empty']),
                                        user_email: new Field('user_email', LocalStorage.get('user').Contact.user_email, ['empty']),
                                        support_type: new Field('support_type', '', ['empty']),
                                        message: new Field('message', '', ['empty'])
                                    }
                                });
                                this.props.dispatch(resetResourceMessage());
                                this.props.dispatch(
                                    hideAddSupportModal()
                                )
                            }}
                        >
                            Done
                        </button>

                    </React.Fragment>
                )}

                {!resource.isLoading && !resource.create && (
                    <form onKeyPress={this.submitForm}>

                        <div className="form-row">

                            <div className="col-md-12 mb-3">
                                <label>Full Name*</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_name} />
                            </div>

                            <div className="col-md-12 mb-3">
                                <label>Email*</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.user_email} />
                            </div>

                            <div className="col-md-12 mb-3">
                                <label>Ticket Type*</label>
                                <FieldSelect addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.support_type}>
                                    <option value={"General"}>General</option>
                                    <option value={"Navigation"}>Navigation</option>
                                    <option value={"Company"}>Company</option>
                                    <option value={"Other"}>Other</option>
                                </FieldSelect>
                            </div>

                            <div className="col-md-12 mb-3">
                                <label>Message*</label>
                                <FieldTextarea addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.message} />
                            </div>

                        </div>

                        <Button className="btn btn-brand" onClick={this.submit} text={"Submit ticket"}/>

                    </form>
                )}

                {resource.isLoading && (
                    <LoaderSmall addClass={"text-center"}/>
                )}

            </Dialog>

        )
    }
}
