import {combineReducers} from "redux";
import userReducer from "./user";
import resourceReducer from "./resource";
import secondResourceReducer from "./secondResource";
import uiReducer from "./ui";
import loadsReducer from "./loads";
import downloadReducer from "./download";
import { flashMessageReducer } from 'simple-redux-flash-message';

const appReducer = combineReducers({
    user: userReducer,
    resource: resourceReducer,
    ui: uiReducer,
    secondResource: secondResourceReducer,
    loads: loadsReducer,
    download: downloadReducer,
    flashMessage: flashMessageReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        return {
            ui: {},
            user: {},
            resource: {},
            secondResource: {},
            loads: {},
            download: {},
            flashMessage: {}
        }
    }
    return appReducer(state, action);
};

export default rootReducer;