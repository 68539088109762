import React, {useEffect, useRef, useState} from "react";

let autoComplete;

const componentForm = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "long_name",
    country: "long_name",
    postal_code: "short_name",
};

const defaultFields = {
    route: "AddressName",
    locality: "CityName",
    administrative_area_level_1: "State",
    country: "Country",
    postal_code: "PostalCode",
}

// Example output
// street_number:               2110
// route:                       Test Drive
// locality:                    Chattanooga
// administrative_area_level_1: TN
// country:                     United States
// postal_code:                 37421

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef, props) {
   autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,

        {types: ["geocode", "establishment"]}
    );

    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery, props)
    );
}

async function handlePlaceSelect(updateQuery, props) {
    const fields = props.fields ? props.fields : defaultFields;

    const place = autoComplete.getPlace();

    if (place === undefined || !place.address_components) {
        return false;
    }

    let street, streetNumber;

    const query = place.formatted_address;
    updateQuery(query);

    let stateFields = props.stateFields;
    let returnFields = {};

    if (place.name && stateFields.LegalName) {
        returnFields["LegalName"] = place.name;
    }

    if (place.name && stateFields.LocationName) {
        returnFields["LocationName"] = place.name;
    }

    if (place.name && stateFields.CompanyName) {
        returnFields["CompanyName"] = place.name;
    }

    for (const component of place.address_components) {
        const addressType = component.types[0];
        if (componentForm[addressType]) {
            const val = component[componentForm[addressType]];

            if (addressType === 'postal_code' && val) {
                returnFields[fields[addressType]] = val;
            }

            if (addressType === 'locality' && val) {
                returnFields[fields[addressType]] = val;
            }

            if (addressType === 'administrative_area_level_1' && val) {
                returnFields[fields[addressType]] = val;
            }

            if (addressType === 'route' && val) {
                street = val;
            }

            if (addressType === 'street_number' && val) {
                streetNumber = val;
            }
        }
    }

    if (street) {
        returnFields["AddressName"] = (streetNumber ? streetNumber + " " : "") + street;
    }

    props.setLocations(returnFields)
}

function FieldGooglePlaces(props) {

    const [query, setQuery] = useState(props.value || "");
    const [initialFocus, setInitialFocus] = useState(false);
    const autoCompleteRef = useRef(null);

    useEffect(() => {
        setQuery(props.value)
    }, [props.value])

    const cleanUp = () => {
        let autoCompleteContainer = document.querySelector('.pac-container.pac-logo');

        if (autoCompleteContainer) {
            autoCompleteContainer.remove();
            setQuery("");
        }
    }

    useEffect(() => {
        if (!initialFocus) return;

        if (props.autocompleteQuery) {
            setQuery(props.autocompleteQuery)
        }

        cleanUp();

        if (autoCompleteRef) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,

                () => handleScriptLoad(setQuery, autoCompleteRef, props)
            );
        }

        if (props.autoFocus && autoCompleteRef) {
            autoCompleteRef.current.focus();
        }

        return () => {
            cleanUp();
        }
    }, [initialFocus]);

    return (
        <div className="search-location-input">
            <input
                onFocus={() => setInitialFocus(true)}
                className={props.addClass + (props.errorMessage?" is-invalid":"")}
                ref={autoCompleteRef}
                onChange={event => {
                    setQuery(event.target.value)
                    props.handleInputChange(props.name, event.target.value)
                }}
                placeholder={props.placeholderText}
                value={query}
            />
            {props.errorMessage && (
                <div className={"invalid-feedback"}>
                    {props.errorLabelMessage?props.errorLabelMessage:"Field required*"}
                </div>
            )}
        </div>
    );
}

export default FieldGooglePlaces;
