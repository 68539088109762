import React, {Component} from "react";
import {connect} from "react-redux";
import BasicLayout from "../../components/basic-layout";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {deleteResource, getResource, resetResourceMessage} from "../../data/actions/resource";
import {getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import LoaderSmall from "../../components/loader-small";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import NotificationSuccessMessage from "../../components/notification-success-message";
import NotificationErrorMessage from "../../components/notification-error-message";
import AddCarrierDialog from "../../components/dialog/add-carrier";
import EditCarriersDialog from "../../components/dialog/edit-carrier";
import Pagination from "../../components/pagination";
import FieldText from "../../components/field-text";
import NoResults from "../../components/no-results";
import Dialog from "../../components/dialog/index";
import FieldSelect from "../../components/field-select";
import {downloadExcelReport} from "../../data/actions/data";

class CarriersView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                query: new Field("query", "", []),
                archived: new Field("archived", 'no', [])
            },
            sort: "ASC",
            sortBy: "carrier_name",
            offset: 0,
            limit: 15,
            paginationPage: 1,
            deleteModal: false,
            editCarriersModal: false,
            addCarrierModal: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevState.offset !== this.state.offset)
            || (prevState.sort !== this.state.sort)
            || (prevState.sortBy !== this.state.sortBy)
        ) {
            this.fetchData();
        }
    };

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                offset: this.state.offset,
                limit: this.state.limit,
                archived: this.state.fields.archived.value
            },
            resource: Resources.Carriers
        }))
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, () => {
            this.fetchData();
        });
    };

    updateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        });
    };

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy)?(this.state.sort==="ASC"?"DESC":"ASC"):"ASC"
        })
    };

    toggleDeleteModal = (submit) => {
        this.setState({
            deleteModal: submit
        })
    };

    toggleAddCarrierModal = () => {
        this.setState({
            addCarrierModal: !this.state.addCarrierModal
        })
    };
    
    toggleEditCarriersModal = (item) => {
        this.setState({
            carrierItem: item,
            editCarriersModal: !this.state.editCarriersModal
        })
    };

    downloadExcel = () => {
        this.props.dispatch(downloadExcelReport({
            user: LocalStorage.get("user"),
            query: {
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                archived: this.state.fields.archived.value,
                name: "activtrack_carriers.xlsx"
            },
            resource: Resources.CarriersExport
        }));
    };

    render() {
        const {resource, translate, download} = this.props;

        const data = getProp(resource, "data.list", []).map((item,i) => (
            <tr key={i}>
                <td>{item.carrier_name}</td>
                <td>{item.carrier_city}</td>
                <td>{item.carrier_state}</td>
                <td>{item.carrier_zip}</td>
                <td>{item.carrier_address}</td>

                <td>{item.carrier_phone}</td>
                <td>{item.carrier_email}</td>

                <td>{item.carrier_mc}</td>
                <td>{item.carrier_ff}</td>
                <td>{item.carrier_dot}</td>
                <td>{item.carrier_scac}</td>

                <td className="text-center">
                    <span className="icon-btn" title={"Edit"}
                          onClick={() => this.toggleEditCarriersModal(item)}
                    >
                        <i className="ti-pencil-alt tx-24 mg-r-10"></i>
                    </span>

                    {!item.archived_date && (
                        <span className="icon-btn" title={"Archive"}
                              onClick={() => this.toggleDeleteModal(() => {
                                  this.props.dispatch(deleteResource({
                                      user: LocalStorage.get('user'),
                                      query: {
                                          id: item.carrier_id,
                                          sort: this.state.sort,
                                          sortBy: this.state.sortBy,
                                          query: this.state.fields.query.value,
                                          offset: this.state.offset,
                                          limit: this.state.limit,
                                          archived: this.state.fields.archived.value
                                      },
                                      resource: Resources.Carriers,
                                      piggyResource: Resources.Carriers
                                  }));
                              })}
                        >
                        <i className="ti-archive tx-24 mg-r-15"></i>
                    </span>
                    )}
                </td>
            </tr>
        ));

        return (
            <BasicLayout {...this.props} query={{
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                offset: this.state.offset,
                limit: this.state.limit,
                archived: this.state.fields.archived.value
            }}>

                <div className="page-inner mg-t-20">

                    <div className="wrapper">

                        <div className="pageheader pd-t-15 pd-b-25">
                            <div className="d-flex justify-content-between">
                                <div className="clearfix">
                                    <h1 className="pd-0 mg-0 tx-26 tx-dark">Carriers</h1>
                                    <div className="breadcrumb pd-0 mg-0 bg-white">
                                        <span className="breadcrumb-item active"> - </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">

                            <div className="input-group col-sm-3 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Search
                                    </span>
                                </div>
                                <FieldText addClass={"form-control"} onChange={this.handleInputChange} {...this.state.fields.query} />
                            </div>

                            <div className="input-group col-sm-2 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Show Archived
                                    </span>
                                </div>
                                <FieldSelect addClass={"form-control"} onChange={this.handleInputChange}
                                             {...this.state.fields.archived}>
                                    <option value={"no"}>No</option>
                                    <option value={"yes"}>Yes</option>
                                    <option value={"all"}>All</option>
                                </FieldSelect>
                            </div>

                            <div className="input-group col-sm-6 mb-3">
                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.toggleAddCarrierModal()}>
                                    Add New Carrier
                                </button>
                                <React.Fragment>
                                    {!download.isLoading && (
                                        <div className={"general-btn-icon"}
                                             onClick={this.downloadExcel}
                                        >
                                            <img alt="" className={"not-active"} title={translate("btn.export_as_excel")} src="/images/buttons/excel.png"/>
                                            <img alt="" className={"active"} title={translate("btn.export_as_excel")} src="/images/buttons/excel-active.png"/>
                                        </div>
                                    )}

                                    {download.isLoading && (
                                        <LoaderSmall/>
                                    )}
                                </React.Fragment>
                            </div>

                        </div>

                        <div className="row row-xs clearfix">
                            <div className="col-md-12 col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-dark table-striped table-hover load-table">
                                        <thead>
                                        <tr>
                                            <th title={this.state.sortBy === "carrier_name"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("carrier_name")}>
                                                {translate("table.name")}
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "carrier_name"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "carrier_city"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("carrier_city")}>
                                                {translate("table.city")}
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "carrier_city"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "carrier_state"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("carrier_state")}>
                                                {translate("table.state")}
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "carrier_state"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "carrier_zip"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("carrier_zip")}>
                                                {translate("table.zip")}
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "carrier_zip"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "carrier_address"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("carrier_address")}>
                                                {translate("table.address")}
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "carrier_address"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "carrier_phone"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("carrier_phone")}>
                                                {translate("table.phone")}
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "carrier_phone"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "carrier_email"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("carrier_email")}>
                                                {translate("table.email")}
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "carrier_email"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "carrier_mc"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("carrier_mc")}>
                                                {translate("table.carrier_mc")}
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "carrier_mc"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "carrier_ff"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("carrier_ff")}>
                                                {translate("table.carrier_ff")}
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "carrier_ff"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "carrier_dot"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("carrier_dot")}>
                                                {translate("table.carrier_dot")}
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "carrier_dot"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "carrier_scac"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("carrier_scac")}>
                                                {translate("table.carrier_scac")}
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "carrier_scac"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th className="text-center">{translate("table.actions")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!resource.isLoading && data}
                                        </tbody>
                                    </table>
                                </div>
                                {(data.length > 0) && (
                                    <Pagination
                                        updateOffset={this.updateOffset}
                                        paginationButtonLimit={5}
                                        limit={this.state.limit}
                                        offset={this.state.offset}
                                        count={ getProp(resource, "data.count", 0)}
                                        paginationPage={this.state.paginationPage}
                                    />
                                )}
                                {resource.isLoading  && (
                                    <LoaderSmall addClass={"text-center"}/>
                                )}
                                {data.length < 1 && !resource.isLoading && (
                                    <NoResults text={this.props.translate("text.no_result")}/>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="notification-message-wrapper">
                        {resource.update && (
                            <NotificationSuccessMessage text={"Address Book entry updated."}
                                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        )}
                        {resource.deleteResource && (
                            <NotificationSuccessMessage icon={"ti-archive"} text={"Address Book entry archived."}
                                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        )}
                        {resource.error && (
                            <NotificationErrorMessage addClass={"action-notification"} text={translate(resource.errorMessage)}
                                                      onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        )}
                    </div>
                </div>

                <ConfirmDialog
                    second_title={"Archive entry"}
                    text={this.props.translate("message.are_you_sure_archive_contact")}
                    submit={this.state.deleteModal}
                    cancel={this.toggleDeleteModal}
                    visible={this.state.deleteModal} {...this.props}
                />

                {this.state.addCarrierModal && (
                    <AddCarrierDialog
                        cancel={this.toggleAddCarrierModal}
                        visible={this.state.addCarrierModal}
                        {...this.props}
                        query={{
                            sort: this.state.sort,
                            sortBy: this.state.sortBy,
                            query: this.state.fields.query.value,
                            offset: this.state.offset,
                            limit: this.state.limit,
                            archived: this.state.fields.archived.value
                        }}
                    />
                )}
                
                {this.state.editCarriersModal && (
                    <EditCarriersDialog
                        cancel={this.toggleEditCarriersModal}
                        value={this.state.carrierItem}
                        visible={this.state.editCarriersModal}
                        {...this.props}
                        query={{
                            sort: this.state.sort,
                            sortBy: this.state.sortBy,
                            query: this.state.fields.query.value,
                            offset: this.state.offset,
                            limit: this.state.limit,
                            archived: this.state.fields.archived.value
                        }}
                    />
                )}

                <Dialog active={this.state.showMap} addClass={"large-dialog"}>
                    <a className="close-dialog" title="Close"
                       onClick={() => {
                           this.setState({
                               showMap: null
                           });
                       }}
                    ><i className="ti-close">
                    </i></a>
                    <div id="map" className="addressMap">
                    </div>
                </Dialog>
            </BasicLayout>
        );
    }
}

export default connect(state => state)(CarriersView);