import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {doneDownloadExcelReport, errorDownloadExcelReport} from "../actions/data";

export function* downloadExcelReport(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.downloadExcelReport, user, action.data.resource, action.data.query);

    if (result && result.status === 0) {
        yield put(doneDownloadExcelReport());
    } else {
        yield put(errorDownloadExcelReport());
    }
}

export function* watchDownloadExcelReport() {
    yield takeLatest('DOWNLOAD_EXCEL_REPORT', downloadExcelReport);
}