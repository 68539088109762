import React, {Component} from "react";
import {connect} from "react-redux";
import BasicLayout from "../../components/basic-layout";
import LoaderSmall from "../../components/loader-small";
import FieldText from "../../components/field-text";
import FieldSelect from "../../components/field-select";
import Button from "../../components/button";
import {getResource, resetResourceMessage, updateResource} from "../../data/actions/resource";
import Resources from "../../data/services/resources";
import LocalStorage from "../../util/localStorage";
import {Field, FieldsManager} from "../../data/services/fields";
import {getProp} from "../../util/util";
import NotificationSuccessMessage from "../../components/notification-success-message";
import NotificationErrorMessage from "../../components/notification-error-message";
import {updateUserInfo} from "../../data/actions/user";

class FaqView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {

            },
            loaded: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const info = getProp(nextProps, "user.data.Contact", {});

        if (!prevState.loaded && !!info.user_fname) {
            return {
                fields: {
                    user_fname: new Field('user_fname', info.user_fname, ['empty']),
                    user_lname: new Field('user_lname', info.user_lname, ['empty']),
                    user_email: new Field('user_email', info.user_email, ['email']),
                    user_phone: new Field('user_phone', info.user_phone, ['empty']),
                    user_zone: new Field('user_zone', info.user_zone, ['empty']),
                    user_datetimeformat: new Field('user_datetimeformat', info.user_datetimeformat, ['empty']),
                },
                loaded: true
            }
        }
        else return null;
    }

    componentDidUpdate = (prevProps) => {
        if (((!prevProps.resource.update) && (this.props.resource.update)) ||
            ((!prevProps.resource.error) && (this.props.resource.error))) {
            setTimeout(() => this.props.dispatch(resetResourceMessage()), 5000);
        }
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: Resources.User
        }))
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({
                            id: this.props.id,
                        }, FieldsManager.getFieldKeyValues(this.state.fields)
                    ),
                    resource: Resources.User
                }));

                const user = Object.assign({}, LocalStorage.get('user'), {
                    Contact: Object.assign(LocalStorage.get('user')['Contact'], FieldsManager.getFieldKeyValues(this.state.fields))
                });
                this.props.dispatch(updateUserInfo(user));
                LocalStorage.set('user', user);
            }
        })
    };

    render() {
        const {translate, resource} = this.props;

        return (
            <BasicLayout {...this.props}>

                <div className="page-inner mg-t-20">

                    <div className="wrapper">

                        <div className="pageheader pd-t-15 pd-b-25">
                            <div className="d-flex justify-content-between">
                                <div className="clearfix">
                                    <h1 className="pd-0 mg-0 tx-26 tx-dark">FAQ</h1>
                                    <div className="breadcrumb pd-0 mg-0 bg-white">
                                        <span className="breadcrumb-item active">Things you need to know</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {resource.isLoading  && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}

                        {!resource.isLoading && (
                            <div className="row row-xs clearfix">
                                <div className="col-md-12 col-lg-12">

                                    {/*
                                    <h6 className="accordion-title">Lorem Ipsum is simply dummy text of the
                                        printing</h6>
                                    <div className="accordion-body">Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and
                                        scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining
                                        essentially unchanged. It was popularised in the 1960s with the release of
                                        Letraset sheets containing Lorem Ipsum passages, and more recently with
                                        desktop publishing software like Aldus PageMaker including versions of Lorem
                                        Ipsum.
                                    </div>
                                    <h6 className="accordion-title">Lorem Ipsum is simply dummy text of the
                                        printing</h6>
                                    <div className="accordion-body">Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and
                                        scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining
                                        essentially unchanged. It was popularised in the 1960s with the release of
                                        Letraset sheets containing Lorem Ipsum passages, and more recently with
                                        desktop publishing software like Aldus PageMaker including versions of Lorem
                                        Ipsum.
                                    </div>
                                    <h6 className="accordion-title">Lorem Ipsum is simply dummy text of the
                                        printing</h6>
                                    <div className="accordion-body">Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and
                                        scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining
                                        essentially unchanged. It was popularised in the 1960s with the release of
                                        Letraset sheets containing Lorem Ipsum passages, and more recently with
                                        desktop publishing software like Aldus PageMaker including versions of Lorem
                                        Ipsum.
                                    </div>
                                    <div className="accordion-body">Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and
                                        scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining
                                        essentially unchanged. It was popularised in the 1960s with the release of
                                        Letraset sheets containing Lorem Ipsum passages, and more recently with
                                        desktop publishing software like Aldus PageMaker including versions of Lorem
                                        Ipsum.
                                    </div>
                                    <div className="accordion-body">Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and
                                        scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining
                                        essentially unchanged. It was popularised in the 1960s with the release of
                                        Letraset sheets containing Lorem Ipsum passages, and more recently with
                                        desktop publishing software like Aldus PageMaker including versions of Lorem
                                        Ipsum.
                                    </div>
                                    <div className="accordion-body">Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and
                                        scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining
                                        essentially unchanged. It was popularised in the 1960s with the release of
                                        Letraset sheets containing Lorem Ipsum passages, and more recently with
                                        desktop publishing software like Aldus PageMaker including versions of Lorem
                                        Ipsum.
                                    </div>
                                    <div className="accordion-body">Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and
                                        scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining
                                        essentially unchanged. It was popularised in the 1960s with the release of
                                        Letraset sheets containing Lorem Ipsum passages, and more recently with
                                        desktop publishing software like Aldus PageMaker including versions of Lorem
                                        Ipsum.
                                    </div>
                                    */}

                                    <h6 className="accordion-title">Coming Soon!</h6>

                                </div>
                            </div>
                        )}


                    </div>

                </div>

            </BasicLayout>
        );
    }
}

export default connect(state => state)(FaqView);