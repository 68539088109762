import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {
    doneCreateLoad, doneDeleteLoad, doneGetLoads, errorCreateLoad, errorDeleteLoad,
    errorGetLoads
} from "../actions/loads";
import Resources from "../services/resources";

export function *getLoadsCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, Resources.Loads);

    if (result && result.status === 0) {
        yield put(doneGetLoads(result.data));
    } else {
        yield put(errorGetLoads(result.data));
    }
}

export function* watchGetLoads() {
    yield takeLatest('GET_LOADS', getLoadsCall);
}

export function* createLoadCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, Resources.Loads);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetLoads(resPiggy.data));
                yield put(doneCreateLoad(result.data));
            }
        } else {
            yield put(doneCreateLoad(result.data));
        }
    } else {
        yield put(errorCreateLoad(result.data));
    }
}

export function* watchCreateLoad() {
    yield takeLatest('CREATE_LOAD', createLoadCall);
}

export function* deleteLoadCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetLoads(resPiggy.data));
                yield put(doneDeleteLoad(result.data));
            }
        } else {
            yield put(doneDeleteLoad(result.data));
        }
    } else {
        yield put(errorDeleteLoad(result.data));
    }
}

export function* watchDeleteLoad() {
    yield takeLatest('DELETE_LOAD', deleteLoadCall);
}