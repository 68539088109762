import React, {Component} from "react";
import Dialog from "./index";
import {toFrontDateTimeNoUTCFormat} from "../../util/util";
import LoaderSmall from "../../components/loader-small";
import NoResults from "../../components/no-results";

export default class ReviewsCustomersDialog extends Component {

    render() {

        const reviews = this.props.list.map((item) => {
            return (
                <React.Fragment>
                    <tr>
                        <td className="text-center">
                            {toFrontDateTimeNoUTCFormat(item.review_date)}
                        </td>
                        <td className="text-center">
                            {item.user_fname + " " + item.user_lname}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            {item.review_note}
                        </td>
                    </tr>
                </React.Fragment>
            )
        });

        return (
            <Dialog active={this.props.visible} addClass={"large-dialog"}>

                <a className="close-dialog" title="Close"
                   onClick={() => {
                       this.props.cancel();
                   }}
                ><i className="ti-close"></i> </a>

                <div className="dialog-title-wrapper">
                    <div className="dialog-main-title">{this.props.translate("dialog.heading.customer_reviews")}</div>
                    <div className="dialog-second-title">{this.props.reviewSubject.customer_name}</div>
                </div>
                <div className="dialog-text-wrapper">
                    <div className="col-md-12 col-lg-12">
                        <button type="button" className="btn btn-secondary btn-sm bottom-margin"
                                onClick={this.props.toggleAddReviewModal}>
                            Add New Review
                        </button>
                        <div className="table-responsive">
                            <table className="table table-dark table-striped table-hover load-table">
                                <thead>
                                    <tr>
                                        <th className="text-center">Date and Time</th>
                                        <th className="text-center">User</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {!this.props.secondResource.isLoading && reviews}
                                </tbody>
                            </table>
                        </div>
                        {this.props.secondResource.isLoading  && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}
                        {reviews.length < 1 && !this.props.secondResource.isLoading && (
                            <NoResults text={this.props.translate("text.no_result")}/>
                        )}
                    </div>
                </div>
            </Dialog>
        )
    }
}