import React, {Component} from "react";
import {connect} from "react-redux";
import BasicLayout from "../../components/basic-layout";
import Resources from "../../data/services/resources";
import {Field, FieldsManager} from "../../data/services/fields";
import {deleteResource, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {getProp, toFrontDateTime, toFrontDateTimeNoSec} from "../../util/util";
import LoaderSmall from "../../components/loader-small";
import Pagination from "../../components/pagination";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import EditCompanyUserDialog from "../../components/dialog/edit-companyUser";
import FieldText from "../../components/field-text";
import NoResults from "../../components/no-results";
import AddCompanyUserDialog from "../../components/dialog/add-companyUser";
import FieldSelect from "../../components/field-select";

class CompanyUsersView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sort: "ASC",
            sortBy: "user_fname",
            offset: 0,
            limit: 15,
            paginationPage: 1,
            fields: {
                query: new Field("query", "", []),
                archived: new Field("archived", 'no', [])
            },
            deleteModal: false,
            editCompanyUserModal: false,
            addCompanyUserModal: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevState.offset !== this.state.offset)
            || (prevState.sort !== this.state.sort)
            || (prevState.sortBy !== this.state.sortBy)
        ) {
            this.fetchData();
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, () => {
            this.fetchData();
        });
    };

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                sort: this.state.sort,
                sortBy: this.state.sortBy,
                query: this.state.fields.query.value,
                offset: this.state.offset,
                limit: this.state.limit,
                archived: this.state.fields.archived.value
            },
            resource: Resources.CompanyUsers
        }))
    };

    updateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        });
    };

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy)?(this.state.sort==="ASC"?"DESC":"ASC"):"ASC"
        })
    };

    toggleDeleteModal = (submit) => {
        this.setState({
            deleteModal: submit
        })
    };

    toggleEditCompanyUserModal = (item) => {
        this.setState({
            companyUserItem: item,
            editCompanyUserModal: !this.state.editCompanyUserModal
        })
    };

    toggleAddCompanyUserModal = () => {
        this.setState({
            addCompanyUserModal: !this.state.addCompanyUserModal
        })
    };

    render() {
        const {translate, resource} = this.props;

        const data = getProp(resource, "data.list", []).map((item,i) => (
            <tr key={i}>
                <td>{item.user_fname} {item.user_lname}</td>
                <td>{item.user_email}</td>
                <td>{item.user_phone}</td>
                <td>{item.role_name}</td>
                <td>{item.user_zone}</td>
                <td>{toFrontDateTimeNoSec(item.user_last_login)}</td>
                <td>{toFrontDateTimeNoSec(item.user_modified)}</td>
                <td className="table-actions text-center">
                    <span className="icon-btn" title={"Edit"}
                          onClick={() => this.toggleEditCompanyUserModal(item)}
                    >
                        <i className="ti-pencil-alt tx-24 mg-r-15"></i>
                    </span>

                    {!item.archived_date && (
                    <span className="icon-btn" title={"Archive"}
                          onClick={() => this.toggleDeleteModal(() => {
                              this.props.dispatch(deleteResource({
                                  user: LocalStorage.get('user'),
                                  query: {
                                      id: item.user_id
                                  },
                                  resource: Resources.CompanyUsers,
                                  piggyResource: Resources.CompanyUsers
                              }));
                          })}
                    >
                        <i className="ti-archive tx-24"></i>
                    </span>
                    )}
                </td>
            </tr>
        ));

        return (
            <BasicLayout {...this.props}>

                <div className="page-inner mg-t-20">

                    <div className="wrapper">

                        <div className="pageheader pd-t-15 pd-b-25">
                            <div className="d-flex justify-content-between">
                                <div className="clearfix">
                                    <h1 className="pd-0 mg-0 tx-26 tx-dark">Users</h1>
                                    <div className="breadcrumb pd-0 mg-0 bg-white">
                                        <span className="breadcrumb-item active">Company users</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-group col-sm-3 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Search
                                    </span>
                                </div>
                                <FieldText addClass={"form-control"} onChange={this.handleInputChange} {...this.state.fields.query} />
                            </div>

                            <div className="input-group col-sm-2 mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Show Archived
                                    </span>
                                </div>
                                <FieldSelect addClass={"form-control"} onChange={this.handleInputChange}
                                             {...this.state.fields.archived}>
                                    <option value={"no"}>No</option>
                                    <option value={"yes"}>Yes</option>
                                    <option value={"all"}>All</option>
                                </FieldSelect>
                            </div>

                            <div className="input-group col-sm-3 mb-3">
                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.toggleAddCompanyUserModal()}>
                                    Add New User
                                </button>
                            </div>

                        </div>

                        <div className="row clearfix">
                            <div className="col-md-12 col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-dark table-striped table-hover load-table">
                                        <thead>
                                        <tr>
                                            <th title={this.state.sortBy === "user_fname"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("user_fname")}>
                                                User
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "user_fname"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "user_email"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("user_email")}>
                                                Email
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "user_email"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "user_phone"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("user_phone")}>
                                                Phone
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "user_phone"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "user_role"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("user_role")}>
                                                Role
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "user_role"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "user_zone"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("user_zone")}>
                                                Zone
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "user_zone"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "user_last_login"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("user_last_login")}>
                                                Last login
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "user_last_login"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th title={this.state.sortBy === "user_modified"?(this.state.sort === "DESC"?"Descending":"Ascending"):"Sort by this column"} className={"has-cursor"} onClick={() => this.updateSort("user_modified")}>
                                                Modified
                                                <i className={"mg-l-5 tx-15 fa fa-" + (this.state.sortBy === "user_modified"?(this.state.sort === "DESC"?"sort-amount-desc":"sort-amount-asc"):"sort")}></i>
                                            </th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {!resource.isLoading && data}
                                        </tbody>
                                    </table>
                                </div>
                                {data.length > 0 && !resource.isLoading && (
                                    <Pagination
                                        updateOffset={this.updateOffset}
                                        paginationButtonLimit={5}
                                        limit={this.state.limit}
                                        offset={this.state.offset}
                                        count={ getProp(resource, "data.count", 0)}
                                        paginationPage={this.state.paginationPage}
                                    />
                                )}
                                {resource.isLoading  && (
                                    <LoaderSmall addClass={"text-center"}/>
                                )}
                                {data.length < 1 && !resource.isLoading && (
                                    <NoResults text={this.props.translate("text.no_result")}/>
                                )}
                            </div>
                        </div>

                    </div>

                </div>

                {this.state.editCompanyUserModal && (
                    <EditCompanyUserDialog
                        cancel={this.toggleEditCompanyUserModal}
                        value={this.state.companyUserItem}
                        visible={this.state.editCompanyUserModal}
                        {...this.props}
                    />

                )}

                {this.state.addCompanyUserModal && (
                    <AddCompanyUserDialog
                        cancel={this.toggleAddCompanyUserModal}
                        visible={this.state.addCompanyUserModal}
                        {...this.props}
                    />

                )}

                <ConfirmDialog
                    second_title={"Archive user"}
                    text={this.props.translate("message.are_you_sure_archive_user")}
                    submit={this.state.deleteModal}
                    cancel={this.toggleDeleteModal}
                    btnText={"Archive"}
                    visible={this.state.deleteModal} {...this.props}/>


            </BasicLayout>
        );
    }
}

export default connect(state => state)(CompanyUsersView);