import React, {Component} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route as NeutralRoute, Router, Switch} from "react-router-dom";
import Loads from "./views/loads";
import AddressBook from "./views/addressBook";
import Carriers from "./views/carriers";
import CompanyDetails from "./views/companyDetails";
import CompanyDrivers from "./views/companyDrivers";
import CompanyUsers from "./views/companyUsers";
import Profile from "./views/profile";
import Faq from "./views/faq";
import Login from "./views/login";
import ResetPassword from "./views/reset-password";
import ResetPasswordConfirm from "./views/reset-password-confirm";
import LocalStorage from "./util/localStorage";
import NotFoundView from "./views/not-found";
import {hideModals} from "./data/actions/ui";
import IdleTimer from "react-idle-timer";
import {logout} from "./data/actions/user";
import LoadDetailsView from "./views/load-details";
import PropsReceiver from "./propReceiver";

const PrivateRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

const PublicRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/routes",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);


class IdleModal extends Component {

    constructor(props) {
        super(props);
        this.unlisten = null;
        this.state = {
            inactiveModal: false
        }
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            this.props.dispatch(hideModals());
        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render() {

        return (
            <React.Fragment>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={(e) => {
                    }}
                    onIdle={(e) => {
                        this.setState({
                            inactiveModal: LocalStorage.get('user')
                        });
                    }}
                    onAction={(e) => {
                    }}
                    debounce={250}
                    timeout={1000 * 60 * 60 * 3600} />
                {this.state.inactiveModal && (
                    <div className="dialog-background black-background">
                        <div className={`dialog-container`}>
                            <h4>
                            {this.props.translate("dialog.heading.session_expired")}
                            </h4>

                            <h5 className="margin-bottom-30">
                                {this.props.translate("text.session_expired")}
                            </h5>
                            <div
                                className="general-btn"
                                onClick={() => {
                                this.setState({
                                    inactiveModal: false
                                }, () => {
                                    this.props.dispatch(logout({}));
                                    this.props.history.push("/");
                                });
                            }}>{this.props.translate("btn.relogin")}</div>
                        </div>
                    </div>
                )}
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default (store, translator) => {
    function getComponent(Component, props) {
        return (
            <Component {...props} translate={(key, args) => translator.translate(key, args)}/>
        );
    }

    const history = createBrowserHistory();
    store.history = history;

    return (
        <Router history={history}>
            <Switch>
                <PublicRoute exact path="/" component={(props) => getComponent(Login, props)}/>
                <PublicRoute exact path="/login" component={(props) => getComponent(Login, props)}/>
                <PublicRoute exact path="/reset-password"
                             component={(props) => getComponent(ResetPassword, props)}/>
                <NeutralRoute exact path="/reset-password-confirm/:email/:token"
                              component={(props) => getComponent(ResetPasswordConfirm, props)}/>
                <NeutralRoute exact path="/reset-password-confirm/:email/:token/:is_new"
                              component={(props) => getComponent(ResetPasswordConfirm, props)}/>
                <PrivateRoute exact path="/home" component={(props) => getComponent(Loads, props)}/>
                <PrivateRoute exact path="/routes" component={(props) => getComponent(Loads, props)}/>
                <PrivateRoute exact path="/route/:id" component={(props) => getComponent(LoadDetailsView, props)}/>
                <PrivateRoute exact path="/addressBook" component={(props) => getComponent(AddressBook, props)}/>
                <PrivateRoute exact path="/carriers" component={(props) => getComponent(Carriers, props)}/>

                <PrivateRoute exact path="/companyDetails" component={(props) => getComponent(CompanyDetails, props)}/>
                <PrivateRoute exact path="/companyUsers" component={(props) => getComponent(CompanyUsers, props)}/>
                <PrivateRoute exact path="/companyDrivers" component={(props) => getComponent(CompanyDrivers, props)}/>

                <PrivateRoute exact path="/profile" component={(props) => getComponent(Profile, props)}/>
                <PrivateRoute exact path="/faq" component={(props) => getComponent(Faq, props)}/>

                <NeutralRoute component={(props) => getComponent(NotFoundView, props)}/>
            </Switch>

            <IdleModal
                history={history} dispatch={store.dispatch}
                translate={(key, args) => translator.translate(key, args)}/>

            <PropsReceiver
                history={history}
                translate={(key, args) => translator.translate(key, args)}
            />
        </Router>
    );
}