import React, {Component} from "react";
import Async from "react-select/async";

export default class FieldDropdownSelect extends Component {

    render() {

        return (
            <React.Fragment>
                <Async
                    className={(this.props.addClass ? this.props.addClass : '')}
                    disabled={this.props.disabled}
                    onChange={(selectedOption) => {
                        this.props.onChange(this.props.name, selectedOption);
                    }}
                    name={this.props.name}
                    loadOptions={this.props.loadOptions}
                    isMulti={!!this.props.isMulti}
                    cacheOptions={!!this.props.cacheOptions}
                    defaultOptions={!!this.props.defaultOptions}
                    value={this.props.value}
                />
                {this.props.errorMessage && (
                    <div className="field-error-text">
                        <img src="/images/forms/Error.png" alt="error icon" title={`${this.props.errorMessage}`}/>
                    </div>
                )}
            </React.Fragment>
        )
    }
}