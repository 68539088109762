import React, {Component} from "react";
import {Field, FieldsManager} from "../../data/services/fields";
import Dialog from "./index";
import {
    hideAddAddressBookModal, minimizeAddAddressBookModal,
    unminimizeAddAddressBookModal
} from "../../data/actions/ui";
import FieldText from "../field-text";
import FieldTextarea from "../field-textarea";
import Button from "../button";
import {createResource, resetResourceMessage} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import FieldSelect from "../field-select";
import SuccessMessage from "../success-message";
import ErrorMessage from "../error-message";
import LoaderSmall from "../../components/loader-small";
import FieldGooglePlaces from "../field-google-places";

export default class AddAddressBookDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields()
        };
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {

                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: FieldsManager.getFieldKeyValues(this.state.fields),
                    piggyResource: this.props.history.location.pathname === "/addressBook"?Resources.AddressBook:null,
                    resource: Resources.AddressBook,
                    query: !!this.props.ui.viewAddAddressBookDialog.query?this.props.ui.viewAddAddressBookDialog.query:{}
                }));
            }
        })
    };

    setLocations = (fields) => {
        let stateFields = this.state.fields
        stateFields.customer_zip.value = fields.PostalCode
        stateFields.customer_city.value = fields.CityName
        stateFields.customer_state.value = fields.State
        this.setState({fields: stateFields})
    };

    getFields = () => {
        return {
            customer_name: new Field('customer_name', '', ['empty']),
            customer_address: new Field('customer_address', '', ['empty']),
            customer_city: new Field('customer_city', '', ['empty']),
            customer_state: new Field('customer_state', '', ['empty']),
            customer_zip: new Field('customer_zip', '', ['empty']),
            customer_phone: new Field('customer_phone', '', []),
            customer_phone2: new Field('customer_phone2', '', []),
            customer_emergency_phone: new Field('customer_emergency_phone', '', []),
            customer_email: new Field('customer_email', '', []),
            customer_hours: new Field('customer_hours', '', []),
            customer_contact: new Field('customer_contact', '', []),
            customer_appointment: new Field('customer_appointment', '', []),
            customer_directions: new Field('customer_directions', '', []),
            customer_desc: new Field('customer_desc', '', []),
        }
    }

    render() {
        const {resource} = this.props;

        return (
            <React.Fragment>
            <div className="minimized-address-book">
                <a className="" title="Expand"
                   onClick={() => {
                       this.props.dispatch(
                           unminimizeAddAddressBookModal()
                       )
                   }}
                ><i className="ti-plus"></i> </a>
            </div>
            <Dialog active={this.props.ui && !this.props.ui.minimizeAddAddressBookDialog}>

                <a className="minimize-dialog" title="Minimize"
                   onClick={() => {
                       this.props.dispatch(
                           minimizeAddAddressBookModal()
                       )
                   }}
                ><i className="ti-minus"></i> </a>
                <a className="close-dialog" title="Close"
                   onClick={() => {
                       this.props.dispatch(
                           hideAddAddressBookModal()
                       )
                   }}
                ><i className="ti-close"></i> </a>

                <div className="dialog-title-wrapper">
                    <div className="dialog-main-title">Address Book</div>
                    <div className="dialog-second-title">add new entry</div>
                </div>

                {resource.create && (
                    <React.Fragment>
                        <SuccessMessage className={"action-success-big"} text={"New entry created!"}
                                        hideClose={true}/>
                        <button
                            className="btn"
                            onClick={() => {
                                this.setState({
                                    fields: this.getFields()
                                }, () => this.props.dispatch(resetResourceMessage()))
                            }}
                        >
                            Create another entry
                        </button>
                        <button
                            className="btn"
                            onClick={() => {
                                this.setState({fields: this.getFields()});
                                this.props.dispatch(resetResourceMessage());
                                this.props.dispatch(hideAddAddressBookModal())
                            }}
                        >
                            Done
                        </button>
                    </React.Fragment>
                )}

                {resource.error && (
                    <ErrorMessage className={"action-success-big"} text={resource.errorMessage}
                                    onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {!resource.isLoading && !resource.create && (
                <form onKeyPress={this.submitForm}>

                    <React.Fragment>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                                <label>Name*</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_name} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Address*</label>

                                <FieldGooglePlaces
                                    addClass="form-control"
                                    autoFocus
                                    handleInputChange={this.handleInputChange}
                                    setLocations={this.setLocations}
                                    stateFields={this.state.fields}
                                    placeholderText={"Enter Address Name"}
                                    {...this.state.fields.customer_address}
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label>City*</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_city} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label>State*</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_state} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label>Zip*</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_zip} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label>Phone</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_phone} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label>Phone 2</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_phone2} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label>Emergency Phone</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_emergency_phone} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label>Email</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_email} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label>Work hours</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_hours} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label>Contact Person</label>
                                <FieldText addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_contact} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label>Appointment</label>
                                <FieldSelect addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_appointment}>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </FieldSelect>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                                <label>Directions</label>
                                <FieldTextarea addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_directions} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Description</label>
                                <FieldTextarea addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.customer_desc} />
                            </div>
                        </div>

                        <Button className="btn btn-brand" onClick={this.submit} text={"Add new entry"}/>
                    </React.Fragment>

                </form>
                )}

                {resource.isLoading && (
                    <LoaderSmall addClass={"text-center"}/>
                )}

            </Dialog>
            </React.Fragment>
        )
    }
}
