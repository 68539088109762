import React, { Component } from 'react';
import Fade from "./fade";
import "./message-styles.css";

export class FlashMessage extends Component {

    renderMessage(message) {
        return (
            <Fade show={true}>
                <div
                    key={message.id}
                    className={ `flash-message flash-active`}
                >
                    <div className="alert alert-warning alert-bordered pd-y-15 mg-b-15" role="alert">
                        <button type="button" className="close" onClick={message.onDismiss}>
                            <span aria-hidden="true">
                                <i className="ti-close tx-16">
                                </i>
                            </span>
                        </button>
                        <div className="d-sm-flex align-items-center justify-content-start mg-r-50"
                             onClick={() => message.onClick()}
                        >
                            <div className="mg-t-20 mg-sm-t-0">
                                <p className="mg-b-0 tx-gray">{message.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        );
    }

    render() {
        const messages = this.props.messages;

        return (
            <div className={"flash-message-wrapper"}>
                { messages.map((message) => this.renderMessage(message))}
            </div>
        );
    }
}

export default FlashMessage;