import React, {PureComponent} from "react";
import Header from "./header";
import Footer from "./footer";
import LocalStorage from "../../util/localStorage";
import {logoutClear} from "../../data/actions/user";
import {checkPerm, CREATE_PERM, getProp} from "../../util/util";
import {setMenuState, showAddAddressBookModal, showAddLoadModal} from "../../data/actions/ui";
import Resources from "../../data/services/resources";
import AddSupportDialog from "../dialog/add-support";

export default class BasicLayout extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            logout: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.user.logout) {
            return { logout: true };
        } else {
            return null;
        }
    }

    componentDidUpdate() {
        if (this.state.logout) {
            LocalStorage.clearAllExcept([
                'username'
            ]);
            this.props.dispatch(logoutClear());
            this.props.history.push("/login");
        }

        if (
            Object.keys(this.props.ui)
                .filter(it => it !== "piggy")
                .reduce((memo, item) => (memo || this.props.ui[item]), false)
        ) {
            document.body.className = "noscroll";
        } else {
            document.body.className = "";
        }
    }

    render() {

        const retract = !!this.props.ui.menuState && this.props.ui.menuState.retract;

        return (
            <div className="page-sidebar-fixed">
                <div className="page-container">
                    {!retract && (
                        <Header
                            {...this.props}
                            onClick={() => {
                                this.props.dispatch(setMenuState({
                                    retract: true
                                }))
                            }}
                        />
                    )}

                    {retract && (
                        <div className="page-sidebar-small">
                            <div className="logo has-cursor" onClick={() => {
                                this.props.dispatch(setMenuState({
                                    retract: false
                                }))
                            }}>
                                <a className="logo-img logo-text">
                                    <img src="/images/large-logo-white.png" className="" alt=""/>
                                </a>
                                <a id="sidebar-toggle-button-close"><i className="wd-20" data-feather="x"></i> </a>
                            </div>
                        </div>
                    )}

                    <React.Fragment>
                        <div className={"page-content" + (retract?" retracted":"")}>

                            <div className="page-header">
                                <nav className="navbar navbar-default">
                                    <div className="navbar-header">
                                        <span title={"User company"} className="mg-r-20"><i className="ti-headphone-alt tx-30 vertical-bottom mg-r-5"></i> {getProp(this.props, 'user.data.Contact.company_name')}</span>
                                        <span title={"User first name"}><i className="ti-id-badge tx-30 vertical-bottom mg-r-5"></i> {getProp(this.props, 'user.data.Contact.user_fname')} {getProp(this.props, 'user.data.Contact.user_lname')}</span>
                                    </div>

                                    <div className="header-right pull-right">
                                        <ul className="list-inline justify-content-end">
                                            {checkPerm(Resources.AddressBook, CREATE_PERM) && (
                                                <li className="list-inline-item">
                                                    <button onClick={() => {
                                                        if (!this.props.ui.viewAddAddressBookDialog) {
                                                            this.props.dispatch(showAddAddressBookModal({query: this.props.query}));
                                                        }
                                                    }} type="button" className="btn btn-secondary btn-sm">Add New Address</button>
                                                </li>
                                            )}
                                            {checkPerm(Resources.Loads, CREATE_PERM) && (
                                            <li className="list-inline-item">
                                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => {
                                                    if (!this.props.ui.viewAddLoadDialog) {
                                                        this.props.dispatch(showAddLoadModal({query: this.props.query}));
                                                    }
                                                }}>Setup New Load</button>
                                            </li>
                                            )}
                                        </ul>
                                    </div>
                                </nav>
                            </div>

                            {this.props.children}

                        </div>
                    </React.Fragment>

                    {!retract && (
                        <Footer
                            {...this.props}
                        />
                    )}

                    {/* SUPPORT DIALOG */}
                    {this.props.ui && this.props.ui.viewAddSupportDialog && (
                        <AddSupportDialog {...this.props}/>
                    )}
                </div>
            </div>
        )
    }
}

