import React, {Component} from "react";
import Dialog from "./index";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldTextarea from "../field-textarea";
import Button from "../button";
import LoaderSmall from "../../components/loader-small";
import {createResource, resetResourceMessage} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import SuccessMessage from "../success-message";
import ErrorMessage from "../error-message";

export default class AddAddressBookReview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                review_note: new Field('review_note', '', ['empty'])
            }
        };
    }

    componentDidUpdate = (prevProps) => {
        if (((!prevProps.resource.create) && (this.props.resource.create)) ||
            ((!prevProps.resource.error) && (this.props.resource.error))) {
            setTimeout(() => this.props.dispatch(resetResourceMessage()), 5000);
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        customer_id: this.props.customer_id
                    }),
                    secondPiggyResource: Resources.AddressBookReview,
                    resource: Resources.AddressBookReview,
                    query: {
                        id: this.props.customer_id
                    }
                }));
                this.props.cancel();
            }
        })
    };

    render() {

        const {translate, resource} = this.props;

        return (
            <Dialog active={this.props.visible}>

                <a className="close-dialog" title="Close"
                   onClick={this.props.cancel}
                ><i className="ti-close"></i> </a>

                <div className="dialog-title-wrapper">
                    <div className="dialog-main-title">Customer</div>
                    <div className="dialog-second-title">Add review note</div>
                </div>

                {resource.create && (
                    <SuccessMessage className={"action-success-big"} text={translate("label.entry_created")}
                                    onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {resource.error && (
                    <ErrorMessage className={"action-success-big"} text={resource.errorMessage}
                                  onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {!resource.isLoading && (
                    <form onKeyPress={this.submitForm}>

                        <React.Fragment>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label>Review*</label>
                                    <FieldTextarea addClass="form-control" onChange={this.handleInputChange} {...this.state.fields.review_note} />
                                </div>
                            </div>

                            <Button className="btn btn-brand mg-r-15" onClick={this.submit} text={"Create"}/>
                            <Button className="btn btn-brand" onClick={this.props.cancel} text={"Cancel"}/>
                        </React.Fragment>
                    </form>
                )}

                {resource.isLoading && (
                    <LoaderSmall addClass={"text-center"}/>
                )}
            </Dialog>
        )
    }

}