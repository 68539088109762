const downloadReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DOWNLOAD_EXCEL_REPORT':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_DOWNLOAD_EXCEL_REPORT':
            return Object.assign({}, state, {
                data: true,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_DOWNLOAD_EXCEL_REPORT':
            return Object.assign({}, state, {
                data: true,
                isLoading: false,
                error: true,
                errorMessage: true
            });
        case 'GET_EMAIL_SENT':
            return Object.assign({}, state, {
                emailSent: false,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_EMAIL_SENT':
            return Object.assign({}, state, {
                emailSent: true,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_EMAIL_SENT':
            return Object.assign({}, state, {
                emailSent: true,
                isLoading: false,
                error: true,
                errorMessage: true
            });
        case 'RESET_EMAIL_SENT_MESSAGE':
            return Object.assign({}, state, {
                data: false,
                emailSent: false,
                isLoading: false,
                error: false,
                errorMessage: false
            });
        default:
            return state;
    }
};

export default downloadReducer;