import React, {Component} from "react";

export default class NotificationSuccessMessage extends Component {


    render() {
        return (

            <div className="alert alert-success alert-bordered pd-y-15 mg-b-15" role="alert">
                <button type="button" className="close" onClick={this.props.onDismiss}>
                    <span aria-hidden="true"><i className="ti-close tx-16"></i></span>
                </button>
                <div className="d-sm-flex align-items-center justify-content-start mg-r-50">
                    <i className={(this.props.icon ? this.props.icon: "ti-check-box") + " alert-icon tx-50 mg-r-20 tx-success"}></i>
                    <div className="mg-t-20 mg-sm-t-0">
                        <h5 className="mg-b-2 tx-success">Success</h5>
                        <p className="mg-b-0 tx-gray">{this.props.text}</p>
                    </div>
                </div>
            </div>

        )
    }
}
